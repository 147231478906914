import React, { createContext, useContext, useEffect } from "react";
import { appInsights } from "../shared/AppInsights";

const AppInsightsContext = createContext<any>(null);

const AppInsightsProvider = ({ children }: { children: any }) => {
	useEffect(() => {
		appInsights.loadAppInsights();
	}, []);
	return (
		<AppInsightsContext.Provider value={appInsights}>
			{children}
		</AppInsightsContext.Provider>
	);
};

const useAppInsights = () => {
	const appInsights = useContext(AppInsightsContext);
	if (appInsights == null) {
		throw new Error("useAppInsights() called outside of a appInsights hook?");
	}
	return appInsights;
};

export { AppInsightsProvider, useAppInsights };
