import { ErrorMessage, Form, Formik } from "formik";
import React, { Fragment } from "react";
import { Card, Label } from "semantic-ui-react";
import * as Yup from "yup";
import EsvdInput from "../../shared/Input";
import { User } from "../../models/user";
import WelcomeHeader from "../../shared/WelcomeHeader";
import { QueryString } from "../../models/EsvdQueryFilters";
import { PASSWORD } from "../../shared/Literals";
import { ErrorHandler } from "../../shared/ExceptionHandling";
import { Link, Redirect } from "react-router-dom";
import { useAuth } from "../../providers/AuthProvider";
import LoginsContainer from "./LoginsContainer";

export interface IProps {
	resetpassword: Function;
	user: User;
	location: any | null;
}

const ResetPassword = (props: IProps) => {
	const { resetpassword, setToken } = useAuth();

	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [isReset, setIsReset] = React.useState<boolean>(false);
	const [subheadText, setsubheadText] = React.useState<string>(
		"Enter New Password To Reset"
	);
	const [queryString] = React.useState<any>(
		Object.fromEntries(new URLSearchParams(props.location.search))
	);

	const resetCompleted = () => {
		setIsReset(!isReset);
		setsubheadText("Reset successful.");
	};

	const renderContent = () => {
		if (queryString.username && queryString.code) {
			setToken();
			return (
				<Card
					centered
					// className="small-card"
					style={{
						padding: "10px",
					}}>
					<Card.Content textAlign="left">
						<h4 className="title-card">Welcome to the ESVD</h4>
					</Card.Content>
					{isReset ? (
						<Card.Content className="text-center">
							Password reset successful. Click <Link to={"/login"}>here</Link>{" "}
							to login.
						</Card.Content>
					) : (
						<>
							<Card.Content textAlign="left">
								<Card.Description>{subheadText}</Card.Description>
								<br />
								<Formik
									validationSchema={validationSchema}
									enableReinitialize
									initialValues={{
										code: "",
										userid: "",
										password: "",
										error: null,
									}}
									onSubmit={(values, { setErrors }) => {
										handleFormSubmit(values, setErrors);
									}}>
									{({ handleSubmit, isValid, isSubmitting, dirty, errors }) => (
										<Form
											className="ui form page-content"
											onSubmit={handleSubmit}
											autoComplete={"off"}>
											<EsvdInput
												name="password"
												label="Password"
												type="password"
												fluid
												icon={"key"}
												placeholder="Password"
											/>
											<EsvdInput
												name="confirmpassword"
												label="Confirm Password"
												type="password"
												fluid
												icon={"key"}
												placeholder="Confirm Password"
											/>
											<ErrorMessage
												name="error"
												render={() => (
													<Label
														style={{ marginBottom: 10 }}
														basic
														color="red"
														content={errors.error}
													/>
												)}
											/>
											<button
												disabled={!dirty || (dirty && !isValid)}
												className="ui button fluid btn btn-primary"
												type="submit"
												style={{
													backgroundColor: "#70bbfd",
													display: "block !important",
													height: "3rem",
												}}>
												<span className={`${isLoading ? "hide" : ""}`}>
													Save
												</span>
												<span
													className={`box loader-01 ${
														isLoading ? "" : "invisible"
													}`}></span>
											</button>
											<Link to="/login" className="ui right floated">
												Login?
											</Link>
										</Form>
									)}
								</Formik>
								{/* <Card.Content> */}
							</Card.Content>
						</>
					)}
				</Card>
			);
		} else return <Redirect to="/" />;
	};

	const handleFormSubmit = (
		values: { code: string; userid: string; password: string; error: null },
		setErrors: Function
	) => {
		//add query string userid and code to form values
		//get details from url query string
		//https://stackoverflow.com/a/56780570/5367889
		//TODO: encode the userid fon the server before sending and then decode it here
		const queryString: QueryString = Object.fromEntries(
			new URLSearchParams(props.location.search)
		);
		const params = { ...values, ...queryString };
		setIsLoading(true);
		resetpassword(params)
			.then((res: any) => {
				// setLoading(false);
				resetCompleted();
			})
			.catch((error: any) => {
				setErrors({ error: ErrorHandler(error) });
				// setLoading(false);
			});
		setIsLoading(false);
	};

	return <LoginsContainer>{renderContent()}</LoginsContainer>;
	// }
};

const validationSchema = Yup.object({
	password: Yup.string()
		.trim()
		.min(PASSWORD.min_len, PASSWORD.msg)
		.required("Password is required"),
	confirmpassword: Yup.string()
		.oneOf([Yup.ref("password"), null], "Passwords must match")
		.required("Confirm Password is required"),
});

export default ResetPassword;
