import { DropdownItemProps } from "semantic-ui-react";
import { ReferenceInfo } from "../models/ReferenceInfo";
import { Study } from "../models/Study";
import { SuggestStudy } from "../models/SuggestStudy";
import { User } from "../models/user";
export const screenBreakpoints = {
  mobile: 0,
  tablet: 800,
  computer: 1024,
};

export const FILTERS = {
  countries: "countries",
  countriesCodes:"countriesCodes",
  protectionStatuses: "protectionStatuses",
  protectionStatusesCodes: "protectionStatusesCodes",
  continents: "continents",
  continentsCodes: "continentsCodes",
  valuationMethods: "valuationMethods",
  valuationMethodsCodes: "valuationMethodsCodes",
  ecosystemServices: "ecosystemServices",
  ecosystemServicesCodes: "ecosystemServicesCodes",
  seeas: "seeas",
  seeasCodes: "seeasCodes",
  ciceses: "ciceses",
  cicesesCodes: "cicesesCodes",
  biomeV2EcosystemV2Ecozones: "biomeV2EcosystemV2Ecozones",
  biomeV2EcosystemV2EcozonesCodes: "biomeV2EcosystemV2EcozonesCodes",
  freeText: "freeText",
}

export const IS_DESCEND_DEFAULT = true;
export const FETCH_USER = "FETCH_USER";
export const ISREFRESH = "ISREFRESH";
export const SET_USER_TOKEN = "SET_USER_TOKEN";
export const SET_APP_LOADED = "SET_APP_LOADED";
export const RESET_LINK_SENT = "RESET_LINK_SENT";
export const RESET_STATE = "RESET_STATE";

export const GA_NO: string = process.env.REACT_APP_GA_TRACKING_NO || "";
export const APP_INSIGHT_INST_KEY: string =
  process.env.APPINSIGHTS_INSTRUMENTATIONKEY || "";
export const COMPANY_EMAIL = "esvd@fsd.nl";
export const defaultSort = { key: "studyId" };
export const LIMIT = 100;
export const LOGIN_COOKIE_NAME = "esvdlogged";
// export const LOGIN_IDLE_TIMEOUT = 1000 * 5 * 1; //5 seconds timeout for testing;
export const LOGIN_IDLE_TIMEOUT = 1000 * 30 * 60; //30 mins timeout;
export const EMAIL_REGEX = {
  exp: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/gm,
  msg: "Invalid email format!",
};
export const PASSWORD = {
  min_len: 5,
  msg: "Password must be minimun of 5 characters.",
};
export const PASSWORD_REGEX = {
  exp: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,30}/gm,
  msg: "Password must be minimum of 8 and max of 30 characters. It should contain a number, an uppercase letter, a lowercase letter and $!@",
};
export const YEAR_REGEX = {
  exp: /(?=.*\d).{4}/gm,
  msg: "Year is incorrect",
};
export const resources = {
  home: { name: "Home", path: "/" },
  userProfile: { name: "UserProfile", path: "/userprofile" },
  esvd: { name: "Database", path: "/esvd" },
  study: { name: "Add Data", path: "/entries" },
  suggestStudy: { name: "Suggest a Study", path: "/suggest" },
  manageStudy: { name: "Manage all Studies", path: "/manage" },
  studyEditor: { name: "Edit Data", path: "/editor" },
  studySearch: { name: "Search" },
  downloads: { name: "Downloads", path: "/downloads" },
  filterqueriesdownloads: { name: "Download Filter Queries", path: "/filterqueries" },
  userprofiledownloads: { name: "Download User Profiles", path: "/userprofiles" },
  settings: { name: "Various Settings", path: "/settings" },
  vtt: { name: "vtt", path: "/vtt", label:"Value Transfer Tool Setup" },
  vttCalculations: { name: "vttcalculations", path: "/vtt/calculations", label:"Value Transfer Tool" },
  uploads: { name: "Uploads", path: "/esvduploads",label:"Esvd Uploads" },
  vsl: { name: "vsl", path: "/vsl", label: "Valuation Studies List" },
  vsluploads: { name: "vslUploads", path: "/vsluploads", label:"Vsl Upload" },
  admin: { name: "Admin", path: "/admin" },
  statsReady: { name: "statsready", path: "/statsready", label: "Summary Stats Admin" },
  apikeySetup: { name: "apikeySetup", path: "/apikeySetup", label: "Api key Setup" },
  earlybird: { name: "earlybird", path: "/earlybirdSetup", label: "Early Bird Setup" },
  studyValuations: { name: "studyvaluations", path: "/studyvaluations", label: "Study Valuations" },
};

export const actions = { read: "read", create: "create", delete: "delete" };
export const userRole = {
  basic: "basic", //non existent in db for now. Holds permissions common to all other roles
  user: "user",
  dataAnalyst: "data analyst",
  editorInChief: "editor in chief",
  admin: "admin",
  cocapacity: "co-capacity",
  earlyBird: "early bird",
};

export const initStudy: Study = {
  id: null,
  studyId: "",
  title: "",
  authors: "",
  reference: "",
  isNew: true,
  yearOfPublication: "",
  isPublished: false,
};

export const initReferenceInfo: ReferenceInfo = {
  id: null,
  month: new Date().getMonth(),
  year: new Date().getFullYear(),
  version:null,
  subversion: null,
  referenceText: "",
  createdOn:""
 
};

export const initValuation = { id: null, valueId: 0, studyId: 0 };

export const initSuggestStudy: SuggestStudy = {
  title: "",
  description: "",
  studyType: "",
  attachment: [],
};

export const StudyProps = {
  id: { name: "id" },
  studyId: { name: "studyId", label: "Study Id" },
  title: { name: "title", label: "Title" },
  authors: { name: "authors", label: "Authors" },
  reference: { name: "reference", label: "Full Bib. Reference" },
  isNew: { name: "isNew", label: "Is New" },
  yearOfPublication: {
    name: "yearOfPublication",
    label: "Year of Publication",
  },
  isPublished: { name: "isPublished", label: "Is Published" },
};

export const StudySuggestionProps = {
  title: { name: "title", label: "Study Title" },
  description: {
    name: "description",
    label:
      "Brief description of why you think the Study should be included in the ESVD",
  },
  studyType: {
    name: "studyType",
    label:
      "Type of Study (thesis, report, journal article, conference paper, book (section))",
  },
  attachment: { name: "attachment", label: "Attach your Study documents" },
};
export const studyPublishStatus = {
  published: "Publish",
  unpublish: "Unpublish",
  republish: "Republish",
};

export const ValuationProps = {
  id: {
    name: "id",
    label: "id",
  },
  valueId: {
    name: "valueId",
    label: "ValueId",
  },
  studyId: {
    name: "studyId",
    label: "Study Id",
  },
  biomeEcosystems: {
    name: "biomeEcosystems",
    label: "Biomes/Ecosystems",
  },
  biomeV2EcosystemV2Ecozones: {
    name: "biomeV2EcosystemV2Ecozones",
    label: "Biomes/Ecozones",
  },
  ecosystemDescription: {
    name: "ecosystemDescription",
    label: "Ecosystem Description",
  },
  ecosystemServices: {
    name: "ecosystemServices",
    label: "Ecosystem Services",
  },
  ecosystemServiceDescription: {
    name: "ecosystemServiceDescription",
    label: "Ecosystem Service Description",
  },
  countries: {
    name: "countries",
    label: "Countries",
  },
  continentId: {
    name: "continentId",
    label: "Continent",
  },
  scaleOfStudySiteId: {
    name: "scaleOfStudySiteId",
    label: "Scale Of Study Id",
  },
  scaleOfStudySiteCode: {
    name: "scaleOfStudySiteCode",
    label: "Scale Of Study Code",
  },
  scaleOfStudySite: {
    name: "scaleOfStudySite",
    label: "Scale Of Study Site",
  },
  studyLocation: {
    name: "studyLocation",
    label: "Study Location Name",
  },
  protectionStatusId: {
    name: "protectionStatusId",
    label: "Protection Status",
  },
  siteArea: {
    name: "siteArea",
    label: "Site Area",
  },
  siteAreaUnit: {
    name: "siteAreaUnit",
    label: "Site Area Unit",
  },
  siteAreaInHectares: {
    name: "siteAreaInHectares",
    label: "Site Area In Hectares",
  },
  siteLength: {
    name: "siteLength",
    label: "Site Length",
  },
  siteLengthUnit: {
    name: "siteLengthUnit",
    label: "Site Length Unit",
  },
  siteLengthKm: {
    name: "siteLengthKm",
    label: "Site Length Km",
  },
  siteCondition: {
    name: "siteCondition",
    label: "Site Condition Description",
  },
  ecosystemConditionId: {
    name: "ecosystemConditionId",
    label: "Ecosystem Condition",
  },
  latitude: {
    name: "latitude",
    label: "Latitude",
  },
  longitude: {
    name: "longitude",
    label: "Longitude",
  },
  valuationMethods: {
    name: "valuationMethods",
    label: "Valuation Method",
  },
  valuedChange: {
    name: "valuedChange",
    label: "Valued Change",
  },
  originalValue: {
    name: "originalValue",
    label: "Original value",
  },
  currencyId: {
    name: "currencyId",
    label: "Currency",
  },
  valueYear: {
    name: "valueYear",
    label: "Value Year",
  },
  spatialUnit: {
    name: "SpatialUnit",
    label: "Spatial Unit",
  },
  temporalUnit: {
    name: "temporalUnit",
    label: "Temporal Unit",
  },
  presentValueYear: {
    name: "presentValueYear",
    label: "Present Value Year",
  },
  presentValueDiscountRate: {
    name: "presentValueDiscountRate",
    label: "Present Value Discount Rate",
  },
  beneficiaryUnit: {
    name: "beneficiaryUnit",
    label: "Beneficiary Unit",
  },
  numberOfBeneficiaries: {
    name: "numberOfBeneficiaries",
    label: "Number Of Beneficiaries",
  },
  typeOfBeneficiary: {
    name: "typeOfBeneficiary",
    label: "Type Of Beneficiary",
  },
  intPerHectarePerYear: {
    name: "intPerHectarePerYear",
    label: "Int_$_Ha_Yr",
  },
  notes: {
    name: "notes",
    label: "Notes",
  },
  secondaryDataSources: {
    name: "secondaryDataSources",
    label: "Secondary Data Sources",
  },
  coders: {
    name: "coders",
    label: "Coders",
  },
  codedDate: {
    name: "codedDate",
    label: "Coded Date",
  },
  reviewers: {
    name: "reviewers",
    label: "Reviewers",
  },
  reviewed: {
    name: "reviewed",
    label: "Reviewed",
  },
  reviewStatusId: {
    name: "reviewStatusId",
    label: "Review Status",
  },
  ciceses: {
    name: "ciceses",
    label: "CICES",
  },
  seeas: {
    name: "seeas",
    label: "SEEA",
  },
    inclExcl: {
    name: "inclExcl",
    label: "InclExcl",
  },
      inclExclComments: {
    name: "inclExclComments",
    label: "InclExclComments",
  },
};

export const ItemLabels = {
  biome: "Biome",
  biomeCode: "Biome_Code",
  teeb: "TEEB ES services",
  teebCode: "TEEB_Code",
  Int_Per_Hectare_Per_Year: "Int_Per_Hectare_Per_Year",
  InclExcl: "InclExcl",
};


export const sectorsList: DropdownItemProps[] = [
  { label: "Academia", value: "Academia" },
  { label: "Business", value: "Business" },
	{ label: "Financial institutions", value: "Financial institutions" },
	{ label: "Government", value: "Government" },	
	{ label: "NGO", value: "NGO" },
	{ label: "Other", value: "Other" },
];

export const purposeOfAccessList: DropdownItemProps[] = [
  { label: "Accounting", value: "Accounting" },
  { label: "Benefit transfer", value: "Benefit transfer" },
  { label: "Cost-benefit", value: "Cost-benefit" },
  { label: "Education", value: "Education" },
	{ label: "Policy appraisal", value: "Policy appraisal" },
	{ label: "Research", value: "Research" },
	{ label: "Other", value: "Other" },
];

export interface IUserProps {
	user: User;
}


export const EsvdColumnMapping = {
  "ValueID": "ValueId",
  "StudyID": "StudyId",
  "ESVD2.0_Biome_Code": "BiomeCode",
  "ESVD2.0_Ecozone_Code": "EcozoneCode",
  "ESVD2.0_Ecosystem_Code": "EcosystemCode",
  "Ecosystem_Text": "EcosystemDescription",
  "ES_Text": "EcosystemServiceDescription",
  "TEEB_ES": "EcosystemServiceCode",
  "TEEB_SubES": "EcosystemSubServiceCode",
  "CICES": "CicesCode",
  "SEEA_Code": "SeeaCode",
  "Country_Code": "CountryCode",
  "Continent_Code": "ContinentCode",
  "Scale_Code": "ScaleOfStudySiteCode",
  "Location_Name": "StudyLocation",
  "Protected_Status": "ProtectionStatusCode",
  "Site_Area": "SiteArea",
  "Area_Unit": "SiteAreaUnit",
  "Area_ha": "SiteAreaInHectares",
  "Site_Length": "SiteLength",
  "Length_Unit": "SiteLengthUnit",
  "Length_km": "SiteLengthKm",
  "Condition_Text": "SiteCondition",
  "Condition_Code": "EcosystemConditionCode",
  "Latitude": "Latitude",
  "Longitude": "Longitude",
  "Valuation_Method": "ValuationCode",
  "Valued_Change_Text": "ValuedChange",
  "Value_Original": "OriginalValue",
  "Currency_Code": "CurrencyCode",
  "Value_year": "ValueYear",
  "Spatial_Unit": "SpatialUnit",
  "Temporal_Unit": "TemporalUnit",
  "PV_Years": "PresentValueYear",
  "PV_Rate": "PresentValueDiscountRate",
  "Beneficiary_Unit": "BeneficiaryUnit",
  "Beneficiary_Number": "NumberOfBeneficiaries",
  "Beneficiary_Type": "TypeOfBeneficiary",
  "INT$_2020_ha_yr_all": "IntPerHectarePerYear",
  "Notes": "Notes",
  "Authors": "Authors",
  "Year_Pub":"Year",
  "Reference":"Reference",
  "Title":"Title",
  "Secondary_sources": "SecondaryDataSources",
  "Coded_By": "CodedBy", 
  "Coded_Date": "CodedDate",
  "Reviewer": "Reviewer",
  "Review_Date": "ReviewedDate",
  "Incl_Excl": "InclExcl",
  "Comments_Incl_Excl": "InclExclComments",
  "Scale_Text": "ScaleOfStudySiteText",
  "Valuation_Display": "ValuationDisplay", //this determines if the valuation is reserved for Earlybird users only.
  "Value_Measure":"ValueMeasure",
  "Value_Change":"ValueChange",
}

export const ExcelHeaders: any = {
  esvds: {
    label: "ESVD MASTER",
    columns: ["ValueID",
      "StudyID",
      "Biome_Code",
      "Ecosystem_Code",
      "ESVD2.0_Biome_Code",
      "ESVD2.0_Ecozone_Code",
      "ESVD2.0_Ecosystem_Code",
      "Ecosystem_Text",
      "ES_Text",
      "TEEB_ES",
      "TEEB_SubES",
      "CICES",
      "SEEA_Code",
      "Country_Code",
      "Continent_Code",
      "Scale_Code",
      "Location_Name",
      "Protected_Status",
      "Site_Area",
      "Area_Unit",
      "Area_ha",
      "Site_Length",
      "Length_Unit",
      "Length_km",
      "Condition_Text",
      "Condition_Code",
      "Latitude",
      "Longitude",
      "Valuation_Method",
      "Valued_Change_Text",
      "Value_Original",
      "Currency_Code",
      "Value_year",
      "Spatial_Unit",
      "Temporal_Unit",
      "PV_Years",
      "PV_Rate",
      "Beneficiary_Unit",
      "Beneficiary_Number",
      "Beneficiary_Type",
      "INT$_2020_ha_yr_all",
      "Notes",
      "Authors",
      "Year_Pub",
      "Reference",
      "Title",
      "Secondary_sources",
      "Coded_By",
      "Coded_Date",
      "Reviewer",
      "Review_Date",
      "Incl_Excl",
      "Comments_Incl_Excl",
      "Scale_Text",
      "Valuation_Display",
      "Value_Measure",
      "Value_Change"]
  },
  biomes: { label: "Biomes and Ecosystems", columns: ["Code", "Biomes and Ecosystems"] },
  biomesV2: { label: "ESVD2.0 Biome and Ecosystems cl", columns: ["Code", "Esvd"] },
  teebs: { label: "TEEB Services", columns: ["Section", "Ecosystem Service", "ES Code", "Ecosystem Sub-Service", "ESS Code"] },
  cices: { label: "CICES V5.1", columns: ["Section", "Division", "Group", "Class", "Code"] },
  seeas: { label: "SEEA Services", columns: ["Code", "main", "types", "subtypes", "description"] },
  valuationmethods: { label: "Valuation Methods", columns: ["Valuation method", "Acronym", "Approach", "Application to ecosystem services", "Example ecosystem service"] },
  protectionstatus: { label: "Protection Status", columns: ["Code", "Category"] },
  ecosystemcondition: { label: "Condition", columns: ["Code", "Condition"] },
  countries: { label: "Country ISO", columns: ["Code", "Name"] },
  continents: { label: "Continent", columns: ["Code", "Name"] },
  currencies: { label: "Currency ISO", columns: ["Code", "Name"] },
  valueMeasure: { label: "Value_Measure", columns: ["Acronym", "Value Measure Options","Approach"] },
  valueChange: { label: "Value_Change", columns: ["Value_Change","Description"] }
}

export const VslColumnMapping = {
  "Authors": "Authors",
  "Year_Pub": "Year",
  "Title": "Title",
  "Reference": "Reference",
  "Biome_Code": "BiomeCode",
  "Ecosystem_Code": "EcosystemCode",
  "ESVD2.0_Biome_Code": "BiomeV2Code",
  "ESVD2.0_Ecozone_Code": "EcozoneCode",
  "ESVD2.0_Ecosystem_Code": "EcosystemV2Code",
  "TEEB_ES": "EcosystemServiceCode",
  "Country_Code": "CountryCode",
  "Protected_Status": "ProtectionStatusCode",  
  // "Condition_Code": "EcosystemConditionCode",
  "StudyID": "StudyId",
  "Included in ESVD": "IncludedinESVD",
  "Included Repository": "IncludedRepository",
  "Repository folder":"Repositoryfolder",
  "Checked by":"Checkedby",
  "Language": "Language",
  "Notes": "Notes",

}

export const VslExcelHeaders: any = {
  vsls: {
    label: "VSL MASTER", columns: [
      "Authors",
      "Year_Pub",
      "Title",
      "Reference",
      "Biome_Code",
      "Ecosystem_Code",
      "ESVD2.0_Biome_Code",
      "ESVD2.0_Ecozone_Code",
      "ESVD2.0_Ecosystem_Code",
      "TEEB_ES",
      "Country_Code",
      "Protected_Status",
      // "Condition_Code",
      "StudyID",
      "Included in ESVD",
      "Included Repository",
      "Repository folder",
      "Checked by",
      "Language",
      "Notes"]
  },
  biomes: { label: "Biomes and Ecosystems", columns: ["Code", "Biomes and Ecosystems"] },
  biomesv2: { label: "ESVD2.0 Biome and Ecosystems cl", columns: ["Code", "Esvd"] },
  teebs: { label: "TEEB Services", columns: ["Section", "Ecosystem Service", "ES Code", "Ecosystem Sub-Service", "ESS Code"] },
  protectionstatus: { label: "Protection Status", columns: ["Code", "Category"] },
  countries: { label: "Country ISO", columns: ["Code", "Name"] },
}



export const IncludedInEsvdOptions = [
	{ value: null, label: "" },
	{ value: "Included", label: "Included" },
	{ value: "Excluded", label: "Excluded" },
	{ value: "Unread", label: "Unread" },
];


export const RepoIncludedOptions = [
	{ value: null, label: "" },
	{ value: false, label: "No" },
	{ value: true, label: "Yes" },
];