import { createMedia } from "@artsy/fresnel";

import { screenBreakpoints } from "../shared/Literals";
import { createContext, useContext, useEffect, useState } from "react";
import { set } from "js-cookie";

const MediaContext = createContext<any | null>(null);

const MediaLayout = createMedia({
	breakpoints: screenBreakpoints,
});

const MediaProvider = ({ children }: { children: any }) => {
	const [screenType, setScreenType] = useState<"mobile" | "large">("large");

	useEffect(() => {
		setScreenType(window.innerWidth <= 760 ? "mobile" : "large");
	}, []);

	return (
		<MediaContext.Provider
			value={{
				MediaLayout,
				screenType,
			}}>
			{children}
		</MediaContext.Provider>
	);
};
const useMedia = () => {
	const vslH = useContext(MediaContext);
	if (vslH == null) {
		throw new Error("useMedia() called outside of its hook.");
	}
	return vslH;
};
export { MediaProvider, useMedia };

export default MediaLayout;
