// import { filter } from "lodash";
import React, { createRef, Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import {
	Button,
	Container,
	Dropdown,
	Icon,
	Menu,
	Segment,
} from "semantic-ui-react";
import Can from "../accessControls/Can";
import EcosystemLogo from "../components/home/EcosystemLogo";
import { useAuth } from "../providers/AuthProvider";
import { actions, resources } from "../shared/Literals";

const NavBar = () => {
	const { user, fetchCurrentUser } = useAuth();
	const profileRef = createRef<any | null>();
	const [active, setActive] = React.useState<string>(resources.home.name);

	useEffect(() => {
		//get the current window path
		const pathname = window.location && window.location.pathname;
		//get the name of the resource with this pathname
		const resource = Object.values(resources).find(
			(value: any) => value.path === pathname
		);

		if (
			resource?.name === resources.userprofiledownloads.name ||
			resource?.name === resources.filterqueriesdownloads.name
		) {
			setActive(resources.downloads.name);
			return;
		}
		resource && setActive(resource.name);
		fetchCurrentUser();
	}, []);

	const handleItemClick = (name: string) => {
		setActive(name);
	};

	const renderLeftMenu = () => {
		return (
			<>
				<Menu.Item
					// className="overline1"
					name={resources.esvd.name}
					active={active === resources.esvd.name}
					onClick={() => {
						handleItemClick(resources.esvd.name);
					}}
					as={Link}
					to={`${resources.esvd.path}`}
				/>
				{user && (
					<Can
						action={actions.read}
						resource={resources.esvd.name}
						yes={() => (
							<Menu.Item
								name={resources.vttCalculations.name}
								active={active === resources.vttCalculations.name}
								onClick={() => handleItemClick(resources.vttCalculations.name)}
								as={Link}
								to={`${resources.vttCalculations.path}`}>
								{resources.vttCalculations.label}
							</Menu.Item>
						)}
						no={() => null}
					/>
				)}

				{user && (
					<Can
						action={actions.read}
						resource={resources.suggestStudy.name}
						yes={() => (
							<Menu.Item
								name={resources.suggestStudy.name}
								active={active === resources.suggestStudy.name}
								onClick={() => handleItemClick(resources.suggestStudy.name)}
								as={Link}
								to={`${resources.suggestStudy.path}`}>
								{resources.suggestStudy.name}
							</Menu.Item>
						)}
						no={() => null}
					/>
				)}

				{user && (
					<Can
						action={actions.read}
						resource={resources.admin.name}
						yes={() => (
							<Menu.Menu
								// active={active === resources.admin.name}
								position="left">
								<Dropdown
									ref={profileRef}
									item
									text={resources.admin.name}
									active={active === resources.admin.name}
									className={`${
										active === resources.admin.name ? "active" : ""
									}`}
									onClick={() => {
										handleItemClick(resources.admin.name);
									}}>
									<Dropdown.Menu>
										<Dropdown.Item active={active === resources.admin.name}>
											<Link
												to={resources.vtt.path}
												onClick={() => handleItemClick(resources.admin.name)}>
												{resources.vtt.label}
											</Link>
										</Dropdown.Item>
										<Dropdown.Item active={active === resources.admin.name}>
											<Link
												to={resources.earlybird.path}
												onClick={() => handleItemClick(resources.admin.name)}>
												{resources.earlybird.label}
											</Link>
										</Dropdown.Item>
										<Dropdown.Item active={active === resources.admin.name}>
											<Link
												to={resources.statsReady.path}
												onClick={() => handleItemClick(resources.admin.name)}>
												{resources.statsReady.label}
											</Link>
										</Dropdown.Item>
										<Dropdown.Item active={active === resources.admin.name}>
											<Link
												to={resources.vsl.path}
												onClick={() => handleItemClick(resources.admin.name)}>
												{resources.vsl.label}
											</Link>
										</Dropdown.Item>

										{user && (
											<Can
												action={actions.read}
												resource={resources.settings.name}
												yes={() => (
													<Dropdown.Item
														active={active === resources.admin.name}>
														<Link
															to={resources.settings.path}
															onClick={() =>
																handleItemClick(resources.admin.name)
															}>
															{resources.settings.name}
														</Link>
													</Dropdown.Item>
												)}
												no={() => null}
											/>
										)}

										{user && (
											<Can
												action={actions.read}
												resource={resources.apikeySetup.name}
												yes={() => (
													<Dropdown.Item
														active={active === resources.admin.name}>
														<Link
															to={resources.apikeySetup.path}
															onClick={() =>
																handleItemClick(resources.admin.name)
															}>
															{resources.apikeySetup.label}
														</Link>
													</Dropdown.Item>
												)}
												no={() => null}
											/>
										)}
										<Dropdown.Item active={active === resources.admin.name}>
											<Link
												to={resources.userprofiledownloads.path}
												onClick={() => handleItemClick(resources.admin.name)}>
												{resources.userprofiledownloads.name}
											</Link>
										</Dropdown.Item>
										<Dropdown.Item
											active={active === resources.admin.name}
											onClick={() => handleItemClick(resources.admin.name)}>
											<Link to={resources.filterqueriesdownloads.path}>
												{resources.filterqueriesdownloads.name}
											</Link>
										</Dropdown.Item>
										<Dropdown.Item active={active === resources.admin.name}>
											<Link
												to={resources.uploads.path}
												onClick={() => handleItemClick(resources.admin.name)}>
												{resources.uploads.label}
											</Link>
										</Dropdown.Item>

										{user && (
											<Can
												action={actions.read}
												resource={resources.vsluploads.name}
												yes={() => (
													<Dropdown.Item
														active={active === resources.admin.name}>
														<Link
															to={resources.vsluploads.path}
															onClick={() =>
																handleItemClick(resources.vsluploads.name)
															}>
															{resources.vsluploads.label}
														</Link>
													</Dropdown.Item>
												)}
												no={() => null}
											/>
										)}
									</Dropdown.Menu>
								</Dropdown>
							</Menu.Menu>
						)}
						no={() => null}
					/>
				)}
			</>
		);
		// }
	};

	const renderNav = () => {
		return (
			<Segment style={{ marginBottom: "3.5rem" }} inverted>
				<Menu fixed="top" borderless>
					<Container fluid>
						<Menu.Item
							style={{
								height: "auto",
								width: "16em",
							}}>
							<EcosystemLogo />
						</Menu.Item>
						<Menu.Item
							className="overline1"
							name={resources.home.name}
							active={active === resources.home.name}
							onClick={() => handleItemClick(resources.home.name)}
							as={Link}
							to={"/"}>
							{resources.home.name}
						</Menu.Item>
						{renderLeftMenu()}

						<Menu.Menu position="right">
							{!user ? (
								<Menu.Item name="login" as={Link} to={"/login"}>
									Login/Sign Up
								</Menu.Item>
							) : (
								<div className="item">
									<Icon name="user circle" />
									<Dropdown
										ref={profileRef}
										style={{ paddingLeft: "0px" }}
										item
										className={`${
											active === resources.userProfile.name ? "active" : ""
										}`}
										text={user.displayName ? user.displayName : user.userName}>
										<Dropdown.Menu>
											<Dropdown.Item
												onClick={() =>
													handleItemClick(resources.userProfile.name)
												}>
												<Icon name="user" />
												<Link to={resources.userProfile.path}>My Profile</Link>
											</Dropdown.Item>
											<Dropdown.Divider />
											<Dropdown.Item
												className="exclude"
												as={Link}
												to={"/logout"}>
												<Icon name="log out" />
												Logout
											</Dropdown.Item>
										</Dropdown.Menu>
									</Dropdown>
								</div>
							)}
						</Menu.Menu>
					</Container>
				</Menu>
			</Segment>
		);
	};
	return renderNav();
};

export default NavBar;
