import { ErrorMessage, Form, Formik } from "formik";
import React, { Fragment } from "react";
import { Card, Label } from "semantic-ui-react";
import * as Yup from "yup";
import EsvdInput from "../../shared/Input";
import WelcomeHeader from "../../shared/WelcomeHeader";
import { Link, Redirect } from "react-router-dom";
import { ErrorHandler } from "../../shared/ExceptionHandling";
import { EMAIL_REGEX } from "../../shared/Literals";
import { useAuth } from "../../providers/AuthProvider";
import LoginsContainer from "./LoginsContainer";

interface IProps {
	forgotpassword: Function;
	isSesetLinkSent: boolean;
}

const ForgotPassword = (props: IProps) => {
	const { user, forgotpassword, setToken } = useAuth();

	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [isSent, setisSent] = React.useState<boolean>(false);

	const resetLinkSent = () => setisSent(!isSent);

	const handleFormSubmit = (
		values: { username: string; password: string; error: null },
		setErrors: Function
	) => {
		// const { forgotpassword } = this.props;
		// const { resetLinkSent, setLoading } = this.state;
		setIsLoading(true);
		forgotpassword(values)
			.then((res: any) => {
				resetLinkSent();
			})
			.catch((error: any) => {
				setErrors({ error: ErrorHandler(error) });
				// setIsLoading(false);
			});
		setIsLoading(false);
	};

	const renderContent = () => {
		if (!user) {
			setToken();
			return (
				<Card
					centered
					// className="small-card"
					style={{
						padding: "10px",
					}}>
					<Card.Content textAlign="left">
						<h4 className="title-card">Welcome to the ESVD</h4>
					</Card.Content>
					{isSent ? (
						<Card.Content className="text-center">
							An email has been sent to your inbox. <br />
							Please check your inbox to reset your password.
							<br />
							If you don't see an email, please check your spam folder.
						</Card.Content>
					) : (
						<>
							<Card.Content textAlign="left">
								<Card.Description>Password Reset</Card.Description>
								<br />
								<Formik
									validationSchema={validationSchema}
									enableReinitialize
									initialValues={{
										username: "",
										password: "",
										error: null,
									}}
									onSubmit={(values, { setErrors }) => {
										handleFormSubmit(values, setErrors);
									}}>
									{({ handleSubmit, isValid, isSubmitting, dirty, errors }) => (
										<Form
											className="ui form page-content"
											onSubmit={handleSubmit}
											autoComplete={"off"}>
											<EsvdInput
												name="email"
												label="Enter your email to reset your password."
												type="email"
												fluid
												icon={"at"}
												placeholder="Email"
											/>
											<ErrorMessage
												name="error"
												render={() => (
													<Label
														style={{ marginBottom: 10 }}
														basic
														color="red"
														content={errors.error}
													/>
												)}
											/>

											<button
												disabled={!dirty || (dirty && !isValid)}
												className="ui button fluid btn btn-primary"
												type="submit"
												style={{
													backgroundColor: "#70bbfd",
													display: "block !important",
													height: "3rem",
												}}>
												<span className={`${isLoading ? "hide" : ""}`}>
													Send Password Reset Link
												</span>
												<span
													className={`box loader-01 ${
														isLoading ? "" : "invisible"
													}`}></span>
											</button>
											<div
												style={{
													display: "inline-block",
													textAlign: "center",
												}}>
												Remember you password? <Link to="/login">Login</Link>
											</div>
										</Form>
									)}
								</Formik>
								{/* <Card.Content className="text-center"> */}
							</Card.Content>
						</>
					)}
				</Card>
			);
		} else return <Redirect to="/" />;
	};

	// render() {
	return <LoginsContainer>{renderContent()}</LoginsContainer>;
	// }
};

const validationSchema = Yup.object({
	email: Yup.string()
		.trim()
		.matches(EMAIL_REGEX.exp, EMAIL_REGEX.msg)
		.required("Email is required"),
});

export default ForgotPassword;
