import React, { useContext, useReducer, useState } from "react";
// import _ from "lodash";
import agent from "../api/agent";
import { defaultEsvdParams } from "../models/EsvdQueryFilters";
import { VslValuationStudy } from "../models/vsl-models/VslValuationStudy";
import {
	vslGridEditStatusReducer,
	vslGridEditSyncLockReducer,
	vslSelectedRowReducer,
} from "../components/vsls/VslActionsPanel";

interface IValue {
	getVsls: Function;
	editVsl: Function;
	setHotTableInstance?: Function;
	hotTableInstance?: any;
	vslsCount: number | undefined;
	vslFilter?: any;
	setVslFilters?: Function;

	vslData?: any[];
	resetVslData?: Function;
	gridStateDispatch?: Function;
	gridEditSycnLockDispatch?: Function;
	gridEditSyncLock?: any;
	gridLockState?: any;
	selectedRowDispatch?: Function;
	selectedRowState?: any;
	addVsl: Function;
	deleteStudy: Function;
	setvslsCount: Function;
	// getVslsCount: Function;
}

const VslContext = React.createContext<IValue | null>(null);

// The FilterQueryProvider is responsible for FilterQuery management
const VslProvider = ({ children }: { children: any }) => {
	const [vslsCount, setvslsCount] = useState<number | undefined>();
	const [vslData, setVslData] = useState<any[]>();
	const [vslFilter, setVslFilters] = React.useState<any>(null);

	const [gridEditSyncLock, gridEditSycnLockDispatch] = useReducer(
		vslGridEditSyncLockReducer,
		false
	);

	const [gridLockState, gridStateDispatch] = useReducer(
		vslGridEditStatusReducer,
		{
			lock: false,
		}
	);

	const [selectedRowState, selectedRowDispatch] = useReducer(
		vslSelectedRowReducer,
		{
			rowInfo: null,
		}
	);

	//use setHotTableInstance to set the hot table instance
	//so that it can be accessed outside the component in which it is defined
	const [hotTableInstance, setHotTableInstance] = useState<
		any | null | undefined
	>();

	const resetVslData = () => {
		setVslData([]);
	};

	const getVsls = async (vslFilter?: any) => {
		const params = {
			// limit: LIMIT,
			offset: 0,
			sortColumn: "studyid",
			isDescend: true,
		};
		try {
			const vsls = vslFilter
				? await agent.Vsls.list({
						...defaultEsvdParams,
						params: { ...params, ...vslFilter },
				  })
				: await agent.Vsls.list({
						...defaultEsvdParams,
						params,
				  });

			//const vsls = await agent.Vsls.list({ ...defaultEsvdParams, params:{...params, ...vslFilter} });
			if (!vsls) return [];
			setvslsCount(vsls.length);
			setVslData(vsls);
		} catch (error: any) {
			throw error.response ? error.response.statusText : error.message;
		}
	};

	// const getVslsCount = async (vslFilter?: any) => {
	// 	const { params } = defaultEsvdParams;
	// 	params.limit = 500;
	// 	// params.sortColumn = "authors";
	// 	try {
	// 		const vsls = vslFilter
	// 			? await agent.Vsls.listCount({
	// 					...defaultEsvdParams,
	// 					params: { ...params, ...vslFilter },
	// 			  })
	// 			: await agent.Vsls.listCount({
	// 					...defaultEsvdParams,
	// 					params,
	// 			  });
	// 		setvslsCount(vsls);
	// 	} catch (error: any) {
	// 		throw error.response ? error.response.statusText : error.message;
	// 	}
	// };
	const addVsl = async (vslUpdate: VslValuationStudy) => {
		try {
			delete vslUpdate.id;
			if (!vslUpdate.yearOfPublication) {
				delete vslUpdate.yearOfPublication;
			}
			return await agent.Vsls.create(vslUpdate);
		} catch (error: any) {
			throw error.response ? error.response.statusText : error.message;
		}
	};

	const editVsl = async (vslUpdate: VslValuationStudy) => {
		try {
			if (!vslUpdate.yearOfPublication) {
				delete vslUpdate.yearOfPublication;
			}
			return await agent.Vsls.update(vslUpdate);
		} catch (error: any) {
			throw error.response ? error.response.statusText : error.message;
		}
	};

	const deleteStudy = async (id: number) => {
		try {
			return await agent.Vsls.delete(id);
		} catch (error: any) {
			throw error.response ? error.response.statusText : error.message;
		}
	};

	return (
		<VslContext.Provider
			value={{
				getVsls,
				vslsCount,
				setHotTableInstance,
				hotTableInstance,
				editVsl,
				vslData,
				resetVslData,
				gridStateDispatch,
				gridLockState,
				selectedRowState,
				selectedRowDispatch,
				addVsl,
				deleteStudy,
				vslFilter,
				setVslFilters,
				setvslsCount,
				gridEditSycnLockDispatch,
				gridEditSyncLock,
				// getVslsCount,
			}}>
			{children}
		</VslContext.Provider>
	);
};

// The useStackData hook can be used by components under an StackDataProvider to
// access the auth context value.
const useVsl = () => {
	const vslH = useContext(VslContext);
	if (vslH == null) {
		throw new Error("useVsl() called outside of its hook.");
	}
	return vslH;
};

export { VslProvider, useVsl };
