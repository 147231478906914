import React, { useEffect } from "react";
import {
	Button,
	Label,
	Card,
	Checkbox,
	// Grid,
	Input,
	Rating,
} from "semantic-ui-react";
import {
	ErrorMessage,
	Form,
	Field,
	// FieldArray,
	Formik,
	useField,
} from "formik";

import * as Yup from "yup";
import agent from "../../api/agent";
import {
	ModalWithContextProvider,
	useModalWithContext,
} from "../../layouts/ModalWithContext";
import feedbackdata, { feedbackCsvHeaders } from "../../shared/FeedbackData";
import { COMPANY_EMAIL } from "../../shared/Literals";
import { useAuth } from "../../providers/AuthProvider";

const RatingField = ({
	field: name,
	...props
}: {
	field: any;
	name: string;
}) => {
	const [field, meta, helpers] = useField(name);

	return (
		<div className="custom-rating">
			<Rating
				{...field}
				{...props}
				onRate={(e, { rating }) => helpers.setValue(rating)}
			/>
			<div className="rating-numbers">
				{Array.from({ length: 10 }, (_, i) => (
					<span key={i} className="rating-number">
						{i + 1}
					</span>
				))}
			</div>
			{meta.touched && meta.error ? (
				<Label
					basic
					color="red"
					size="tiny"
					style={{ padding: "0px", border: "none", display: "block" }}>
					{meta.error}
				</Label>
			) : null}
		</div>
	);
};
export const TextInputField = ({
	field: name,
	...props
}: {
	field: any;
	name: string;
}) => {
	const [field, meta, helpers] = useField(name);

	return (
		<>
			<Input
				labelPosition="left"
				{...field}
				{...props}
				type={"text"}
				value={field.value ? field.value : field.value === 0 ? "0" : ""}>
				<textarea {...field} rows={4} cols={50} />
			</Input>
			{meta.touched && meta.error ? (
				<Label
					basic
					color="red"
					size="tiny"
					style={{ padding: "0px", border: "none", display: "block" }}>
					{meta.error}
				</Label>
			) : null}
		</>
	);
};

const CheckboxField = ({
	field: name,
	checkState,
	...props
}: {
	field: any;
	name: string;
	checkState: Function;
}) => {
	const [field, , helpers] = useField(name);

	return (
		<Checkbox
			{...props}
			checked={field.value}
			onChange={(e, { checked }) => {
				helpers.setValue(checked);
				checkState && checkState(checked);
			}}
		/>
	);
};

export const FeedbackForm = ({
	formDispatcher,
}: {
	formDispatcher: Function | undefined;
}) => {
	//use a separate dispacher for the modal that will show upon success or failure of feedback submission.
	//This way, the feedback form will not be affected by the closing of the that modal, since it has a different dispatch, formDispatcher
	const { modalDispatcher } = useModalWithContext();
	const { user } = useAuth();

	const closeModal = () => {
		formDispatcher && formDispatcher({ open: false });
	};

	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [checkStateOther, setCheckStateOther] = React.useState<any>(false);
	const [submitSuccess, setSubmitSuccess] = React.useState<any>(false);

	useEffect(() => {
		if (submitSuccess) {
			const thankMsg =
				"Thank you for taking the time to write to the ESVD team about the ESVD database. " +
				"Your feedback had been submitted to the ESVD team.\n\n" +
				"A copy of your feedback has been emailed to you too.";

			formDispatcher &&
				formDispatcher({
					open: true,
					size: "tiny",
					displayType: "info",
					options: {
						title: "Thank you",
						body: thankMsg,
						basicModal: true,
					},
				});
		}
	}, [submitSuccess]);

	const [validateSchema, setValidateSchema] = React.useState<any>(
		Yup.object({
			howSatisfied: Yup.number()
				.typeError("Must be a number")
				.min(1, `Please rate your satisfaction.`)
				.max(10, `Must be less than or equal to 10.`)
				.required("Required"),
			useforEsvd: Yup.string().required("Required"),
			// issueOtherText: Yup.string().required("Required"),
		})
	);

	const objToCSV = (obj: any) => {
		delete obj["error"];
		//get the keys of the object, but do not change the order of the keys
		let headers: string[] = []; // Object.keys(obj);
		let values: string[] = [];

		headers.push(feedbackCsvHeaders["howSatisfied"]);
		values.push(obj["howSatisfied"] || "undefined");

		headers.push(feedbackCsvHeaders["ideasforEsvd"]);
		values.push(obj["ideasforEsvd"] || "undefined");

		headers.push(feedbackCsvHeaders["useforEsvd"]);
		values.push(obj["useforEsvd"] || "undefined");

		headers.push(feedbackCsvHeaders["issueLoadingTables"]);
		values.push(obj["issueLoadingTables"] ? "Yes" : "No");

		headers.push(feedbackCsvHeaders["issueLoadingMapResult"]);
		values.push(obj["issueLoadingMapResult"] ? "Yes" : "No");

		headers.push(feedbackCsvHeaders["issueLoadingSumStart"]);
		values.push(obj["issueLoadingSumStart"] ? "Yes" : "No");

		headers.push(feedbackCsvHeaders["issueDownload"]);
		values.push(obj["issueDownload"] ? "Yes" : "No");

		headers.push(feedbackCsvHeaders["issueLoggin"]);
		values.push(obj["issueLoggin"] ? "Yes" : "No");

		headers.push(feedbackCsvHeaders["issueOther"]);
		values.push(obj["issueOther"] ? "Yes" : "No");

		//Include the issueOtherText only if issueOther is checked
		// if (obj["issueOther"]) {
		headers.push(feedbackCsvHeaders["issueOtherText"]);
		values.push(obj["issueOtherText"]);
		// }

		headers.push(feedbackCsvHeaders["additionalFeatures"]);
		values.push(obj["additionalFeatures"] || undefined);

		// console.log(headers);

		//Use values from feedbackCsvHeaders as headers by mapping the keys of the obj to the keys of feedbackCsvHeaders
		// const headers2 = headers.map(
		// 	(key) => feedbackCsvHeaders[key as keyof typeof feedbackCsvHeaders]
		// );

		//Loop through the headers and replace the text with the values from feedbackCsvHeaders, step by step
		// headers.forEach((key) => {

		// const values = Object.values(obj);

		let csv = headers.join(",") + "\n" + values.join(",");
		//search all feedbackCsvHeaders keys text in csv string and replace them with feedbackCsvHeaders values.
		// Object.keys(feedbackCsvHeaders).forEach((key) => {
		// 	csv = csv.replace(
		// 		key,
		// 		feedbackCsvHeaders[key as keyof typeof feedbackCsvHeaders]
		// 	);
		// });

		return csv;
	};

	const handleFormSubmit: Function = async (
		values: any,
		setErrors: Function
	) => {
		// console.log(values);
		const msg = messageBody(values);
		try {
			setIsLoading(true);
			await agent.FeedbackMessage.send({
				title: "ESVD database feedback",
				body: msg,
				attachmentString: objToCSV(values),
			})
				.then(() => {
					setSubmitSuccess(true);

					setIsLoading(false);
				})
				.catch((err) => {
					throw err;
				});
		} catch (error: any) {
			setSubmitSuccess(false);
			modalDispatcher &&
				modalDispatcher({
					open: true,
					size: "tiny",
					displayType: "error",
					options: {
						title: "Something happened...",
						body: `There was a problem sending your feedback. \nPlease try again or contact the ESVD Team at ${COMPANY_EMAIL}`,
						basicModal: true,
					},
				});
			setIsLoading(false);
			// console.log(error.response ? error.response.statusText : error.message);
		}
	};

	const messageBody = (frmData: any) => {
		//
		let msg = `<div style={{ marginBlockStart: "0em", padding: "0em 2.5em", marginBottom:"1em" }}>
						Dear ${user?.displayName ? user.displayName : user?.userName},
						<br />
						<p>
							Filling out this form provides us with information about your use of the ESVD and helps us to enhance your experience with it.
						</p>
		</div>
		<ol>
		<li>${feedbackdata.howSatisfied}<br/><br/>
		<br/>
		${frmData["howSatisfied"] || "undefined"}<br/><br/><br/>
		</li>
		<li>${feedbackdata.ideasforEsvd}<br/><br/>
		<br/>
		${frmData["ideasforEsvd"] || "undefined"}<br/><br/><br/>
		</li>
		<li>${feedbackdata.useforEsvd}<br/><br/>
		<br/>
		${frmData["useforEsvd"] || "undefined"}<br/><br/><br/>
		</li>	
		<li>${feedbackdata.issue.label}<br/><br/>
		<br/>
		${frmData ? processIssueOptions(frmData) : "undefined"}<br/><br/>
		</li>
		<li>${feedbackdata.additionalFeatures}<br/><br/>
		<br/>
		${frmData["additionalFeatures"] || "undefined"}<br/><br/><br/>
		</li>		
		</ol>		
		`;

		return msg;
	};

	const processIssueOptions = (frmData: any) => {
		//
		const options = feedbackdata.issue.options;
		let msg = "\t";
		let isIssue = false;
		if (frmData["issueLoadingTables"]) {
			msg += options.issueLoadingTables + "<br/><br/>";
			isIssue = true;
		}

		if (frmData["issueLoadingMapResult"]) {
			msg += "\t" + options.issueLoadingMapResult + "<br/><br/>";
			isIssue = true;
		}

		if (frmData["issueLoadingSumStart"]) {
			msg += "\t" + options.issueLoadingSumStart + "<br/><br/>";
			isIssue = true;
		}

		if (frmData["issueDownload"]) {
			msg += "\t" + options.issueDownload + "<br/><br/>";
			isIssue = true;
		}

		if (frmData["issueLoggin"]) {
			msg += "\t" + options.issueLoggin + "<br/><br/>";
			isIssue = true;
		}

		if (frmData["issueOther"]) {
			msg += "\t" + options.issueOther + "<br/>";
			msg += "<blockquote>" + frmData["issueOtherText"] + "</blockquote>";
			isIssue = true;
		}

		if (!isIssue) {
			msg += "\t" + "undefined" + "<br/>";
		}

		return msg;
	};

	return (
		<Card.Content style={{ overflowY: "auto", height: "100%" }}>
			<Formik
				enableReinitialize={true}
				initialValues={{
					howSatisfied: 0,
					error: null,
					useforEsvd: "",
					issueOtherText: "",
				}}
				validationSchema={validateSchema}
				onSubmit={(values, { setErrors }) => {
					handleFormSubmit(values, setErrors);
				}}>
				{({ handleSubmit, errors }) => (
					<Form onSubmit={handleSubmit} className="custom-ui-element">
						<div
							style={{
								marginBlockStart: "0em",
								padding: "0em 2.5em",
								marginBottom: "1em",
							}}>
							Dear {user?.displayName ? user.displayName : user?.userName},
							<br />
							<p>
								Filling out this form provides us with information about your
								use of the ESVD and helps us to enhance your experience with it.
							</p>
						</div>
						<ol>
							<li className="feedback-list">
								<div
									dangerouslySetInnerHTML={{
										__html: ` ${feedbackdata.howSatisfied}`,
									}}
								/>
								<br />
								<Field
									name="howSatisfied"
									component={RatingField}
									maxRating={10}
									size="massive"
									icon="star"
								/>
							</li>
							<li className="feedback-list">
								{feedbackdata.ideasforEsvd}
								<br />
								<Field name="ideasforEsvd" component={TextInputField} />
							</li>
							<li className="feedback-list">
								<div
									dangerouslySetInnerHTML={{
										__html: ` ${feedbackdata.useforEsvd}`,
									}}
								/>
								<br />
								<Field name="useforEsvd" component={TextInputField} />
							</li>
							<li className="feedback-list">
								{feedbackdata.issue.label}
								<Field
									name="issueLoadingTables"
									component={CheckboxField}
									// label="Loading table results"
									label={feedbackdata.issue.options.issueLoadingTables}
									checkState={null}
								/>
								<br />
								<Field
									name="issueLoadingMapResult"
									component={CheckboxField}
									label={feedbackdata.issue.options.issueLoadingMapResult}
									checkState={null}
								/>
								<br />
								<Field
									name="issueLoadingSumStart"
									component={CheckboxField}
									label={feedbackdata.issue.options.issueLoadingSumStart}
									checkState={null}
								/>
								<br />
								<Field
									name="issueDownload"
									component={CheckboxField}
									label={feedbackdata.issue.options.issueDownload}
									checkState={null}
								/>
								<br />
								<Field
									name="issueLoggin"
									component={CheckboxField}
									label={feedbackdata.issue.options.issueLoggin}
									checkState={null}
								/>
								<br />
								<Field
									name="issueOther"
									component={CheckboxField}
									label={feedbackdata.issue.options.issueOther}
									// checkState={(checked: boolean) => setCheckStateOther(checked)}
									checkState={(checked: boolean) => {
										setCheckStateOther(checked);
										if (checked) {
											setValidateSchema(
												Yup.object({
													howSatisfied: Yup.number()
														.typeError("Must be a number")
														.min(1, `Please rate your satisfaction.`)
														.max(10, `Must be less than or equal to 10.`)
														.required("Required"),
													useforEsvd: Yup.string().required("Required"),
													issueOtherText: Yup.string().required(
														"Please specify other issues or bugs"
													),
												})
											);
										} else {
											setValidateSchema(
												Yup.object({
													howSatisfied: Yup.number()
														.typeError("Must be a number")
														.min(1, `Please rate your satisfaction.`)
														.max(10, `Must be less than or equal to 10.`)
														.required("Required"),
													useforEsvd: Yup.string().required("Required"),
													// issueOtherText: Yup.string().required("Required"),
												})
											);
										}
									}}
								/>
								{checkStateOther && (
									<Field name="issueOtherText" component={TextInputField} />
								)}
							</li>
							<li className="feedback-list">
								{/* Which additional features (additional filters, value transfer
								function) would you like to see on esvd.net which would assist
								you in better using the data on esvd.net? */}
								{feedbackdata.additionalFeatures}
								<br />
								<Field name="additionalFeatures" component={TextInputField} />
							</li>
						</ol>
						<Button
							className="btn btn-feedback btn-warning"
							floated="left"
							style={{
								marginLeft: "2.5em",
								minWidth: "4em",
								backgroundColor: "#f6da6e",
							}}
							onClick={closeModal}>
							Close
						</Button>
						<Button
							className="btn btn-feedback btn-primary"
							type="submit"
							// primary
							floated="right"
							loading={isLoading}
							style={{
								backgroundColor: "#70bbfd",
								marginRight: "2.5em",
								minWidth: "4em",
							}}>
							Submit
						</Button>
						<ErrorMessage
							name="error"
							render={() => (
								<Label
									style={{ marginBottom: 5, borderColor: "none" }}
									basic
									color="red"
									size="tiny"
									content={errors.error}
								/>
							)}
						/>
					</Form>
				)}
			</Formik>
		</Card.Content>
	);
};

const FeedbackFormWrapper = ({
	formDispatcher,
}: {
	formDispatcher: Function | undefined;
}) => {
	return (
		<ModalWithContextProvider>
			<FeedbackForm formDispatcher={formDispatcher} />
		</ModalWithContextProvider>
	);
};

export default FeedbackFormWrapper;
