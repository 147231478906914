import { useState } from "react";
import { FieldArray, Formik, useField } from "formik";
import React, { Fragment, useEffect } from "react";
import {
	Form,
	Card,
	Checkbox,
	Grid,
	Table,
	TableBody,
	TableRow,
	TableCell,
	TableHeader,
	TableHeaderCell,
} from "semantic-ui-react";

import { useEsvdv2 } from "../../providers/Esvdv2Provider";
import { formatBiomeForDropdown } from "../../EsvdFunctions";
import { TreeType } from "../../models/ecosystemService";
// import { useMedia } from "../../layouts/MediaLayout";

const ItemContent = (props: any) => {
	const [field, meta] = useField(props);
	const { label, checked, setChecked } = props;

	const [isCheck, setIsCheck] = useState<boolean>(checked);

	return (
		<Form.Field error={meta.touched && !!meta.error}>
			<Grid columns={2} centered>
				<Grid.Row>
					<Grid.Column width={12}>
						<label>{label}</label>
					</Grid.Column>
					<Grid.Column
						width={4}
						verticalAlign="middle"
						// floated="right"
						// style={{ paddingRight: "2em" }}
					>
						<Checkbox
							style={{ paddingTop: "0.3em" }}
							onChange={(e, data: any) => {
								setIsCheck(data.checked);
								setChecked(data.checked);
							}}
							checked={isCheck}
						/>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		</Form.Field>
	);
};

const ItemHeaderContent = (props: any) => {
	const { label } = props;
	return (
		<Grid columns={2} centered style={{ paddingTop: "2em" }}>
			<Grid.Row style={{ backgroundColor: "#f9fafb", marginBottom: "0.5em" }}>
				<Grid.Column width={12}>
					<h5>
						<strong>{label}</strong>
					</h5>
				</Grid.Column>
				<Grid.Column
					width={4}
					verticalAlign="middle"
					// floated="left"
					// style={{ paddingRight: "2em" }}
					// style={{ paddingRight: "2em" }}
				>
					<h5>
						<strong>Include</strong>
					</h5>
				</Grid.Column>
			</Grid.Row>
		</Grid>
	);
};

const BiomeEcozoneStatReady = () => {
	const { getEsvdv2, toggleStatsReady } = useEsvdv2();
	const [freeVariables, setFreeVariables] = React.useState<
		{ label: string; options: TreeType[] }[] | null
	>();

	useEffect(() => {
		(async () => {
			const dat = await getEsvdv2();
			const datTree = formatBiomeForDropdown(dat, false);
			setFreeVariables(datTree);
		})();
	}, []);

	return (
		<>
			<Formik
				enableReinitialize={true}
				initialValues={{}}
				onSubmit={(values, { setErrors }) => {
					// handleFormSubmit(values, setErrors);
				}}>
				{({ values, handleSubmit, errors }) => (
					<Form
						onSubmit={handleSubmit}
						className="custom-ui-element"
						autoComplete={"off"}
						encType="multipart/form-data">
						<Card fluid>
							<Card.Content>
								<h4 className="title-card">Summary Stats Admin</h4>
							</Card.Content>
							<Card.Content>
								<div style={{ padding: "1em 2em" }}>
									<FieldArray
										name="paramLists"
										render={(arrayHelpers) => (
											<div>
												{freeVariables &&
													freeVariables.map((k, i) => {
														return (
															<div key={`container${i}`}>
																<ItemHeaderContent
																	key={`header${i}`}
																	name={`var-${k.label}`}
																	label={k.label}
																	placeholder={k.label}
																/>
																<Table
																	size="small"
																	// color={"blue"}
																	celled
																	selectable
																	key={`table${i}`}>
																	{/* <TableHeader >
																		<TableRow>
																			<TableHeaderCell>
																				{k.label}
																			</TableHeaderCell>
																		</TableRow>
																	</TableHeader> */}
																	<TableBody>
																		{k.options.map((t: any, j: number) => {
																			return (
																				<TableRow key={`body${i}-${j}`}>
																					<TableCell>
																						<ItemContent
																							name={`var-${t.id}`}
																							label={`${t.code} - ${t.label}`}
																							setChecked={async (
																								checkstate: any
																							) => {
																								await toggleStatsReady(
																									t.id,
																									checkstate
																								);
																							}}
																							checked={t.statsReady}
																						/>
																					</TableCell>
																				</TableRow>
																			);
																		})}
																	</TableBody>
																</Table>
															</div>
														);
													})}
											</div>
										)}
									/>
								</div>
							</Card.Content>
						</Card>
					</Form>
				)}
			</Formik>
		</>
	);
};

export default BiomeEcozoneStatReady;
