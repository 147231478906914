import React from "react";
import { Card } from "semantic-ui-react";
import { COMPANY_EMAIL } from "../../shared/Literals";

const Contact = () => {
	return (
		<Card fluid>
			<Card.Content>
				<h4 className="title-card">Contact</h4>
			</Card.Content>
			<Card.Content>
				<p>
					Want to stay informed?{" "}
					<a
						className="esvd-hyperlink"
						href="https://www.es-partnership.org/sign-up/"
						target="_blank"
						rel="noopener noreferrer">
						Subscribe for the monthly Ecosystem Services Partnership Update.
					</a>{" "}
				</p>
				<p>
					The ESVD is Open Source and voluntary contributions are much
					appreciated to maintain and further improve the database.
					Contributions can help us expand the database to cover more biomes and
					regions as well as to further develop the database functionalities.{" "}
					<a
						className="esvd-hyperlink"
						href="https://esvd.info"
						target="_blank"
						rel="noopener noreferrer">
						Visit our info website
					</a>{" "}
					if you wish to contribute to the development of ESVD.
				</p>
				<p>
					Any questions? Send us an{" "}
					<a className="esvd-hyperlink" href={`mailto:${COMPANY_EMAIL}`}>
						email
					</a>
					.
				</p>
			</Card.Content>
		</Card>
	);
};

export default Contact;
