import React, { useEffect } from "react";
import { Grid, Container } from "semantic-ui-react";
import ApiGenerator from "./ApiGenerator";
import { actions, resources } from "../../shared/Literals";
import Can from "../../accessControls/Can";
import { Redirect } from "react-router";
import MediaLayout from "../../layouts/MediaLayout";
import { useActiveMenu } from "../../providers/ActiveMenuProvider";

const { Media, MediaContextProvider } = MediaLayout;

const ApiGeneratorBoard = () => {
	const { setActiveItem } = useActiveMenu();

	useEffect(() => {
		// resetEsvds();
		setActiveItem(resources.suggestStudy.name);
	}, []);

	const renderContent = () => {
		return (
			<MediaContextProvider>
				<Media at="mobile" className="page-content">
					<ApiGenerator />
				</Media>
				<Media greaterThan="mobile">
					<Container fluid className="top-container">
						<Grid columns={2} className="page-content" stackable>
							<Grid.Row>
								<Grid.Column width={12}>
									<ApiGenerator />
								</Grid.Column>
								<Grid.Column width={4}></Grid.Column>
							</Grid.Row>
						</Grid>
					</Container>
				</Media>
			</MediaContextProvider>
		);
	};

	return (
		<Can
			action={actions.read}
			resource={resources.apikeySetup.name}
			yes={() => renderContent()}
			no={() => <Redirect to={"/"} />}
		/>
	);
};

const ApiGeneratorBoardWrapper = () => {
	return <ApiGeneratorBoard />;
};
export default ApiGeneratorBoardWrapper;
