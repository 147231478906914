import React, { MouseEvent, useEffect, useState } from "react";
import { Card, Grid, Item, Menu, Segment } from "semantic-ui-react";
import VttValueCalculationEntry from "./VttValueCalculationEntry";
import { VttBiomeProvider } from "../../../providers/VttBiomeProvider";
import { VttSubBiomeProvider } from "../../../providers/VttSubBiomeProvider";
import { VttVariableProvider } from "../../../providers/VttVariableProvider";
import { VttVariableDetailMapProvider } from "../../../providers/VttVariableDetailMapProvider";
import { VttProvider } from "../../../providers/VttProvider";

const VttCalculateBase = () => {
	const [headerText, setHeaderText] = useState("Biome");
	// const { currentView, setCurrentView } = useVtt();
	// const [view, setView] = useState(ViewHandles.biome);

	// useEffect(() => {}, [currentView]);

	// const handleItemClick = (e: any, data: any) => {
	// 	const { name, content } = data;
	// 	setHeaderText(content);
	// 	setCurrentView({ view: name });
	// };

	// const renderContent = () => {
	// 	switch (currentView.view) {
	// 		case ViewHandles.manage:
	// 			return <VttBiomeDetails />;
	// 		case ViewHandles.manageDetail:
	// 			return <VttVariableDetailEntry />;
	// 		case ViewHandles.subbiome:
	// 			return <VttSubBiomeList />;
	// 		case ViewHandles.subbiomeEntry:
	// 			return <VttSubBiomeEntry />;
	// 		case ViewHandles.variable:
	// 			return <VttVariableList />;
	// 		case ViewHandles.variableEntry:
	// 			return <VttVariableEntry />;
	// 		case ViewHandles.biomeEntry:
	// 			return <VttBiomeEntry />;
	// 		default:
	// 			return <VttBiomeList />;
	// 	}
	// };//style={{ height: "80vh" }}
	return (
		<Grid style={{ height: "90vh" }} centered>
			{/* <Grid.Column stretched width={4}></Grid.Column> */}
			<Grid.Column stretched>
				<Card fluid>
					<Card.Content>
						<h4 className="title-card">Value Transfer Tool</h4>
					</Card.Content>
					<VttBiomeProvider>
						<VttSubBiomeProvider>
							<VttVariableProvider>
								<VttVariableDetailMapProvider>
									<VttValueCalculationEntry />
								</VttVariableDetailMapProvider>
							</VttVariableProvider>
						</VttSubBiomeProvider>
					</VttBiomeProvider>
				</Card>
			</Grid.Column>
		</Grid>
	);
};

const VttCalculateBaseWrapper = () => {
	return (
		<VttProvider>
			<VttCalculateBase />
		</VttProvider>
	);
};
export default VttCalculateBaseWrapper;
