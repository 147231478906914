import { ErrorMessage, Form, Formik } from "formik";
import React, { useEffect } from "react";
import { Card, Grid, Label } from "semantic-ui-react";
import * as Yup from "yup";
import EsvdInput from "../../shared/Input";

import { ResendConfirmation } from "../../models/user";
import { Link, Redirect } from "react-router-dom";
import { ErrorHandler } from "../../shared/ExceptionHandling";
import { useAuth } from "../../providers/AuthProvider";
import LoginsContainer from "./LoginsContainer";

interface IProps {
	match: any;
	username: string;
	setUserLoginMode: Function;
}

const Login = (props: IProps) => {
	const { user, login, resendconfirmationlink } = useAuth();
	const { match, username, setUserLoginMode } = props;
	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [isSent, setIsSent] = React.useState<boolean>(false);

	useEffect(() => {
		return () => {};
	}, []);

	const handleResendLink = async (value: ResendConfirmation) => {
		setIsLoading(true);
		resendconfirmationlink(value)
			.then((res: any) => {
				setIsSent(true);
				setIsLoading(false);
			})
			.catch((error: any) => {
				setIsLoading(false);
			});
	};

	const renderContent = () => {
		if (!user) {
			return (
				<Card
					centered
					// className="small-card"
					style={{
						padding: "10px",
					}}>
					<Card.Content textAlign="left">
						<h4 className="title-card">
							Welcome to the ESVD
							{/* <span className="logo-accent"></span> */}
						</h4>
					</Card.Content>
					{isSent ? (
						<Card.Content className="text-center">
							<Card.Description>Send confirmation link </Card.Description>
							An email has been sent to your inbox. <br />
							Please check your inbox to reset your password.
							<br />
							If you don't see an email, please check your spam folder.
						</Card.Content>
					) : (
						<Card.Content textAlign="left">
							<Card.Description>
								Login below if you already have an account. If you do not have
								an account yet, please Sign Up first by clicking the Sign Up
								button below and create your account.
							</Card.Description>
							<br />
							<Formik
								validationSchema={validationSchema}
								enableReinitialize
								initialValues={{
									username: `${username}`,
									password: "",
									error: null,
								}}
								onSubmit={(values, { setErrors }) => {
									setIsLoading(true);
									login(values)
										.then((res: any) => {
											setIsLoading(false);
										})
										.catch((error: any) => {
											setErrors({
												error: ErrorHandler(error),
											});
											setIsLoading(false);
										});
								}}>
								{({
									handleSubmit,
									values,
									isValid,
									dirty,
									isSubmitting,
									errors,
								}) => (
									<Form
										className="ui form page-content"
										onSubmit={handleSubmit}
										autoComplete={"off"}
										style={
											{
												// marginBottom: "10px",
											}
										}>
										<EsvdInput
											name="username"
											label="Username"
											fluid
											icon={"user outline"}
											placeholder="Username"
											value={username}
											disabled={true}
										/>
										<EsvdInput
											name="password"
											label="Password"
											type="password"
											fluid
											icon={"key"}
											placeholder="Password"
											autoFocus={true}
										/>

										<Grid>
											<Grid.Row
												style={{
													marginTop: "0",
													paddingTop: "0px",
													fontSize: "0.8rem",
												}}>
												<Grid.Column style={{ marginTop: "0" }}>
													<Link
														style={{ marginTop: "0" }}
														to="/forgotpassword"
														className="ui right floated">
														Forgot a password?
													</Link>
													<ErrorMessage
														name="error"
														render={() => (
															<>
																<Label
																	style={{
																		border: "none",
																		color: "red",
																	}}
																	basic
																	// color="red"
																	content={errors.error}></Label>
																{errors.error &&
																	errors.error.includes(
																		"Email not confirmed"
																	) && (
																		<button
																			style={{
																				padding: "5px",
																				marginBottom: "5px",
																				border: "none",
																				backgroundColor: "#fff",
																				color: "#70bbfd",
																				cursor: "pointer",
																			}}
																			onClick={() =>
																				handleResendLink({
																					username: values.username,
																				})
																			}
																			// to={{
																			//   pathname: "/resendconfirmelink",
																			//   search: "?sort=name",
																			//   hash: "#the-hash",
																			//   state: { username: values.username },
																			// }}
																			className="ui right floated">
																			Resend confirmation link?
																		</button>
																	)}
															</>
														)}
													/>
												</Grid.Column>
											</Grid.Row>
											<Grid.Row>
												<Grid.Column width={8}>
													{" "}
													<button
														className="ui button fluid btn btn-primary"
														style={{
															border: "0.05rem solid #70bbfd",
															borderRadius: "5px",
															borderColor: "#70bbfd",
															backgroundColor: "#fff",
															height: "2.8rem",
															color: "#70bbfd",
															lineHeight: "2.2rem",
														}}
														onClick={() => setUserLoginMode(null)}
														type="button">
														Back
													</button>
												</Grid.Column>
												<Grid.Column width={8}>
													<button
														disabled={!dirty || (dirty && !isValid)}
														className="ui button fluid btn btn-primary"
														type="submit"
														style={{
															backgroundColor: "#70bbfd",
															display: "block !important",
															height: "3rem",
														}}
														//let this button be the default enter when the user press enter
													>
														<span className={`${isLoading ? "hide" : ""}`}>
															Login
														</span>
														<span
															className={`box loader-01 ${
																isLoading ? "" : "invisible"
															}`}></span>
													</button>
												</Grid.Column>
											</Grid.Row>
										</Grid>
									</Form>
								)}
							</Formik>
						</Card.Content>
					)}
				</Card>
			);
		}
		if (match.params) {
			const { params } = match;
			if (Object.keys(params).length === 0) {
				return <Redirect to="/" />;
			} else {
				return <Redirect to={`/${params.returnUrl}`} />;
			}
		} else {
			//a login user shouldn't see the login page.
			//Therefore is a logged user access the login route, go to home
			// history.push("/");
			return <Redirect to="/" />;
		}
	};
	// render() {
	return <LoginsContainer>{renderContent()}</LoginsContainer>;
	// }
};

const validationSchema = Yup.object({
	username: Yup.string().required("Username is required"),
	password: Yup.string().required("Enter password"),
});

// const mapStateToProps = (state: any) => {
// 	return { user: state.user };
// };

export default Login;
// export default connect(mapStateToProps, {
// 	login,
// 	resendconfirmationlink,
// })(Login);
