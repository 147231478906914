import React from "react";
import { Route, Switch } from "react-router-dom";
import EditorStudyBoard from "../components/editStudy/EditorStudyBoard";

import ConfirmEmail from "../components/logins/ConfirmEmail";
import ForgotPassword from "../components/logins/ForgotPassword";
import UsernameOrEmail from "../components/logins/UsernameOrEmail";
import Logout from "../components/logins/Logout";
import Signup from "../components/logins/Signup";
import { resources } from "../shared/Literals";
import HomePage from "../components/home/HomePage";
import FilterQueriesDownloadsBoard from "../components/generalDownloads/FilterQueriesDownloadsBoard";
import SettingsMain from "../components/settings/SettingsMain";
import ResetPasswordWrapper from "../components/logins/ResetPasswordWrapper";
import UserProfile from "../components/useraccounts/UserProfile";
import UserProfileDownloadsBoard from "../components/generalDownloads/UserProfileDownloadsBoard";
import { ValuationsProvider } from "../providers/ValuationsProvider";
import StudyBoardWrapper from "../components/studies/StudyBoardWrapper";
import UploadDatasetBoard from "../components/uploadDatasets/UploadDatasetBoard";
import EsvdDatabaseHome from "../components/esvdPanel/EsvdDatabaseHome";
import VttHome from "../components/vtts/VttHome";
import VttCalculateHomeWrapper from "../components/vtts/vttsCalculations/VttCalculateHome";
import VslDatabaseHomeWrapper from "../components/vsls/VslDatabaseHome";
import VslUploadDatasetBoard from "../components/vslUploadDatasets/VslUploadDatasetBoard";
import SuggestionBoardWrapper from "../components/studySuggest/SuggestionBoard";
import StatsReadyBoard from "../components/esvdStats/StatsReadyBoard";
import ApiGeneratorBoardWrapper from "../components/apisKeyGenerator/ApiGeneratorBoard";
import EarlyBirdBoardwrapper from "../components/earlybirds/EarlyBirdBoard";
import LoginWrapper from "../components/logins/LoginWrapper";
import EsvdDatabaseHomeReadonlyWrapper from "../components/esvdReadonlyPanel/EsvdDatabaseHomeReadonly";

const Routes = (props: any) => {
	return (
		<Switch>
			<Route
				exact
				path={"/"}
				render={({ location }) => {
					return <HomePage />;
				}}
			/>
			<Route
				exact
				path={`${resources.esvd.path}`}
				render={({ location }) => {
					//this saves the url of the page so that in case of a refresh, we get that url and navigate to that location.
					return <EsvdDatabaseHome />;
				}}
			/>
			<Route exact path={"/login"} component={LoginWrapper} />
			<Route exact path={"/UsernameOrEmail"} component={UsernameOrEmail} />
			<Route path={"/login/:returnUrl"} component={LoginWrapper} />
			<Route exact path={"/signup"} component={Signup} />
			<Route exact path={"/logout"} component={Logout} />{" "}
			<Route exact path={"/forgotpassword"} component={ForgotPassword} />
			<Route exact path={"/resetpassword"} component={ResetPasswordWrapper} />
			<Route exact path={"/confirmemail"} component={ConfirmEmail} />
			<Route
				exact
				path={`${resources.study.path}`}
				render={({ location }) => {
					return <StudyBoardWrapper />;
				}}
			/>
			<Route
				exact
				path={`${resources.suggestStudy.path}`}
				render={({ location }) => {
					return <SuggestionBoardWrapper />;
				}}
			/>
			<Route
				exact
				path={`${resources.studyEditor.path}`}
				render={({ location }) => {
					return (
						<ValuationsProvider>
							<EditorStudyBoard />
						</ValuationsProvider>
					);
				}}
			/>
			<Route
				exact
				path={resources.userProfile.path}
				render={({ location }) => {
					// setActiveItem(resources.userProfile.name);
					return <UserProfile />;
				}}
			/>
			<Route
				exact
				path={resources.userprofiledownloads.path}
				render={({ location }) => {
					// setActiveItem(resources.filterQuery.name);
					return <UserProfileDownloadsBoard />;
				}}
			/>
			<Route
				exact
				path={resources.filterqueriesdownloads.path}
				render={({ location }) => {
					// setActiveItem(resources.filterQuery.name);
					return <FilterQueriesDownloadsBoard />;
				}}
			/>
			<Route
				exact
				path={resources.settings.path}
				render={({ location }) => {
					return <SettingsMain />;
				}}
			/>
			<Route
				exact
				path={resources.uploads.path}
				render={({ location }) => {
					return <UploadDatasetBoard />;
				}}
			/>
			<Route
				exact
				path={resources.vtt.path}
				render={({ location }) => {
					return <VttHome />;
				}}
			/>
			<Route
				exact
				path={resources.vttCalculations.path}
				render={({ location }) => {
					return <VttCalculateHomeWrapper />;
				}}
			/>
			<Route
				exact
				path={resources.vsl.path}
				render={({ location }) => {
					return <VslDatabaseHomeWrapper />;
				}}
			/>
			<Route
				exact
				path={resources.vsluploads.path}
				render={({ location }) => {
					return <VslUploadDatasetBoard />;
				}}
			/>
			<Route
				exact
				path={resources.statsReady.path}
				render={({ location }) => {
					return <StatsReadyBoard />;
				}}
			/>
			<Route
				exact
				path={resources.apikeySetup.path}
				render={({ location }) => {
					return <ApiGeneratorBoardWrapper />;
				}}
			/>
			<Route
				exact
				path={resources.earlybird.path}
				render={({ location }) => {
					return <EarlyBirdBoardwrapper />;
				}}
			/>
			<Route
				exact
				path={resources.studyValuations.path}
				render={(props) => {
					return <EsvdDatabaseHomeReadonlyWrapper />;
				}}
			/>
		</Switch>
	);
};

export default Routes;
