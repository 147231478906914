import moment from "moment";
import React, { useEffect, useState } from "react";
import { Card, Grid, Header, Label, List } from "semantic-ui-react";
import { FilterQuery } from "../../models/FilterQuery";
import { useFilterQuery } from "../../providers/FilterQueryProvider";
import { LIMIT } from "../../shared/Literals";
import LoadingOverlay from "../../shared/LoadingOverlay";
import EsvdPagination from "../esvdPanel/EsvdPagination";
import FilterQueryButton from "./FilterQueryButton";

// interface IProps {
// 	onSelectionChanged: Function;
// 	headerText: string;
// }

const FilterQueryList = () => {
	const { getTotalCount, getFilterQueries } = useFilterQuery();
	const [isLoading, setIsLoading] = React.useState(false);
	const [currentPage, setCurrentPage] = React.useState(1);
	const [totalFilterQueries, setTotalFilterQueries] = useState<number>(0);
	const [filterQueries, setFilterQueries] = useState<FilterQuery[]>([]);

	useEffect(() => {
		setIsLoading(true);
		(async () => {
			var filtersCnt = await getTotalCount();
			setTotalFilterQueries(filtersCnt);
		})();

		(async () => {
			var filters = await getFilterQueries();
			setFilterQueries(filters);
			setIsLoading(false);
		})();

		return () => {};
	}, []);

	const renderContent = () => {
		return (
			<>
				<LoadingOverlay active={isLoading} text="Loading filter queries" />
				<List celled selection animated>
					{filterQueries.map((s, i) => {
						const { filterObject } = s;
						if (filterObject) {
							const {
								biomeV2EcosystemV2Ecozones,
								// biomeEcosystems,
								ecosystemServices,
								freeText,
								countries,
								continents,
								ciceses,
								seeas,
								protectionStatuses,
								valuationMethods,
								resultsCount,
							} = filterObject;
							return (
								<List.Item key={i}>
									<List.Header>{`User: ${s.user}`}</List.Header>
									<List.Header>{`Valuation Rows(s): ${
										resultsCount || 0
									}`}</List.Header>
									<List.Description>
										{`Date: ${moment(s.createdOn).format(
											"DD-MM-YYYY HH:mm:ss"
										)}`}
									</List.Description>
									<List.Content>
										{/* {biomeEcosystems &&
											`Biome/Ecosystems: ${
												Array.isArray(biomeEcosystems)
													? biomeEcosystems.join(";")
													: biomeEcosystems
											}\n`}
										{biomeEcosystems && <br />} */}
										{biomeV2EcosystemV2Ecozones &&
											` Biome/Ecozone: ${
												Array.isArray(biomeV2EcosystemV2Ecozones)
													? biomeV2EcosystemV2Ecozones.join(";")
													: biomeV2EcosystemV2Ecozones
											}`}
										{ecosystemServices &&
											` TEEB: ${
												Array.isArray(ecosystemServices)
													? ecosystemServices.join(";")
													: ecosystemServices
											}`}
										{ecosystemServices && <br />}
										{countries &&
											` Countries: ${
												Array.isArray(countries)
													? countries.join(";")
													: countries
											}`}
										{countries && <br />}
										{continents &&
											` Continent: ${
												Array.isArray(continents)
													? continents.join(";")
													: continents
											}`}
										{continents && <br />}
										{protectionStatuses &&
											` Protection Status: ${
												Array.isArray(protectionStatuses)
													? protectionStatuses.join(";")
													: protectionStatuses
											}`}
										{protectionStatuses && <br />}
										{ciceses &&
											` CICES: ${
												Array.isArray(ciceses) ? ciceses.join(";") : ciceses
											}`}
										{ciceses && <br />}
										{seeas &&
											` SEEA: ${
												Array.isArray(seeas) ? seeas.join(";") : seeas
											}`}
										{seeas && <br />}
										{valuationMethods &&
											` Valuation Method: ${
												Array.isArray(valuationMethods)
													? valuationMethods.join(";")
													: valuationMethods
											}`}
										{valuationMethods && <br />}
										{freeText && ` Free Text Search: ${freeText}`}
									</List.Content>
								</List.Item>
							);
						} else {
							return null;
						}
					})}
				</List>
			</>
		);
	};

	function setPage(activePage: string | number | undefined) {
		setIsLoading(true);
		setCurrentPage(Number(activePage));
		(async () => {
			var filters = await getFilterQueries({
				params: {
					offset: activePage ? Number(activePage) - 1 : 0,
				},
			});
			setFilterQueries(filters);
			setIsLoading(false);
		})();
	}

	return (
		<Card fluid style={{ height: "90vh" }}>
			<Card.Content style={{ marginBottom: "0", paddingBottom: "0.5em" }}>
				<h4
					className="title-card"
					// textAlign="center"
					style={{ display: "inline-block" }}>
					Saved Filter Queries
				</h4>
				<div className="ui right floated">
					<FilterQueryButton />
					<br />
					<Label
						className="ui right floated"
						style={{ marginTop: "0.5em", marginRight: "0.5em" }}>
						{`${
							LIMIT * currentPage < totalFilterQueries
								? LIMIT * currentPage
								: totalFilterQueries
						} of ${totalFilterQueries}`}
					</Label>
				</div>
			</Card.Content>
			<Card.Content style={{ overflowY: "auto" }}>
				{renderContent()}
			</Card.Content>
			<Card.Content>
				<Grid>
					<Grid.Row style={{ marginTop: "1em", marginBottom: "1em" }}>
						<Grid.Column width={4}>
							<EsvdPagination
								currentPage={currentPage}
								setActivePage={setPage}
								totalPages={totalFilterQueries / LIMIT}
							/>
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</Card.Content>
		</Card>
	);
};

export default FilterQueryList;
