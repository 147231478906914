import React from "react";
import { Grid, Container } from "semantic-ui-react";
import { actions, resources } from "../../shared/Literals";

import Can from "../../accessControls/Can";
import { Redirect } from "react-router";
import MediaLayout from "../../layouts/MediaLayout";
import VslUploadDataset from "./VslUploadDataset";
import { VslGridHubProvider } from "../../providers/VslGridHubProvider";

const { Media, MediaContextProvider } = MediaLayout;

const VslUploadDatasetBoard = (props: any) => {
	const renderContent = () => {
		return (
			<VslGridHubProvider>
				<MediaContextProvider>
					<Media at="mobile">
						<VslUploadDataset />
					</Media>
					<Media greaterThan="mobile">
						<Container className="top-container">
							<Grid className="page-content" stackable>
								<Grid.Row>
									<Grid.Column width={16} stretched>
										<VslUploadDataset />
									</Grid.Column>
								</Grid.Row>
							</Grid>
						</Container>
					</Media>
				</MediaContextProvider>
			</VslGridHubProvider>
		);
	};
	return (
		<Can
			action={actions.read}
			resource={resources.vsluploads.name}
			yes={() => renderContent()}
			no={() => <Redirect to={"/"} />}
		/>
	);
};

export default VslUploadDatasetBoard;
