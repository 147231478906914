export function ErrorHandler(error: any): string {
  if (typeof error === "string") return error;
  if (typeof error === "object") {
    const status = error.status;
    switch (status) {
      case 400:
        return error.title.toString();
      case 401:
        return "Your password is not correct or the combination of username and password is not found. Please check and try again.";
      default:
        return error.title
          ? error.title
          : "Something unexpected has happened. Please try again or consult the admin for this site.";
    }
  }
  return "Something unexpected has happened. Please try again or consult the admin for this site.";
}
