import { useEffect } from "react";
import { Grid, Container } from "semantic-ui-react";
import EsvdActionsPanel from "./EsvdActionsPanel";
import Can from "../../accessControls/Can";
import { actions, resources } from "../../shared/Literals";
import { resetEsvds } from "../../actions/esvdActions";
import EsvdStatisticsPanel from "../esvdStats/EsvdStatisticsPanel";
import EsvdGetStartedPanel from "./EsvdGetStartedPanel";
import { Redirect } from "react-router";
import MediaLayout from "../../layouts/MediaLayout";
import {
	EsvdValuationProvider,
	// useEsvdValuation,
} from "../../providers/EsvdValuationProvider";
import EsvdDataWrapper from "./EsvdDataWrapper";
import { ListsProvider } from "../../providers/ListsProvider";
import EsvdFilterPanel from "./EsvdFilterPanel";
import StatisticsBiomePanel from "../esvdStats/StatisticsBiomePanel";
import StatisticsEcozonePanel from "../esvdStats/StatisticsEcozonePanel";
import { ModalWithContextProvider } from "../../layouts/ModalWithContext";
const { Media, MediaContextProvider } = MediaLayout;

const EsvdDatabaseHome = () => {
	//reset filters
	// updateQueryParams;
	// const { updateQueryParams, clearEsvds } = useEsvdValuation();
	useEffect(() => {
		resetEsvds();
		// updateQueryParams(null);
	}, []);

	const renderContent = () => {
		return (
			<MediaContextProvider>
				<Media at="mobile" className="page-content">
					<EsvdGetStartedPanel />
					<EsvdFilterPanel />
					<EsvdDataWrapper />
					<EsvdActionsPanel />
					<StatisticsBiomePanel />
					<StatisticsEcozonePanel />
					<EsvdStatisticsPanel />
				</Media>
				<Media greaterThan="mobile">
					<Container fluid className="top-container">
						<Grid columns={2} className="page-content" stackable>
							<Grid.Row>
								<Grid.Column width={12} stretched>
									<EsvdGetStartedPanel />
									<EsvdDataWrapper />
								</Grid.Column>
								<Grid.Column width={4}>
									<EsvdFilterPanel />
									<EsvdActionsPanel />
								</Grid.Column>
							</Grid.Row>
							<Grid.Row>
								<Grid.Column width={12}>
									<StatisticsBiomePanel />
									<StatisticsEcozonePanel />
								</Grid.Column>
								<Grid.Column width={4}>
									<EsvdStatisticsPanel />
								</Grid.Column>
							</Grid.Row>
						</Grid>
					</Container>
				</Media>
			</MediaContextProvider>
		);
	};

	return (
		<Can
			action={actions.read}
			resource={resources.esvd.name}
			yes={() => {
				return renderContent();
			}}
			no={() => {
				return <Redirect to={"/login"} />;
			}}
		/>
	);
};

const EsvdDatabaseHomeWrapper = (props: any) => {
	return (
		<EsvdValuationProvider>
			<ModalWithContextProvider>
				<ListsProvider>
					<EsvdDatabaseHome />
				</ListsProvider>
			</ModalWithContextProvider>
		</EsvdValuationProvider>
	);
};

export default EsvdDatabaseHomeWrapper;
