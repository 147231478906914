import React, { useEffect } from "react";
import { Button, Card } from "semantic-ui-react";

import { useReferenceInfo } from "../../providers/ReferenceInfoProvider";
import { useEsvdValuation } from "../../providers/EsvdValuationProvider";
import filterCheck from "../../shared/FilterCheck";
import agent from "../../api/agent";
import { Esvd } from "../../models/esvd";
import EsvdReadonlyTable from "./EsvdReadonlyTable";
import EsvdReadonlyMaps from "./EsvdReadonlyMaps";
import EsvdTableReadonlyPanel from "./EsvdTableReadonlyPanel";

const EsvdDataReadonlyWrapper = () => {
	const { currentInfo } = useReferenceInfo();

	const {
		esvdTotalCount,
		showMap,
		setShowMap,
		saveQueryParams,
		esvdQueryParams,
		getStudyEsvds,
		studyEsvds,
	} = useEsvdValuation();

	const [valuations, setValuations] = React.useState<Esvd[]>([]);

	React.useEffect(() => {
		//read headers from the request that was made to the server
		// (async () => {
		const urlParams = new URLSearchParams(window.location.search);
		const studyId = urlParams.get("studyId");
		if (!studyId) return;
		getStudyEsvds(studyId);

		// })();
	}, []);
	React.useEffect(() => {
		if (studyEsvds) {
			setValuations(studyEsvds);
		} else {
			setValuations([]);
		}
	}, [studyEsvds]);

	const switchBetweenMapTable = () => {
		//save query if valid
		if (!esvdQueryParams.saved && filterCheck(esvdQueryParams)) {
			//save query if not saved already
			saveQueryParams && saveQueryParams({ ...esvdQueryParams });
		}
		setShowMap(!showMap);
	};

	return (
		<Card fluid style={{ height: "80vh" }}>
			<Card.Content>
				{showMap ? (
					<h4 className="title-card" style={{ display: "inline-block" }}>
						Valuations:{" "}
						<span>{`${
							valuations && valuations.length
						} of ${esvdTotalCount}`}</span>
					</h4>
				) : (
					<h4 className="title-card" style={{ display: "inline-block" }}>
						Valuations:{" "}
						<span>{`${
							valuations && valuations.length
						} of ${esvdTotalCount}`}</span>
					</h4>
				)}

				{currentInfo && (
					<>
						<h5
							className="ui right floated"
							style={{ display: "inline-block" }}>
							Database Version: {`${currentInfo.version}`}
						</h5>
						<br />
						<Button
							floated={"right"}
							onClick={() => switchBetweenMapTable()}
							size="tiny">
							{showMap ? "Show Table" : "Show Map"}
						</Button>
					</>
				)}
			</Card.Content>
			{showMap ? <EsvdReadonlyMaps /> : <EsvdTableReadonlyPanel />}
		</Card>
	);
};

export default EsvdDataReadonlyWrapper;
