import { useState } from "react";
import { FieldArray, Formik } from "formik";
import React, { useEffect } from "react";
import {
	Form,
	Card,
	Button,
	Input,
	List,
	Pagination,
	Icon,
	Item,
} from "semantic-ui-react";

import { useUsers } from "../../providers/UserProvider";
import { User } from "../../models/user";
import { useModalWithContext } from "../../layouts/ModalWithContext";
import EarlyBirdSelectionWrapper from "./EarlyBirdSelection";
import { LIMIT } from "../../shared/Literals";

const EarlyBirdListing = () => {
	const { getEarlyBirdUsers, removeUserToEarlybird } = useUsers();
	const { modalDispatcher } = useModalWithContext();

	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [ebUsers, setEbUsers] = React.useState<User[]>();
	const [ebUsersCount, setEbUsersCount] = React.useState<number>(0);
	// const [currentPage, setCurrentPage] = useState<string | number>(0);
	// const [freeText, setFreeText] = useState("");
	const [refresh, setRefresh] = useState(false);
	// const [limit] = React.useState<number>(15);

	useEffect(() => {
		(async () => {
			const dat = await getEarlyBirdUsers({
				params: { offset: 0, limit: 0 },
			});
			dat && setEbUsersCount(dat.totalRecords);
			dat && setEbUsers(dat.users);
		})();
	}, []);

	// useEffect(() => {
	// 	(async () => {
	// 		const dat = await getEarlyBirdUsers({
	// 			params: { offset: currentPage, limit: 0 },
	// 		});
	// 		dat && setEbUsersCount(dat.totalRecords);
	// 		dat && setEbUsers(dat.users);
	// 	})();
	// }, [currentPage]);

	// useEffect(() => {
	// 	setCurrentPage(0);
	// 	(async () => {
	// 		const dat = await getEarlyBirdUsers({
	// 			params: { offset: 0, limit: limit, freeText },
	// 		});
	// 		dat && setEbUsersCount(dat.totalRecords);
	// 		dat && setEbUsers(dat.users);
	// 	})();
	// 	setRefresh(false);
	// }, [freeText]);

	useEffect(() => {
		if (refresh) {
			// setCurrentPage(0);
			(async () => {
				const dat = await getEarlyBirdUsers({
					params: { offset: 0, limit: 0, freeText: "" },
				});
				dat && setEbUsersCount(dat.totalRecords);
				dat && setEbUsers(dat.users);
			})();
			setRefresh(false);
		}
	}, [refresh]);

	const toggleModal = () => {
		modalDispatcher({
			open: true,
			size: "tiny",
			displayType: null,
			options: {
				title: "Add users to Early Bird",
				body: (
					<EarlyBirdSelectionWrapper
						formDispatcher={modalDispatcher}
						setRefresh={setRefresh}
					/>
				),
				closeOnDimmerClick: false,
			},
		});
	};

	const removeFromEarlyBird = async (user: User) => {
		try {
			setIsLoading(true);
			await removeUserToEarlybird(user.id);
			user.id = null;
			setRefresh(true);
		} catch (error) {
			console.log(error);
		} finally {
			setIsLoading(false);
		}
	};

	// const searchUsers = (e: any) => {
	// 	setFreeText(e.target.value);
	// };

	return (
		<div style={{ height: "80vH" }}>
			<div style={{ height: "95%" }} className="custom-ui-element">
				<Card fluid>
					{/* <Card.Content>
						<Button type="button" onClick={toggleModal} icon="add">
							Add users to early bird  style={{ height: "100%", overflowY: "auto" }} style={{ padding: "1em 2em" }}
						</Button>
					</Card.Content> */}

					<Card.Content style={{ overflow: "hidden", padding: "1em 3em" }}>
						<Button
							type="button"
							onClick={toggleModal}
							icon="add"
							// className="btn btn-feedback btn-primary"
							// style={{
							// 	backgroundColor: "#70bbfd",
							// 	marginBottom: "0.5em",
							// 	// minWidth: "4em",
							// }}
							// style={{ marginBottom: "0.5em" }}
						>
							Add users to early bird
						</Button>

						<hr />
						<div>
							<>
								<Item>
									<Item.Content>
										<Item.Header>
											<h4 className="title-card">List of early birds</h4>
										</Item.Header>
									</Item.Content>
								</Item>
								{/* <Input
									onChange={(e: any) => searchUsers(e)}
									icon="search"
									placeholder="Search..."
									fluid
									loading={isLoading}
								/> */}
								<List divided verticalAlign="middle" size="tiny">
									{ebUsers &&
										ebUsers.map((user, i) => {
											return (
												<List.Item
													key={i}
													style={{
														display: `${user.id ? "block" : "none"}`,
													}}>
													<List.Content verticalAlign="middle">
														<Button
															icon
															size="mini"
															floated="right"
															onClick={async () =>
																user.id && (await removeFromEarlyBird(user))
															}>
															<Icon name="trash" size="small" />
														</Button>
														<List.Header>{user.userName}</List.Header>
														<List.Description>{user.email}</List.Description>
													</List.Content>
												</List.Item>
											);
										})}
								</List>
							</>
						</div>
					</Card.Content>
				</Card>
			</div>

			{/* <Pagination
				activePage={currentPage}
				boundaryRange={1}
				onPageChange={(e, { activePage }) => {
					setCurrentPage(activePage || 1);
				}}
				size="mini"
				siblingRange={1}
				totalPages={Math.ceil(ebUsersCount / limit)}
				prevItem={true}
				nextItem={true}
			/> */}
		</div>
	);
};

export default EarlyBirdListing;
