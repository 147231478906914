import React, { MouseEvent, useEffect, useState } from "react";
import { Card, Grid, Item, Menu, Segment } from "semantic-ui-react";
import VttBiomeList from "./vttbiomes/VttBiomeList";
import { VttBiomeProvider } from "../../providers/VttBiomeProvider";
import { VttSubBiomeProvider } from "../../providers/VttSubBiomeProvider";
import { VttVariableProvider } from "../../providers/VttVariableProvider";
import { VttVariableDetailMapProvider } from "../../providers/VttVariableDetailMapProvider";
import VttBiomeEntry from "./vttbiomes/VttBiomeEntry";
import { ViewHandles, VttProvider, useVtt } from "../../providers/VttProvider";
import VttSubBiomeEntry from "./vttsubbiomes/VttSubBiomeEntry";
import VttSubBiomeList from "./vttsubbiomes/VttSubBiomeList";
import VttVariableEntry from "./vttvariables/VttVariableEntry";
import VttVariableList from "./vttvariables/VttVariableList";
import VttBiomeDetailWrapper from "./vttbiomesDetails/VttBiomeDetails";
import VttBiomeDetails from "./vttbiomesDetails/VttBiomeDetails";
import VttVariableDetailEntry from "./vttbiomesDetails/VttVariableDetailEntry";

// interface IProps {
//   mobile?: boolean;
// }

const VttBase = () => {
	const [activeItem, setActiveItem] = useState("biome");
	const [headerText, setHeaderText] = useState("Biome");
	const { currentView, setCurrentView } = useVtt();
	// const [view, setView] = useState(ViewHandles.biome);

	useEffect(() => {}, [currentView]);

	const handleItemClick = (e: any, data: any) => {
		const { name, content } = data;
		setHeaderText(content);
		setActiveItem(name);
		setCurrentView({ view: name });
	};

	const renderContent = () => {
		switch (currentView.view) {
			case ViewHandles.manage:
				return <VttBiomeDetails />;
			case ViewHandles.manageDetail:
				return <VttVariableDetailEntry />;
			case ViewHandles.subbiome:
				return <VttSubBiomeList />;
			case ViewHandles.subbiomeEntry:
				return <VttSubBiomeEntry />;
			case ViewHandles.variable:
				return <VttVariableList />;
			case ViewHandles.variableEntry:
				return <VttVariableEntry />;
			case ViewHandles.biomeEntry:
				return <VttBiomeEntry />;
			default:
				return <VttBiomeList />;
		}
	};
	return (
		<Grid style={{ height: "90vh" }}>
			<Grid.Column width={4}>
				<Menu fluid vertical tabular>
					{/* <Menu.Item
						name={ViewHandles.manage}
						active={activeItem === ViewHandles.manage}
						onClick={handleItemClick}
						content="Manage"
					/> */}
					<Menu.Item
						name={ViewHandles.biome}
						active={activeItem === ViewHandles.biome}
						onClick={handleItemClick}
						content="Biome"
					/>
					<Menu.Item
						name={ViewHandles.subbiome}
						active={activeItem === ViewHandles.subbiome}
						onClick={handleItemClick}
						content="Sub biome"
					/>
					<Menu.Item
						name={ViewHandles.variable}
						active={activeItem === ViewHandles.variable}
						onClick={handleItemClick}
						content="Variables"
					/>
				</Menu>
			</Grid.Column>

			<Grid.Column stretched width={12}>
				<Card fluid style={{ height: "80vh" }}>
					<Card.Content>
						<h4 className="title-card">Value Transfer Tool - {headerText}</h4>
					</Card.Content>
					{/* <Card.Content> */}
					<VttBiomeProvider>
						<VttSubBiomeProvider>
							<VttVariableProvider>
								<VttVariableDetailMapProvider>
									{renderContent()}
								</VttVariableDetailMapProvider>
							</VttVariableProvider>
						</VttSubBiomeProvider>
					</VttBiomeProvider>
					{/* </Card.Content> */}
				</Card>
			</Grid.Column>
		</Grid>
	);
};

const VttBaseWrapper = () => {
	return (
		<VttProvider>
			<VttBase />
		</VttProvider>
	);
};
export default VttBaseWrapper;
