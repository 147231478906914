import * as React from "react";
import { Card, Item } from "semantic-ui-react";

const EsvdGetStartedPanel = () => {
	return (
		<Card fluid>
			<Card.Content>
				<Item>
					<Item.Content>
						<Item.Header>
							<h4 className="title-card">SELINA View</h4>
						</Item.Header>
					</Item.Content>
				</Item>
			</Card.Content>
			<Card.Content>
				<p>
					Here you find the standardized monetary values of your study as
					collected in the ESVD. The ESVD provides information on the ecosystem
					types, different ES classifications, the monetary value and valuation
					method and some location and bibliographical information. Use the bar
					below to scroll to the right for additional information. You can also
					view the results on a map by clicking on 'Show Map'. Note that if a
					value estimate cannot be standardised to the common set of units used
					in the ESVD (Int$/ha/year), this field will be left empty.
				</p>
				<p>
					If you want to query the full ESVD, please create a free account or
					log-in on the top right of the screen.
				</p>
			</Card.Content>
		</Card>
	);
};

export default EsvdGetStartedPanel;
