import { ErrorMessage, Formik } from "formik";
import React, { Fragment, useState } from "react";
import agent from "../../api/agent";
import { Form, Label, Card, Grid } from "semantic-ui-react";
import * as Yup from "yup";
import { initSuggestStudy, StudySuggestionProps } from "../../shared/Literals";
import { ErrorHandler } from "../../shared/ExceptionHandling";
import { SuggestStudy } from "../../models/SuggestStudy";
import EsvdInput from "../../shared/Input";
import EsvdTextArea from "../../shared/InputTextArea";
import EsvdDropZoneField from "../../shared/DropZone";
import { readAllFiles } from "../../EsvdFunctions";
import { useModalWithContext } from "../../layouts/ModalWithContext";

interface IProps {
	resetSuggestStudy: Function;
	onStudyUpdated: Function;
	suggestedStudy: SuggestStudy | null;
}

const validationSchema: any = Yup.object({
	[`${StudySuggestionProps.title.name}`]: Yup.string()
		.trim()
		.required(`${StudySuggestionProps.title.label} is required`),
	[`${StudySuggestionProps.description.name}`]: Yup.string()
		.trim()
		.required(`Study Description is required`),
	[`${StudySuggestionProps.studyType.name}`]: Yup.string()
		.trim()
		.required(`Study Type is required`),
	[`${StudySuggestionProps.attachment.name}`]: Yup.array()
		.min(1, "Attachment field must have at least 1 item")
		.required("Attachment field must have at least 1 item"),
});

const SuggestEntry = (props: IProps) => {
	const { modalDispatcher } = useModalWithContext();
	const [isSending, setisSending] = useState<boolean>(false);
	const [isSent, setisSent] = useState<boolean>(false);
	const [attachment, setattachment] = useState<any[]>([]);
	const [suggestedStudy, setsuggestedStudy] = useState<SuggestStudy | null>(
		initSuggestStudy
	);

	const resetStudy = () => {
		props.resetSuggestStudy(initSuggestStudy);

		setsuggestedStudy(initSuggestStudy);
		setattachment([]);
		setisSent(false);
	};

	const renderContent = () => {
		return isSent ? null : (
			<Fragment>
				<Card.Content>
					<h4 className="title-card">Suggest a Study</h4>
				</Card.Content>
				<Card.Content>
					<Formik
						enableReinitialize={true}
						validationSchema={validationSchema}
						initialValues={suggestedStudy || {}}
						onSubmit={(values, { setErrors }) => {
							handleFormSubmit(values, setErrors);
						}}>
						{({ values, handleSubmit, errors, setFieldValue }) => (
							<Form
								className="custom-ui-element"
								onSubmit={handleSubmit}
								autoComplete={"off"}
								encType="multipart/form-data">
								<EsvdInput
									name={StudySuggestionProps.title.name}
									label={StudySuggestionProps.title.label}
									fluid
									placeholder={StudySuggestionProps.title.label}
								/>

								<EsvdTextArea
									name={StudySuggestionProps.description.name}
									label={StudySuggestionProps.description.label}
									fluid
									minHeight={150}
									placeholder={StudySuggestionProps.description.label}
								/>
								<EsvdInput
									name={StudySuggestionProps.studyType.name}
									label={StudySuggestionProps.studyType.label}
									fluid
									placeholder={StudySuggestionProps.studyType.label}
								/>
								<label>
									{attachment.length < 2
										? `${StudySuggestionProps.attachment.label}. Formats allowed: [pdf, docx, odt, txt]. Max files: 2. Max total size: 10 MB`
										: "Selected file(s)"}
								</label>
								<EsvdDropZoneField
									name={StudySuggestionProps.attachment.name}
									customHeight={false}
									onSelectionChange={(d: any) => {
										values.attachment = d;
										setattachment(d);
									}}
									value={attachment || []}
									accept=".pdf, .odt, .doc,.docx, .txt "
								/>

								<button
									className="ui button btn btn-primary"
									type="reset"
									style={{
										marginTop: "1rem",
										backgroundColor: "#70bbfd",
										display: "block !important",
										height: "2rem",
										width: "5rem",
									}}
									onClick={() => {
										setFieldValue("title", "");
										setFieldValue("description", "");
										setFieldValue("studyType", "");
										setFieldValue("attachment", []);
										resetStudy();
									}}>
									Clear
								</button>
								<button
									className="ui button btn btn-primary right floated"
									type="submit"
									style={{
										marginTop: "1rem",
										backgroundColor: "#70bbfd",
										display: "block !important",
										height: "2rem",
										width: "5rem",
									}}>
									<span className={`${isSending ? "hide" : ""}`}>Submit</span>
									<span
										className={`box loader-01 ${
											isSending ? "" : "invisible"
										}`}></span>
								</button>
								<ErrorMessage
									name="error"
									render={() => (
										<Label
											style={{ marginBottom: 5, borderColor: "none" }}
											basic
											color="red"
											size="tiny"
											content={errors.error}
										/>
									)}
								/>
							</Form>
						)}
					</Formik>
				</Card.Content>
			</Fragment>
		);
	};

	const handleFormSubmit: Function = async (
		values: SuggestStudy,
		setErrors: Function
	) => {
		values.attachment = await readAllFiles(values.attachment || []);

		try {
			setisSending(true);
			await agent.SuggestStudies.create(values);
			setisSending(false);
			setisSent(true);
			showModal();
			resetStudy();
		} catch (error: any) {
			setErrors({ error: ErrorHandler(error) });
			setisSending(false);
		}
	};

	const showModal = () => {
		modalDispatcher({
			open: true,
			size: "small",
			displayType: "info",
			options: {
				title: "Thank You!",
				body: (
					<Grid.Row centered>
						<Grid.Column width={12}>
							<p className="pos_fixed">
								Thank you for suggesting a study for inclusion in the ESVD.
							</p>
							<br />
							<p className="pos_fixed">
								Your submission will be evaluated by one of the ESVD team
								members.
								<br />
								<br />
								The ESVD team.
							</p>
						</Grid.Column>
					</Grid.Row>
				),
				basicModal: true,
			},
		});
	};

	return <Card fluid>{renderContent()}</Card>;
};

export default SuggestEntry;
