import { ErrorMessage, Formik } from "formik";
import React, { Fragment, useEffect, useState } from "react";
import agent from "../../api/agent";
import { Form, Label, Card } from "semantic-ui-react";
import * as Yup from "yup";
import { StudySuggestionProps } from "../../shared/Literals";
import { ErrorHandler } from "../../shared/ExceptionHandling";
// import { SuggestStudy } from "../../models/SuggestStudy";
import EsvdInput from "../../shared/Input";
import EsvdTextArea from "../../shared/InputTextArea";

export interface EmailApiKey {
	subject?: string;
	body?: string;
	toEmail?: string;
	error?: string;
}

export const initEmail: EmailApiKey = {
	subject: "",
	body: "",
	toEmail: "",
	error: "",
};

const ApiGenerator = (props: any) => {
	//declare a ref variable
	// const isCompleteRef = React.useRef(null);

	// isCompleteRef: any | null = null;

	// const [hasError, setHasError] = useState(false);
	// const [showPassword, setShowPassword] = useState(false);
	const [isSending] = useState(false);
	const [isSent] = useState(false);
	const [apiKeys, setApiKeys] = useState("");
	const [readyToSend, setReadyToSend] = useState(false);

	// useEffect(() => {
	// 	(async () => {
	// 		const api = await agent.Apikey.getApiKey();
	// 		setApiKeys(api);
	// 	})();
	// }, []);

	useEffect(() => {
		if (apiKeys) {
			setReadyToSend(true);
		} else {
			setReadyToSend(false);
		}
	}, [apiKeys, readyToSend]);

	const resetApiKey = () => {
		setApiKeys("");
	};

	const renderContent = () => {
		return isSent ? (
			<Card.Content className="text-center">
				{/* <ThankYou />
          <Label
            className="ui right floated"
            style={{ cursor: "pointer", backgroundColor: "#70bbfd" }}
            content={<Icon name="arrow circle left" />}
            onClick={() => this.setState(() => ({ isSent: false }))}
          /> */}
			</Card.Content>
		) : (
			<Fragment>
				<Card.Content>
					<h4 className="title-card">Email Api Key</h4>
				</Card.Content>
				<Card.Content>
					<Formik
						enableReinitialize={true}
						validationSchema={validationSchema}
						initialValues={initEmail}
						onSubmit={(values, { setErrors }) => {
							handleFormSubmit(values, setErrors);
						}}>
						{({
							values,
							handleChange,
							handleSubmit,
							isValid,
							isSubmitting,
							dirty,
							errors,
							resetForm,
							setFieldValue,
						}) => (
							<Form
								className="custom-ui-element"
								onSubmit={(values, { setErrors }) => {
									handleFormSubmit(values, setErrors);
								}}
								autoComplete={"off"}
								encType="multipart/form-data">
								<EsvdInput
									name={"toEmail"}
									label={"Recipient Email"}
									fluid
									// value="debbs119@yahoo.co.uk"
									placeholder={"Recipient Email"}
								/>
								<EsvdInput
									name={"subject"}
									label={"Subject"}
									fluid
									value={"ESVD API Key for programmatic access."}
									placeholder={"API Key for programmatic access."}
								/>
								<EsvdTextArea
									name={"body"}
									label={"API Key"}
									fluid
									value={apiKeys}
									// minHeight={150}
									readonly={true}
								/>

								<ErrorMessage
									name="error"
									render={() => (
										<Label
											style={{ marginBottom: 5, borderColor: "none" }}
											basic
											color="red"
											size="tiny"
											content={errors.error}
										/>
									)}
								/>
								<button
									className="ui button btn btn-primary"
									type="reset"
									style={{
										marginTop: "1rem",
										backgroundColor: "#70bbfd",
										display: "block !important",
										height: "2rem",
										width: "5rem",
									}}
									onClick={async () => {
										setFieldValue("subject", "");
										setFieldValue("body", "");
										setFieldValue("toEmail", "");
										resetApiKey();

										// if (!apiKeys) {
										// 	try {
										// 		const api = await agent.Apikey.getApiKey();
										// 		setApiKeys(api);
										// 		return;
										// 	} catch (error: any) {
										// 		// setErrors({ error: ErrorHandler(error) });
										// 	}
										// }
									}}>
									Clear
								</button>
								<div className="ui right floated">
									<button
										className="ui button btn btn-primary"
										type="submit"
										style={{
											marginTop: "1rem",
											backgroundColor: "#70bbfd",
											display: "block !important",
											height: "2rem",
											width: "10rem",
										}}>
										{readyToSend ? "Send Api key" : "Generate Api key"}
										<span
											className={`box loader-01 ${
												isSending ? "" : "invisible"
											}`}></span>
									</button>
								</div>
							</Form>
						)}
					</Formik>
				</Card.Content>
			</Fragment>
		);
	};

	const handleFormSubmit = async (values: any, setErrors: Function) => {
		if (!apiKeys) {
			try {
				const api = await agent.Apikey.getApiKey();
				setApiKeys(
					`Request Header Key: "ESVD-API-KEY" \n \n\n ESVD Api key:  ${api}`
				);
				return;
			} catch (error: any) {
				setErrors({ error: ErrorHandler(error) });
			}
		}

		// const { emailSuggestedStudy } = props;

		// setIsSending(true);
		// emailSuggestedStudy(values)
		// 	.then((res: any) => {
		// 		setIsSending(false);
		// 		setIsSent(true);
		// 		resetApiKey();
		// 	})
		// 	.catch((error: any) => {
		// 		setErrors({ error: ErrorHandler(error) });
		// 	});
	};

	return <Card fluid>{renderContent()}</Card>;
};
const validationSchema: any = Yup.object({
	[`${StudySuggestionProps.title.name}`]: Yup.string()
		.trim()
		.required(`${StudySuggestionProps.title.label} is required`),
	[`${StudySuggestionProps.description.name}`]: Yup.string()
		.trim()
		.required(`Study Description is required`),
	[`${StudySuggestionProps.studyType.name}`]: Yup.string()
		.trim()
		.required(`Study Type is required`),
	[`${StudySuggestionProps.attachment.name}`]: Yup.array()
		.min(1, "Attachment field must have at least 1 item")
		.required("Attachment field must have at least 1 item"),
});

export default ApiGenerator;
