import React, { useEffect, useState } from "react";
import { Card, Item } from "semantic-ui-react";

import { useVslGridHub } from "../../providers/VslGridHubProvider";

const VslOnlineAdmins = () => {
	const { vslGridHub } = useVslGridHub();

	const [onlineAdminsOnEditCount, setOnlineAdminsOnEditCount] = useState<
		number | null
	>(null);

	useEffect(() => {
		if (vslGridHub) {
			if (vslGridHub.state === "Disconnected") {
				vslGridHub.start();
			}
			vslGridHub.on("OnlineAdminsOnEditCount", (adminCount: number | null) => {
				setOnlineAdminsOnEditCount(adminCount);
			});
		}
	}, [vslGridHub]);

	useEffect(() => {
		if (vslGridHub) {
			if (vslGridHub.state === "Disconnected") {
				vslGridHub.start();
			}
			vslGridHub
				.invoke<number | null>("OnlineAdminsOnEditCount")
				.then((cnt) => {
					setOnlineAdminsOnEditCount(cnt);
				});
		}
	}, []);

	return (
		<>
			<Card fluid>
				<Card.Content>
					<Item>
						<Item.Content>
							<Item.Header>
								<h4 className="title-card">{`Number of VSL Admins on-line: ${
									onlineAdminsOnEditCount && onlineAdminsOnEditCount > 0
										? onlineAdminsOnEditCount
										: ""
								}`}</h4>
							</Item.Header>
						</Item.Content>
					</Item>
				</Card.Content>
			</Card>
		</>
	);
};

export default VslOnlineAdmins;
