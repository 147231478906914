import React from "react";
import { Button, Card, Form, Input, Label } from "semantic-ui-react";
import { SelectDrop } from "../../shared/SelectDropdown";
import {
	formatBiomeForDropdown,
	formatShowOnlyBiomeForDropdown,
} from "../../EsvdFunctions";

import { ErrorMessage, Formik } from "formik";
import { NoVslFilterGrid } from "../../shared/NoFilterGrid";
import { LoadingButton } from "../../shared/LoadingOverlay";
// import { useModal } from "../../hooks/ModalHook";
import { useVsl } from "../../providers/VslProvider";
import { IncludedInEsvdOptions } from "../../shared/Literals";
import { IVslFilter } from "../../models/vsl-models/IVslFilter";
import { useModalWithContext } from "../../layouts/ModalWithContext";

const VslFilterPanel = (props: IVslFilter) => {
	// const { openModal } = useModal();
	const { modalDispatcher } = useModalWithContext();
	const { getVsls } = useVsl();
	const {
		// vslFilters,
		setVslFilters,
		dropdownOptions,
		isLoading,
		setIsLoading,
	} = props;
	const {
		biomeEcosystems,
		biomeV2EcosystemV2Ecozones,
		ecosystemServices,
		countries,
		protectionStatuses,
	} = dropdownOptions || {};
	// const [vslFilters, setQVslFilters] = useState<EsvdQueryParams>({
	// 	...defaultEsvdParams,
	// });

	let countriesRef: any | null = null;
	let ecosystemServicesRef: any | null = null;
	let protectionStatusRef: any | null = null;
	let biomeRef: any | null = null;
	let biomeV2Ref: any | null = null;
	let includedEsvdRef: any | null = null;

	const [vslQFilter, setVslQFilters] = React.useState<any>(null);

	React.useEffect(() => {
		setVslFilters({ ...vslQFilter });
	}, [vslQFilter]);

	const clearFilter = () => {
		countriesRef.select.clearValue();
		ecosystemServicesRef.select.clearValue();
		protectionStatusRef.select.clearValue();
		biomeV2Ref.select.clearValue();
		biomeRef.select.clearValue();
		includedEsvdRef.select.clearValue();
		setVslQFilters && setVslQFilters(null);
		getVsls && getVsls();
	};

	const handleFormSubmit = async (
		values: { error: null },
		setErrors: Function
	) => {
		if (vslQFilter) {
			setIsLoading(true);
			getVsls && (await getVsls(vslQFilter));
			setIsLoading(false);
		} else {
			modalDispatcher({
				open: true,
				size: "small",
				displayType: "info",
				options: {
					title: "Attention!",
					body: <NoVslFilterGrid />,
					basicModal: true,
				},
			});
		}
	};

	return (
		<Formik
			enableReinitialize
			initialValues={{ error: null }}
			onSubmit={(values, { setErrors }) => {
				handleFormSubmit(values, setErrors);
			}}>
			{({ handleSubmit, isValid, dirty, isSubmitting, errors }) => (
				<Form onSubmit={handleSubmit} autoComplete={"off"}>
					<Card className="top" fluid>
						<Card.Content>
							<h4 className="ui left floated title-card">Filters</h4>
							<Button.Group floated="right" size="small">
								<LoadingButton
									isLoading={isLoading || false}
									text="Get valuations studies"
									type="submit"
									className="ui button"
								/>
								<button
									className="ui button btn btn-warning btn-sm"
									onClick={() => {
										clearFilter();
									}}
									type="reset"
									style={{
										// marginTop: "1rem",
										backgroundColor: "#f6da6e",
										// display: "block !important",
										height: "2rem",
										// width: "5rem",
									}}>
									Clear Filters
								</button>
							</Button.Group>
						</Card.Content>
						<Card.Content>
							<Form.Field>
								<SelectDrop
									getRef={(ref: any) => {
										includedEsvdRef = ref;
									}}
									options={IncludedInEsvdOptions.filter((x) => x.value)} //filter out the blank options
									placeholder="Included in ESVD"
									onChange={(value: any) => {
										if (value.length > 0)
											setVslQFilters &&
												setVslQFilters({
													...vslQFilter,
													includedEsvd: value[0].label,
												});
										else
											setVslQFilters &&
												setVslQFilters({
													...vslQFilter,
													includedEsvd: null,
												});
									}}
								/>
							</Form.Field>
							<Form.Field>
								<SelectDrop
									getRef={(ref: any) => {
										biomeRef = ref;
									}}
									options={formatBiomeForDropdown(biomeEcosystems || [])}
									placeholder="Biome / Ecosystem"
									onChange={(value: any) => {
										if (value.length > 0)
											setVslQFilters &&
												setVslQFilters({
													...vslQFilter,
													biomeEcosystems: value.map((x: any) => x.value),
												});
										else
											setVslQFilters &&
												setVslQFilters({
													...vslQFilter,
													biomeEcosystems: null,
												});
									}}
								/>
							</Form.Field>
							<Form.Field>
								<SelectDrop
									getRef={(ref: any) => {
										biomeV2Ref = ref;
									}}
									options={formatBiomeForDropdown(
										biomeV2EcosystemV2Ecozones || []
									)}
									placeholder="ESVD2.0 Biome / Ecozone"
									onChange={(value: any) => {
										if (value.length > 0)
											setVslQFilters &&
												setVslQFilters({
													...vslQFilter,
													biomeV2EcosystemV2Ecozones: value.map(
														(x: any) => x.value
													),
												});
										else
											setVslQFilters &&
												setVslQFilters({
													...vslQFilter,
													biomeV2EcosystemV2Ecozones: null,
												});
									}}
								/>
							</Form.Field>
							<Form.Field>
								<SelectDrop
									getRef={(ref: any) => {
										countriesRef = ref;
									}}
									options={countries}
									placeholder="Country"
									onChange={(value: any) => {
										if (value.length > 0)
											setVslQFilters &&
												setVslQFilters({
													...vslQFilter,
													countries: value.map((x: any) => x.value),
												});
										else
											setVslQFilters &&
												setVslQFilters({
													...vslQFilter,
													countries: null,
												});
									}}
								/>
							</Form.Field>

							<Form.Field>
								<SelectDrop
									getRef={(ref: any) => {
										protectionStatusRef = ref;
									}}
									options={protectionStatuses}
									placeholder="Protection Status"
									onChange={(value: any) => {
										if (value.length > 0)
											setVslQFilters &&
												setVslQFilters({
													...vslQFilter,
													protectionStatuses: value[0].id,
												});
										else
											setVslQFilters &&
												setVslQFilters({
													...vslQFilter,
													protectionStatuses: null,
												});
									}}
									// onChange={this.setSelectedProtectionStatuses}
									// onChange={(value: any) =>
									// 	// setSelectedItem(value, FILTERS.protectionStatuses)
									// }
								/>
							</Form.Field>
							<Form.Field>
								<SelectDrop
									// disabled={queryFilters.isTeeb === "cices"}
									getRef={(ref: any) => {
										ecosystemServicesRef = ref;
									}}
									options={formatShowOnlyBiomeForDropdown(
										ecosystemServices || []
									)}
									placeholder="TEEB services classification"
									onChange={(value: any) => {
										if (value.length > 0)
											setVslQFilters &&
												setVslQFilters({
													...vslQFilter,
													ecosystemServices: value.map((x: any) => x.value),
												});
										else
											setVslQFilters &&
												setVslQFilters({
													...vslQFilter,
													ecosystemServices: null,
												});
									}}
									// onChange={this.setSelectedEcosystemServices}
									// onChange={(value: any) =>
									// 	// setSelectedItem(value, FILTERS.ecosystemServices)
									// }
								/>
							</Form.Field>
							<Form.Field>
								<Input
									size={"small"}
									// onChange={(val) => setFreeText(val)}
									onChange={(e: any) => {
										// if (value.length > 0)
										setVslQFilters &&
											setVslQFilters({
												...vslQFilter,
												freeText: e.target.value,
											});
										// else
										// 	setVslFilters &&
										// 		setVslFilters({
										// 			...vslQFilter,
										// 			freeText: null,
										// 		});
									}}
									name={"freeText"}
									placeholder={'Free Text Search - Use "" for string matching'}
									data-after='Use "" for string matching'
									// value={queryFilters.params.freeText}
								/>
							</Form.Field>
							<ErrorMessage
								name="error"
								render={() => (
									<Label
										style={{ marginBottom: 10, border: "none" }}
										basic
										color="red"
										content={errors.error}
									/>
								)}
							/>
						</Card.Content>
					</Card>
				</Form>
			)}
		</Formik>
	);
	// }
};

export default VslFilterPanel;
