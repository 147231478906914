import React, { useEffect } from "react";
import { Card, Header, Statistic } from "semantic-ui-react";
import * as XLSX from "xlsx";
import moment from "moment";
import { EsvdColumnMapping, ExcelHeaders } from "../../shared/Literals";
import InvalidItems from "./InvalidItems";
import { useUploadInfo } from "../../providers/UploadInfoProvider";

const ValidateDataset = (props: any) => {
	const { uploading, loading, setLoading, filename, setFileName } =
		useUploadInfo();
	const { file, setData, data, setIsValidData, isValidData } = props;

	const [duplicateValues, setDuplicateValues] = React.useState<any>(null);
	const [noEsvdMasterSheet, setNoEsvdMasterSheet] =
		React.useState<boolean>(false);
	const [invalidCodedDateEntries, setInvalidCodedDateEntries] =
		React.useState<any>(null);
	const [invalidReviewdDateEntries, setInvalidReviewdDateEntries] =
		React.useState<any>(null);

	const [noStudyIdEntries, setNoStudyIdEntries] = React.useState<any>(null);
	const [noValueIdEntries, setNoValueIdEntries] = React.useState<any>(null);

	const [invalidLatLngEntries, setInvalidLatLngEntries] =
		React.useState<any>(null);

	const [dupBiomesCodes, setDupBiomesCodes] = React.useState<any>(null);

	const [dupTEEBsCodes, setDupTEEBsCodes] = React.useState<any>(null);

	const [dupTEEBsubCodes, setDupTEEBsubCodes] = React.useState<any>(null);

	const [dupCICESCodes, setDupCICESCodes] = React.useState<any>(null);
	const [dupEcosystemsCodes, setDupEcosystemsCodes] = React.useState<any>(null);
	const [dupEcozonesCodes, setDupEcozonesCodes] = React.useState<any>(null);
	const [dupSeeasCodes, setDupSeeasCodes] = React.useState<any>(null);
	const [labelColumnErrors, setLabelColumnErrors] = React.useState<
		any[] | null
	>(null);

	useEffect(() => {
		resetDups();
		if (file) {
			setLoading(true);
			setFileName(file.name);
			process(file);
		} else {
			setFileName("");
		}
	}, [file]);

	const process = (file: any) => {
		try {
			setLoading(true);
			/* Boilerplate to set up FileReader */
			const reader = new FileReader();

			const rABS = !!reader.readAsBinaryString;
			reader.onload = (e: any) => {
				try {
					/* Parse data */
					const bstr = e.target.result;

					const wb = XLSX.read(bstr, {
						type: rABS ? "binary" : "array",
						cellText: false,
						cellDates: true,
					});
					// /* Get first worksheet */
					// const wsname = wb.SheetNames["ESVD"];
					// if (wsname !== "maindata") {
					// 	throw new Error("First sheet in the excel must be 'maindata.'");
					// }
					const esvdSheetName =
						wb.SheetNames.find((x) => x.startsWith("ESVD MASTER")) || undefined;
					if (esvdSheetName === undefined) {
						setIsValidData(false);
						setNoEsvdMasterSheet(true);
						setLoading(false);
						return;
					}

					let labelColumnError: any[] | null = null;
					const excelSheetHeaders: string[] = wb.SheetNames;
					const codeSheetHeaders: string[] = Object.keys(ExcelHeaders).map(
						(k) => k
					);

					for (const ch of codeSheetHeaders) {
						const srch: string | undefined =
							ch === "esvds"
								? wb.SheetNames.find((x) => x.startsWith("ESVD MASTER"))
								: excelSheetHeaders.find(
										(x) =>
											ExcelHeaders[`${ch}`].label.toLowerCase().trim() ===
											x.toLowerCase().trim()
								  );

						if (!srch) {
							if (!ch.startsWith("ESVD MASTER")) {
								if (!labelColumnError) {
									//initialize labelColumnError
									labelColumnError = [];
								}

								labelColumnError.push(
									`Sheet with label "${
										ExcelHeaders[`${ch}`].label
									}" is missing from the upload file.`
								);
							}
						} else {
							//check the column headers inside the selected sheet if it exists
							const errorColumns = checkColumnHeaders(
								wb,
								srch,
								ExcelHeaders[`${ch}`].columns
							);
							if (errorColumns) {
								//initialize labelColumnError
								if (!labelColumnError) {
									labelColumnError = [];
								}

								labelColumnError.push(
									`Sheet with label ${srch} is missing the following column headings: ${errorColumns.join(
										", "
									)}. If they are included, they must be the top row.`
								);
							}
						}
					}

					if (labelColumnError) {
						setIsValidData(false);
						setLabelColumnErrors(labelColumnError);
						setLoading(false);
						return;
					}
					const mapped = getESVDJson(wb, esvdSheetName.trim());

					//get stats on data
					const items: any = {};
					const duplicateValueIds: any = {};
					const invalidCodedDate: any = {};
					const invalidReviewedDate: any = {};
					const noValueId: any = {};
					const noStudyId: any = {};
					const duplicateBiomesCodes: any = {};
					const duplicateEcosystemsCodes: any = {};
					const duplicateEcozonesCodes: any = {};
					const duplicateTEEBsCodes: any = {};
					const duplicateTEEBsubCodes: any = {};
					const duplicateCICESCodes: any = {};
					const duplicateSeeasCodes: any = {};
					const invalidLatLng: any = {};
					mapped.forEach((x: any, i: number) => {
						//excel row index
						const rowIndex = i + 1 + 1; //first 1 caters for header and second 1 caters forzero-based index

						//check for unique ValueIds
						if (x.valueid) {
							if (items[`${x.valueid}`]) {
								items[`${x.valueid}`].push(rowIndex);
								//mark value as a duplicate
								duplicateValueIds[`${x.valueid}`] =
									`ValueID: ${x.valueid}, Rows:` + items[`${x.valueid}`];
							} else {
								items[`${x.valueid}`] = [rowIndex];
							}
						}

						//check for duplicate biome codes in one cell
						if (x.biomecode) {
							const biomes: string[] = [];
							const codeSplit = x.biomecode.toString().split(";");
							codeSplit.forEach((arr: string) => {
								if (biomes.includes(arr.trim())) {
									//means it's duplicate
									if (!duplicateBiomesCodes[`${x.valueid}`]) {
										// 	duplicateBiomesCodes[`${x.valueid}`].push(arr.trim());
										// } else {
										duplicateBiomesCodes[`${x.valueid}`] = [arr.trim()];
									}
								} else {
									biomes.push(arr.trim());
								}
							});
							//join after removing duplicates if any
							x.biomecode = biomes.join("; ");
						}

						//check for duplicate Ecozone codes in one cell
						if (x.ecozonecode) {
							const codes: string[] = [];
							const codeSplit = x.ecozonecode.toString().split(";");
							codeSplit.forEach((arr: string) => {
								if (codes.includes(arr.trim())) {
									if (!duplicateEcozonesCodes[`${x.valueid}`]) {
										// 	duplicateEcozonesCodes[`${x.valueid}`].push(arr.trim());
										// } else {
										duplicateEcozonesCodes[`${x.valueid}`] = [arr.trim()];
									}
								} else {
									codes.push(arr.trim());
								}
							});
							//join after removing duplicates if any
							x.ecozonecode = codes.join("; ");
						}

						//check for duplicate Ecosystem codes in one cell
						if (x.ecosystemcode) {
							const codes: string[] = [];
							const codeSplit = x.ecosystemcode.toString().split(";");
							codeSplit.forEach((arr: string) => {
								if (codes.includes(arr.trim())) {
									if (!duplicateEcosystemsCodes[`${x.valueid}`]) {
										// 	duplicateEcosystemsCodes[`${x.valueid}`].push(arr.trim());
										// } else {
										duplicateEcosystemsCodes[`${x.valueid}`] = [arr.trim()];
									}
								} else {
									codes.push(arr.trim());
								}
							});
							//join after removing duplicates if any
							x.ecosystemcode = codes.join("; ");
						}

						//check for duplicate teebs codes in one cell
						checkDuplicateCodes(
							x,
							"EcosystemServiceCode".toLocaleLowerCase(),
							duplicateTEEBsCodes
						);

						//check for duplicate sub teebs codes in one cell
						checkDuplicateCodes(
							x,
							"EcosystemSubServiceCode".toLocaleLowerCase(),
							duplicateTEEBsubCodes
						);

						//check for duplicate Cices codes in one cell
						checkDuplicateCodes(
							x,
							"CicesCode".toLocaleLowerCase(),
							duplicateCICESCodes
						);

						checkDuplicateCodes(
							x,
							"SeeaCode".toLocaleLowerCase(),
							duplicateSeeasCodes
						);

						//check for valid coded date
						if (x.codeddate) {
							let cdate = moment(x.codeddate, "DD-MM-YYYY", true);
							!cdate.isValid() &&
								(invalidCodedDate[
									`${x.valueid}`
								] = `${x.codeddate} at row: ${rowIndex}`);
						}

						//check for valid review date
						if (x.revieweddate) {
							let rdate = moment(x.revieweddate, "DD-MM-YYYY", true);
							!rdate.isValid() &&
								(invalidReviewedDate[
									`${x.valueid}`
								] = `${x.revieweddate} at row: ${rowIndex}`);
						}

						//check if studyid doesn't exist
						if (!x.studyid) {
							noStudyId[`${x.valueid}`] = `row ${rowIndex}`;
						}

						//check if valueid doesn't exist
						if (!x.valueid) {
							noValueId[`${rowIndex}`] = `Row# ${rowIndex}`;
						}

						//check validity of lat/lng
						//The numbers are in decimal degrees format and range from -90 to 90 for latitude and -180 to 180 for longitude.
						if (
							x.latitude > 90 ||
							x.latitude < -90 ||
							x.longitude > 180 ||
							x.longitude < -180
						) {
							invalidLatLng[
								`${x.valueid}`
							] = `latitude: ${x.latitude}, longitude: ${x.longitude}`;
						}
					});

					if (Object.keys(invalidLatLng).length > 0) {
						setInvalidLatLngEntries(invalidLatLng);
						setIsValidData(false);
					} else {
						setInvalidLatLngEntries(null);
					}

					if (Object.keys(duplicateValueIds).length > 0) {
						setDuplicateValues(duplicateValueIds);
						setIsValidData(false);
					} else {
						setDuplicateValues(null);
					}

					if (Object.keys(invalidCodedDate).length > 0) {
						setInvalidCodedDateEntries(invalidCodedDate);
						setIsValidData(false);
					} else {
						setInvalidCodedDateEntries(null);
					}

					if (Object.keys(invalidReviewedDate).length > 0) {
						setInvalidReviewdDateEntries(invalidReviewedDate);
						setIsValidData(false);
					} else {
						setInvalidReviewdDateEntries(null);
					}

					if (Object.keys(noStudyId).length > 0) {
						setNoStudyIdEntries(noStudyId);
						setIsValidData(false);
					} else {
						setNoStudyIdEntries(null);
					}

					if (Object.keys(noValueId).length > 0) {
						setNoValueIdEntries(noValueId);
						setIsValidData(false);
					} else {
						setNoValueIdEntries(null);
					}

					if (Object.keys(duplicateBiomesCodes).length > 0) {
						setDupBiomesCodes(duplicateBiomesCodes);
					} else {
						setDupBiomesCodes(null);
					}

					if (Object.keys(duplicateEcozonesCodes).length > 0) {
						setDupEcozonesCodes(duplicateEcozonesCodes);
					} else {
						setDupEcozonesCodes(null);
					}

					if (Object.keys(duplicateEcosystemsCodes).length > 0) {
						setDupEcosystemsCodes(duplicateEcosystemsCodes);
					} else {
						setDupEcosystemsCodes(null);
					}

					if (Object.keys(duplicateTEEBsCodes).length > 0) {
						setDupTEEBsCodes(duplicateTEEBsCodes);
					} else {
						setDupTEEBsCodes(null);
					}

					if (Object.keys(duplicateTEEBsubCodes).length > 0) {
						setDupTEEBsubCodes(duplicateTEEBsubCodes);
					} else {
						setDupTEEBsubCodes(null);
					}

					if (Object.keys(duplicateCICESCodes).length > 0) {
						setDupCICESCodes(duplicateCICESCodes);
					} else {
						setDupCICESCodes(null);
					}

					if (Object.keys(duplicateSeeasCodes).length > 0) {
						setDupSeeasCodes(duplicateSeeasCodes);
					} else {
						setDupSeeasCodes(null);
					}

					const jsonDataset: any = {};
					jsonDataset.esvds = mapped;

					//StudyBibliographies
					let temp: any = {};
					mapped.forEach((m) => {
						if (!temp[`${m.studyid}`]) {
							temp[`${m.studyid}`] = {
								studyid: m.studyid,
								authors: m.authors,
								year: m.year,
								title: m.title,
								reference: m.reference,
							};
						}
					});
					jsonDataset.studybibliographies = Object.values(temp);

					//ScaleOfStudySites
					temp = {};
					mapped.forEach((m) => {
						if (!temp[`${m.scaleofstudysitecode}`]) {
							temp[`${m.scaleofstudysitecode}`] = {
								code: m.scaleofstudysitecode,
								name: m.scaleofstudysitetext,
							};
						}
					});
					jsonDataset.scaleOfstudysites = Object.values(temp);

					jsonDataset.ecosystemconditions = getExcelTabJson(
						wb,
						ExcelHeaders.ecosystemcondition.label
					);
					jsonDataset.cices = getExcelTabJson(wb, ExcelHeaders.cices.label);

					jsonDataset.biomesecosystems = getExcelTabJson(
						wb,
						ExcelHeaders.biomes.label
					);
					jsonDataset.biomesecozones = getExcelTabJson(
						wb,
						ExcelHeaders.biomesV2.label
					);
					jsonDataset.seeas = getExcelTabJson(wb, ExcelHeaders.seeas.label);
					jsonDataset.teebs = getExcelTabJson(wb, ExcelHeaders.teebs.label);
					jsonDataset.valuationmethods = getExcelTabJson(
						wb,
						ExcelHeaders.valuationmethods.label
					);
					jsonDataset.continents = getExcelTabJson(
						wb,
						ExcelHeaders.continents.label
					);
					jsonDataset.protectionstatus = getExcelTabJson(
						wb,
						ExcelHeaders.protectionstatus.label
					);
					jsonDataset.countries = getExcelTabJson(
						wb,
						ExcelHeaders.countries.label
					);
					jsonDataset.currencies = getExcelTabJson(
						wb,
						ExcelHeaders.currencies.label
					);

					jsonDataset.valueMeasure = getExcelTabJson(
						wb,
						ExcelHeaders.valueMeasure.label
					);

					jsonDataset.valueChange = getExcelTabJson(
						wb,
						ExcelHeaders.valueChange.label
					);
					// ScaleOfStudySites; from Scale_Text column in ESVD

					/* Update state */
					setData({
						uploaddata: jsonDataset,
						esvdCount: mapped.length,
						filename: file.name,
					});
				} catch (err: any) {
					// setLoading(false);
				}
				setLoading(false);
			};
			// const file = this.state.file;
			if (rABS) reader.readAsBinaryString(file);
			else reader.readAsArrayBuffer(file);
		} catch (err: any) {
			setLoading(false);
		}
	};

	function getESVDJson(wb: XLSX.WorkBook, sheetName: string) {
		const ws = wb.Sheets[sheetName];
		/* Convert array of arrays */
		//https://docs.sheetjs.com/docs/api/utilities/
		const dat = XLSX.utils.sheet_to_json(ws, {
			// header: 1,
			blankrows: false,
			raw: true,
			defval: "",
			dateNF: 'd"/"m"/"yyyy',
			// range: 1000,
		});

		// remove rows without valueIds
		const filtered = dat.filter((x: any) => x.ValueID || x.StudyID);

		const mapped = filtered.map((row: any, i) => {
			const item: any = {};
			Object.entries(EsvdColumnMapping).map(([key, value]) => {
				item[`${value.toLocaleLowerCase()}`] = row[key];
			});
			return item;
		});
		return mapped;
	}

	function getExcelTabJson(wb: XLSX.WorkBook, sheetName: string) {
		const ws = wb.Sheets[sheetName];
		/* Convert array of arrays */
		//https://docs.sheetjs.com/docs/api/utilities/
		const dat = XLSX.utils.sheet_to_json(ws, {
			// header: 1,
			blankrows: false,
			raw: true,
			defval: "",
			dateNF: 'd"/"m"/"yyyy',
			// range: 1000,
		});

		const datt: any = dat.map((d: any, i) => {
			const newObj: any = {};
			for (const key in d) {
				if (key.startsWith("__EMPTY")) {
					delete d[key];
					continue;
				}
				newObj[`${key.toLocaleLowerCase()}`] = d[key];
			}
			return newObj;
		});
		return datt;
	}

	function checkColumnHeaders(
		wb: XLSX.WorkBook,
		sheetName: string,
		expectedHeaders: string[]
	): string[] | null {
		const ws = wb.Sheets[sheetName];
		/* Convert array of arrays */
		//https://docs.sheetjs.com/docs/api/utilities/
		//This takes each used column in the sheet and uses the first cell value as object keys
		//So eg if the sheet has Code and Name as the values in the top rowa, they are used as keys for all the remaining rows and
		//all rows apart from the top row are converted to object array ie [{code:"1...", Name:"1..."}, {code:"2...", Name:"2..."}...]
		const dat: any[] = XLSX.utils.sheet_to_json(ws, {
			// header: 1,
			blankrows: false,
			raw: true,
			defval: "",
			dateNF: 'd"/"m"/"yyyy',
		});

		const errorColumns: string[] = [];
		//Due the above explanation, since we want to ascertain if the sheet contains the expected headers we have passed in the first row
		//we pick on the first element of the object array (the first element will represent the first row in the sheet)
		//and check if any keys in it matches our expected headers. If so, then it is valid and we return true. Else it's invalid and we return false

		const firstObjectKeys: string[] = Object.keys(dat[0]);
		for (const txt of expectedHeaders) {
			if (
				!firstObjectKeys.find(
					(x) => x.toLowerCase().trim() === txt.toLowerCase().trim()
				)
			) {
				errorColumns.push(txt);
			}
		}

		return errorColumns.length === 0 ? null : errorColumns;
	}

	const checkDuplicateCodes = (
		rowItem: any,
		keyName: string,
		dupCodesHolder: any
	) => {
		if (rowItem[keyName]) {
			const codes: string[] = [];
			const codeSplit = rowItem[keyName].toString().split(";");
			codeSplit.forEach((arr: string) => {
				if (codes.includes(arr.trim())) {
					if (!dupCodesHolder[`${rowItem.valueid}`]) {
						// 	dupCodesHolder[`${rowItem.valueid}`].push(arr.trim());
						// } else {
						dupCodesHolder[`${rowItem.valueid}`] = [arr.trim()];
					}
				} else {
					codes.push(arr.trim());
				}
			});
			//join after removing duplicates if any
			rowItem[keyName] = codes.join("; ");
		}
	};

	const resetDups = () => {
		setDupCICESCodes(null);
		setDupEcosystemsCodes(null);
		setDupEcozonesCodes(null);
		setDupSeeasCodes(null);
		setDuplicateValues(null);
		setDupBiomesCodes(null);
		setDupTEEBsCodes(null);
		setDupTEEBsubCodes(null);
		setInvalidCodedDateEntries(null);
		setInvalidReviewdDateEntries(null);
		setNoStudyIdEntries(null);
		setNoValueIdEntries(null);
		setInvalidLatLngEntries(null);
		setData({});
		setNoEsvdMasterSheet(false);
		setIsValidData(true);
		setLabelColumnErrors(null);
	};

	const renderDataReport = () => {
		const { uploaddata } = data;
		const { esvds } = uploaddata || {};
		return (
			<div style={{ height: "50vh", marginTop: "20px" }}>
				<Card.Content style={{ marginBottom: "0", paddingBottom: "0.5em" }}>
					{/* <Header
						as="h1"
						color="red"
						textAlign="center"
						style={{ display: "inline-block" }}>
						Max file size: 10Mb
					</Header> */}
					{/* <br /> */}
					{((!loading && file) || !uploading) && (
						<h4
							// as="h3"
							className="title-card"
							// textAlign="center"
							style={{ display: "inline-block" }}>
							File details{"    "}
							{!isValidData && (
								<span style={{ color: "red" }}>
									*Please fix the red higlights below before you can proceed.
								</span>
							)}
						</h4>
					)}

					{!uploading && (
						<Header as="h5">
							{(esvds && `Uploaded file name: ${filename}`) ||
								(filename &&
									esvds === undefined &&
									!loading &&
									"Invalid data format. Please verify.")}
						</Header>
					)}
					{esvds && (
						<Card.Meta>
							<Statistic>
								<Statistic.Value>{esvds.length}</Statistic.Value> valuations
							</Statistic>
						</Card.Meta>
					)}
				</Card.Content>
				{noEsvdMasterSheet && (
					<h4 className="title-card">
						<label style={{ color: "red" }}>
							No sheet name starting with "ESVD MASTER"
						</label>
					</h4>
				)}
				{labelColumnErrors && (
					<>
						<h4 className="title-card" style={{ color: "red" }}>
							{labelColumnErrors &&
								"Incorrect sheet names and/or column labels."}
						</h4>
						{labelColumnErrors && (
							<ul style={{ color: "red" }}>
								{labelColumnErrors.map((key: any, i: number) => (
									<li key={i} style={{ color: "red" }}>
										<label style={{ color: "red !important" }}>{key}</label>
									</li>
								))}
							</ul>
						)}
					</>
				)}
				{duplicateValues && (
					<>
						<h4 className="title-card">
							{duplicateValues &&
								`${
									Object.keys(duplicateValues).length
								} duplicate valuation studies found.`}
						</h4>
						{duplicateValues && (
							<InvalidItems
								invalidEntries={duplicateValues}
								isFatal={true}
								withJoin={true}
								delimiter=", "
							/>
						)}
					</>
				)}
				{invalidCodedDateEntries && (
					<>
						<h4 className="title-card">
							{invalidCodedDateEntries &&
								`${
									Object.keys(invalidCodedDateEntries).length
								} entries with invalid
						coded date found.`}
						</h4>
						{invalidCodedDateEntries && (
							<InvalidItems
								invalidEntries={invalidCodedDateEntries}
								isFatal={true}
							/>
						)}
					</>
				)}
				{invalidReviewdDateEntries && (
					<>
						<h4 className="title-card">
							{invalidReviewdDateEntries &&
								`${Object.keys(invalidReviewdDateEntries).length} entries with
						invalid reviewed date found.`}
						</h4>
						{invalidReviewdDateEntries && (
							<InvalidItems
								invalidEntries={invalidReviewdDateEntries}
								isFatal={true}
							/>
						)}
					</>
				)}
				{noStudyIdEntries && (
					<>
						<h4 className="title-card">
							{noStudyIdEntries &&
								`${Object.keys(noStudyIdEntries).length} entries with
						no study Ids.`}
						</h4>
						{noStudyIdEntries && (
							<InvalidItems invalidEntries={noStudyIdEntries} isFatal={true} />
						)}
					</>
				)}
				{noValueIdEntries && (
					<>
						<h4 className="title-card">
							{noValueIdEntries &&
								`${Object.keys(noValueIdEntries).length} entries with
						no value Ids.`}
						</h4>
						{noValueIdEntries && (
							<InvalidItems
								invalidEntries={noValueIdEntries}
								isFatal={true}
								withJoin={true}
								delimiter="; "
							/>
						)}
					</>
				)}

				{invalidLatLngEntries && (
					<>
						<h4 className="title-card">
							{invalidLatLngEntries &&
								`${Object.keys(invalidLatLngEntries).length} entries with
						invalid latitutes and longitudes. Numbers range from -90 to 90 for latitude and -180 to 180 for longitude.`}
						</h4>
						{invalidLatLngEntries && (
							<InvalidItems
								invalidEntries={invalidLatLngEntries}
								isFatal={true}
							/>
						)}
					</>
				)}

				{dupBiomesCodes && (
					<>
						<h4 className="title-card">
							{dupBiomesCodes &&
								`${Object.keys(dupBiomesCodes).length} entries with
						duplicate biome codes.`}
						</h4>
						{dupBiomesCodes && (
							<InvalidItems
								invalidEntries={dupBiomesCodes}
								withJoin={true}
								delimiter="; "
							/>
						)}
					</>
				)}

				{dupEcozonesCodes && (
					<>
						<h4 className="title-card">
							{dupEcozonesCodes &&
								`${Object.keys(dupEcozonesCodes).length} entries with
						duplicate Ecozones codes.`}
						</h4>
						{dupEcozonesCodes && (
							<InvalidItems
								invalidEntries={dupEcozonesCodes}
								withJoin={true}
								delimiter="; "
							/>
						)}
					</>
				)}

				{dupEcosystemsCodes && (
					<>
						<h4 className="title-card">
							{dupEcosystemsCodes &&
								`${Object.keys(dupEcosystemsCodes).length} entries with
						duplicate Ecosystems codes.`}
						</h4>
						{dupEcosystemsCodes && (
							<InvalidItems
								invalidEntries={dupEcosystemsCodes}
								withJoin={true}
								delimiter="; "
							/>
						)}
					</>
				)}

				{dupTEEBsCodes && (
					<>
						<h4 className="title-card">
							{dupTEEBsCodes &&
								`${Object.keys(dupTEEBsCodes).length} entries with
						duplicate TEEB codes.`}
						</h4>
						{dupTEEBsCodes && (
							<InvalidItems
								invalidEntries={dupTEEBsCodes}
								withJoin={true}
								delimiter="; "
							/>
						)}
					</>
				)}
				{dupTEEBsubCodes && (
					<>
						<h4 className="title-card">
							{dupTEEBsubCodes &&
								`${Object.keys(dupTEEBsubCodes).length} entries with
						duplicate Sub TEEB codes.`}
						</h4>
						{dupTEEBsubCodes && (
							<InvalidItems
								invalidEntries={dupTEEBsubCodes}
								withJoin={true}
								delimiter="; "
							/>
						)}
					</>
				)}

				{dupCICESCodes && (
					<>
						<h4 className="title-card">
							{dupCICESCodes &&
								`${Object.keys(dupCICESCodes).length} entries with
						duplicate CICES codes.`}
						</h4>
						{dupCICESCodes && (
							<InvalidItems
								invalidEntries={dupCICESCodes}
								withJoin={true}
								delimiter="; "
							/>
						)}
					</>
				)}

				{dupSeeasCodes && (
					<>
						<h4 className="title-card">
							{dupSeeasCodes &&
								`${Object.keys(dupSeeasCodes).length} entries with
						duplicate SEEAs codes.`}
						</h4>
						{dupSeeasCodes && (
							<InvalidItems
								invalidEntries={dupSeeasCodes}
								withJoin={true}
								delimiter="; "
							/>
						)}
					</>
				)}
			</div>
		);
	};
	return <>{renderDataReport()}</>;
};

export default ValidateDataset;
