import React from "react";
import { connect } from "react-redux";
import { Card, Icon, List } from "semantic-ui-react";
import { deleteStudy } from "../../actions/studyActions";
import { Study } from "../../models/Study";
import { useValuations } from "../../providers/ValuationsProvider";
import { initStudy } from "../../shared/Literals";
import LoadingOverlay from "../../shared/LoadingOverlay";

interface IProps {
	// deleteStudy: Function;
	clearStudy: Function;
	// studies: Study[];
	onSelectionChanged: Function;
	headerText: string;
	// isEditor: boolean;
	// isLoadingStudies: boolean;
}

const StudyList = (props: IProps) => {
	const { studies } = useValuations();
	const { headerText } = props;
	const selectedStudy = (study: Study) => {
		props.onSelectionChanged(study);
	};

	// const removeStudy = (study: Study) => {
	// 	props
	// 		.deleteStudy(study.id)
	// 		.then((res: any) => {
	// 			props.clearStudy(initStudy);
	// 		})
	// 		.catch((error: any) => {});
	// };

	const renderContent = () => {
		// const { isLoadingStudies } = props;

		return (
			<>
				{/* <LoadingOverlay active={isLoadingStudies} text="Loading studies" /> */}
				<List celled selection animated>
					{studies.map((s, i) => (
						<List.Item key={i}>
							<List.Content floated="right"></List.Content>
							<List.Content onClick={() => selectedStudy(s)}>
								<List.Header>{s.title}</List.Header>
								{`StudyID = ${s.studyId}`}
								{s.isPublished ? (
									<Icon className="ui right floated" name="eye" />
								) : null}
							</List.Content>
						</List.Item>
					))}
				</List>
			</>
		);
	};

	//
	return (
		<Card fluid style={{ height: "55vh" }}>
			<Card.Content>
				<h4 className="title-card">{`${headerText} (${studies.length} studies)`}</h4>
			</Card.Content>
			<Card.Content style={{ overflowY: "auto" }}>
				{renderContent()}
			</Card.Content>
		</Card>
	);
};

// const mapStateToProps = (state: any) => {
// 	return {
// 		studies: Object.values<Study>(state.studies).sort((a, b) => {
// 			return !!a.studyId > !!b.studyId ? 1 : -1; //sort by study in descending order
// 		}),
// 		isEditor: state.isEditor,
// 		isLoadingStudies: state.isLoadingStudies,
// 	};
// };

export default StudyList;
// 	connect(mapStateToProps, {
// 	deleteStudy,
// })(StudyList);
