import React, { useContext, useState } from "react";

import agent from "../api/agent";
import { User, UserQueryResult } from "../models/user";
import { UserQueryParams } from "../models/EsvdQueryFilters";

interface IValue {
	// currentPage: number;
	getEarlyBirdUsers: Function;
	getNonEarlyBirdUsers: Function;
	// setCurrentPage: Function;
	addUserToEarlybird: Function;
	removeUserToEarlybird: Function;
}

const UserContext = React.createContext<IValue | null>(null);

// The AuthProvider is responsible for Auth management
const UserProvider = ({ children }: { children: any }) => {
	// const [user, setUser] = useState<User | null>();
	// const [currentPage, setCurrentPage] = useState<number>(0);

	const getEarlyBirdUsers = async (
		userQueryParams: UserQueryParams
	): Promise<UserQueryResult> => {
		try {
			const items = await agent.Account.earlybirdusers(userQueryParams);
			items.users = items.users.sort((a, b) => {
				return !!a.userName > !!b.userName ? 1 : -1;
			});
			return items;
		} catch (error: any) {
			throw error.response ? error.response.statusText : error.message;
		}
	};

	const getNonEarlyBirdUsers = async (
		userQueryParams: UserQueryParams
	): Promise<UserQueryResult> => {
		try {
			const items = await agent.Account.nonearlybirdusers(userQueryParams);
			items.users = items.users.sort((a, b) => {
				return !!a.userName > !!b.userName ? 1 : -1;
			});
			return items;
		} catch (error: any) {
			throw error.response ? error.response.statusText : error.message;
		}
	};

	const addUserToEarlybird = async (id: string) => {
		try {
			await agent.Account.addearlybirduser(id);
			return;
		} catch (error: any) {
			// setUser(null);
			throw error.response ? error.response.statusText : error.message;
		}
	};

	const removeUserToEarlybird = async (id: string) => {
		try {
			await agent.Account.removeearlybirduser(id);
			return;
		} catch (error: any) {
			// setUser(null);
			throw error.response ? error.response.statusText : error.message;
		}
	};

	return (
		<UserContext.Provider
			value={{
				getEarlyBirdUsers,
				getNonEarlyBirdUsers,
				// currentPage,
				// setCurrentPage,
				addUserToEarlybird,
				removeUserToEarlybird,
			}}>
			{children}
		</UserContext.Provider>
	);
};

// The useStackData hook can be used by components under an StackDataProvider to
// access the auth context value.
const useUsers = () => {
	const auth = useContext(UserContext);
	if (auth == null) {
		throw new Error("useUsers() called outside of a auth hook?");
	}
	return auth;
};

export { UserProvider, useUsers };
