import { useState, useEffect } from "react";
import Table, { AutoResizer } from "react-base-table";
import "react-base-table/styles.css";
import { defaultSort, IS_DESCEND_DEFAULT } from "../../shared/Literals";
import { useEsvdValuation } from "../../providers/EsvdValuationProvider";
import { Esvd, EsvdArray } from "../../models/esvd";
import {
	bodyRowsFlattened,
	displayBodyRowsObjects,
	displayHeaderTransform,
	setDisplayMultiColumnMaxLens,
	setMultiColumnMaxLenFlattened,
} from "../../EsvdFunctions";
import {
	ColumnStructure,
	DisplayColumnStructure,
} from "../../models/columnStructure";
import {
	ColumnTemplate,
	DisplayColumnTemplate,
} from "../../models/columnTemplate";

const EsvdReadonlyTable = () => {
	const { studyEsvds } = useEsvdValuation();

	const [valuations, setValuations] = useState<Esvd[]>([]);
	const [sort, setSort] = useState<any>(defaultSort);

	const [esvds, setEsvds] = useState<EsvdArray>({
		esvdsAllCols: [],
		headerArray: [],
		bodyArray: [],
	});

	useEffect(() => {
		if (studyEsvds) {
			setValuations(studyEsvds);
		} else {
			setValuations([]);
		}
	}, [studyEsvds]);

	useEffect(() => {
		processEsvds();
	}, [valuations]);

	const processEsvds = () => {
		try {
			const cols: DisplayColumnStructure = setDisplayMultiColumnMaxLens(
				valuations,
				DisplayColumnTemplate
			);
			const heads = displayHeaderTransform(valuations, cols);
			const body = displayBodyRowsObjects(valuations, cols);

			const allCols: ColumnStructure = setMultiColumnMaxLenFlattened(
				valuations,
				ColumnTemplate
			);
			const items = bodyRowsFlattened(valuations, allCols);

			setEsvds({ esvdsAllCols: items, headerArray: heads, bodyArray: body });

			// return response;
		} catch (error: any) {
			throw error.response ? error.response.statusText : error.message;
		}
	};

	return (
		<>
			<div className="BaseContainer">
				<AutoResizer>
					{({ width, height }) => (
						<Table
							fixed
							width={width}
							height={height - 80}
							columns={esvds.headerArray}
							data={esvds.bodyArray}
							sortBy={sort}
							rowHeight={30}
							// onColumnSort={onColumnSort}
						/>
					)}
				</AutoResizer>
			</div>
		</>
	);
	// }
};

export default EsvdReadonlyTable;
