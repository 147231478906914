import React, { Fragment } from "react";
import { Card, Grid, Icon, Item, Popup, Table } from "semantic-ui-react";
import { Statistics } from "../../models/esvd";
import { useEsvdValuation } from "../../providers/EsvdValuationProvider";
// import { ItemLabels } from "../../shared/Literals";
import { calculateStats, processStatsData } from "./processStatsData";
import StatsPopup from "./StatsPopup";
import { SummaryStatsColumnStructure } from "../../models/columnStructure";
import {
	setMultiColumnMaxLensSummaryStats,
	summaryStatsRowsObjects,
} from "../../EsvdFunctions";
import { SummaryStatsColumnTemplate } from "../../models/columnTemplate";
import MyPopup from "../../shared/MyPopup";

export const measureStats = {
	mean: "mean",
	count: "count",
	max: "max",
	min: "min",
	median: "median",
};

const headerCellWith = "20%";

const StatisticsEcozonePanel = () => {
	const { esvdsSummaryStats } = useEsvdValuation();

	const [statsBody, setStatsBody] = React.useState<any[]>([]);
	const [statsHeader, setStatsHeader] = React.useState<any[]>([]);
	const [totalValidValuationsUsed, setTotalValidValuationsUsed] =
		React.useState<number>(0);

	React.useEffect(() => {
		const { ecozones } = esvdsSummaryStats || {};
		if (!ecozones) return;
		const cols: SummaryStatsColumnStructure = setMultiColumnMaxLensSummaryStats(
			ecozones,
			SummaryStatsColumnTemplate
		);
		// const heads = headerTransform(action.payload, cols);
		const ecozoneStats = summaryStatsRowsObjects(ecozones, cols);
		const result =
			ecozoneStats.length > 0
				? processStatsData(ecozoneStats)
				: { header: [], body: [] };
		let validValuationsUsed = 0;
		const data = result.body.map((a: any, index: number) => (
			<Table.Row key={index}>
				{buildTableCells(a, (cnt: number) => {
					validValuationsUsed += cnt;
				})}
			</Table.Row>
		));
		setStatsHeader(result.header);
		setStatsBody(data);
		setTotalValidValuationsUsed(validValuationsUsed);
	}, [esvdsSummaryStats]);

	const buildTableCells = (row: any, countValid: Function) => {
		return Object.entries(row).map(([key, value]) => {
			let arr = [];
			arr.push(
				<Table.Cell style={{ width: `${headerCellWith}` }} key={key}>
					{key}
				</Table.Cell>
			);
			const valuations: any = value;
			const valValues = Object.values(valuations);
			const stats: Statistics[] = valValues.map((vals: any, i) =>
				calculateStats(vals, measureStats.mean)
			);
			const countNum = stats.reduce((a, b) => a + (b?.countNum || 0), 0);
			countValid && countValid(countNum);
			stats.map((stat, i) =>
				arr.push(
					<Table.Cell key={i} style={{ cursor: "pointer" }}>
						<Popup
							wide
							size="small"
							trigger={<span>{stat.display}</span>}
							content={<StatsPopup {...stat} />}
							// basic
						/>
					</Table.Cell>
				)
			);
			return arr;
		});
	};

	return (
		<Fragment>
			<Card fluid style={{ overflow: "scroll", minHeight: "30vh" }}>
				<Card.Content>
					<Item>
						<Item.Content>
							<Item.Header>
								<h4 className="ui left floated title-card">
									Summary statistics ($2020/ha/yr)
									<MyPopup content="The summary statistics show the <strong>mean standardised values</strong> for each selected biome and/or ecozone and ecosystem service combination. To enable comparison, values in the ESVD are standardised to a common set of units: <strong>International dollars/hectare/year in 2020 price levels</strong>. The number of value records used to compute mean values is indicated in parentheses." />
									- ecozones:{" "}
									<span>
										{`${totalValidValuationsUsed || 0}   valuation(s)`}
										<MyPopup content="Note that this summary of values is for illustrative purposes, only to provide an impression of the order of magnitude of the values obtained from the literature and to identify data gaps. It is not advised to use these summary statistics for value transfers since the summary statistics reflect the underlying ecological and socio-economic contexts of diverse (but not necessarily representative) study sites." />
									</span>
								</h4>
							</Item.Header>
						</Item.Content>
					</Item>
				</Card.Content>
				<Card.Content>
					<Grid>
						<Grid.Row>
							<Grid.Column>
								<div
									// className=""
									style={{
										overflow: "inherit",
										minHeight: "30vh",
										maxHeight: "60vh",
									}}>
									<Table definition celled striped singleLine>
										<Table.Header>
											<Table.Row>
												<Table.HeaderCell
													style={{
														width: `${headerCellWith}`,
													}}></Table.HeaderCell>
												{statsHeader.map((a: any, index: number) => (
													<Table.HeaderCell key={index}>{a}</Table.HeaderCell>
												))}
											</Table.Row>
										</Table.Header>
										<Table.Body>
											{statsBody}
											{/* {statsData.body.map((a: any, index: number) => (
												<Table.Row key={index}>{renderContent(a)}</Table.Row>
											))} */}
										</Table.Body>
									</Table>
								</div>
							</Grid.Column>
						</Grid.Row>
					</Grid>
				</Card.Content>
			</Card>
		</Fragment>
	);
};

export default StatisticsEcozonePanel;

/**
 * stefanos writes the following:
 * I apply the function in Excel =TRIMMEAN(7:100,2.5). Now, 2.5% of 3 (number of values) is 0.07.
 * Obviously, Excel cannot exclude 0.07 records, so no values will be excluded.
 * Therefore, the number of values that will be considered for the calculation of statistics will be 3 (N=3).
 * Then I calculate the mean using Excel's =AVERAGE(7:100) which equals to 42,33.
 * For your second email, the explanation is like above. No values can be excluded from a dataset with only 1 value, so N will equal 1.
 * In order for values to actually be excluded with this filter, a dataset of at least 40 values is needed (2.5% of 40 = 1).
 * 2.5% of the number of values must be greater than 1 for this filter to have effect (so that Excel can exclude at least 1 value point).
 */
