// import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Card, Checkbox, Icon, Table } from "semantic-ui-react";
import { useVttBiome } from "../../../providers/VttBiomeProvider";
import LoadingOverlay from "../../../shared/LoadingOverlay";
import { VttBiome } from "../../../models/vtt-models/VttBiome";
import MyModal from "../../../layouts/Modals";

import { ViewHandles, useVtt } from "../../../providers/VttProvider";

const VttBiomeList = () => {
	const { setCurrentView } = useVtt();
	const { getVttBiomeCount, getVttBiomes, deleteVttBiome } = useVttBiome();
	const [isLoading, setIsLoading] = React.useState(false);
	// const [currentPage, setCurrentPage] = React.useState(1);
	const [vttBiomesCnt, setVttBiomesCnt] = useState<number>(0);
	const [vttBiomes, setVttBiomes] = useState<VttBiome[]>([]);
	const [opened, setOpened] = useState<boolean>(false);
	const [delItem, setDelItem] = useState<number | null>(null);
	const [isModalConfirmed, setIsModalConfirmed] = useState<boolean>(false);
	const [modalMessage, setModalMessage] = useState<string | null>(null);
	const [isActionNeeded, setisActionNeeded] = useState<boolean>(true);

	useEffect(() => {
		setIsLoading(true);
		(async () => {
			var filtersCnt = await getVttBiomeCount();
			setVttBiomesCnt(filtersCnt);
		})();

		(async () => {
			var vtt = await getVttBiomes();
			setVttBiomes(vtt || []);
			setIsLoading(false);
		})();

		return () => {};
	}, []);

	useEffect(() => {
		if (isModalConfirmed) {
			setIsModalConfirmed(false);
			(async () => {
				if (delItem) {
					await deleteVttBiome(delItem);
					var vtt = await getVttBiomes();
					setVttBiomes(vtt);
					setIsLoading(false);
					setDelItem(null);
				}
			})().catch((err) => {
				setOpened(true);
				setisActionNeeded(false);
				setModalMessage(err || null);
			});
		}
		return () => {};
	}, [isModalConfirmed]);

	// const viewVttBiomeDetails = (id: number) => {
	// 	selectedItem(id);
	// };

	const deleting = (id: number) => {
		setisActionNeeded(true);
		setModalMessage("Are you sure you want to delete?");
		setOpened(true);
		setDelItem(id);
		// console.log(opened);
	};

	const renderContent = () => {
		return (
			<>
				<LoadingOverlay active={isLoading} text="Loading filter queries" />
				<Table celled selectable>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell>Biome</Table.HeaderCell>
							<Table.HeaderCell>Constant</Table.HeaderCell>
							<Table.HeaderCell>Lower bound factor</Table.HeaderCell>
							<Table.HeaderCell>Upper bound factor</Table.HeaderCell>
							<Table.HeaderCell>Show in the VTT to visitors</Table.HeaderCell>
							<Table.HeaderCell></Table.HeaderCell>
						</Table.Row>
					</Table.Header>

					<Table.Body>
						{vttBiomes &&
							vttBiomes.map((biome, i) => {
								return (
									<Table.Row key={i}>
										<Table.Cell
											onClick={() => {
												setCurrentView({
													view: ViewHandles.manage,
													biomeid: biome.id,
												});
											}}
											style={{ cursor: "pointer" }}>
											{biome.name}
										</Table.Cell>
										<Table.Cell width={2}>{biome.constantValue}</Table.Cell>
										<Table.Cell width={2}>{biome.lowerBoundFactor}</Table.Cell>
										<Table.Cell width={2}>{biome.upperBoundFactor}</Table.Cell>
										<Table.Cell width={3}>
											{biome.showForUserCalculations ? "Yes" : "No"}
										</Table.Cell>
										<Table.Cell width={4} textAlign="right">
											<Button.Group>
												<button
													className="btn btn-primary btn-sm"
													style={{ minWidth: "4em" }}
													onClick={() => {
														setCurrentView({
															view: ViewHandles.manage,
															biomeid: biome.id,
														});
													}}>
													Manage variables
												</button>
												<button
													className="btn btn-success btn-sm"
													style={{ minWidth: "4em" }}
													onClick={() => {
														setCurrentView({
															view: ViewHandles.biomeEntry,
															biomeid: biome.id,
														});
													}}>
													<Icon name="edit" />
												</button>
												<button
													className="btn btn-warning btn-sm"
													style={{ minWidth: "4em" }}
													onClick={() => biome.id && deleting(biome.id)}>
													<Icon name="trash" />
												</button>
											</Button.Group>
										</Table.Cell>
									</Table.Row>
								);
							})}
					</Table.Body>
				</Table>
			</>
		);
	};

	return (
		<>
			<Card.Content>
				<button
					className="ui button btn btn-primary "
					type="submit"
					style={{
						backgroundColor: "#70bbfd",
						height: "2rem",
					}}
					onClick={() => {
						setCurrentView({ view: ViewHandles.biomeEntry });
					}}>
					Add Biome
				</button>
			</Card.Content>
			<Card.Content style={{ overflowY: "auto", height: "80vh" }}>
				{renderContent()}
			</Card.Content>

			<MyModal
				title="Delete"
				body="Are you sure you want to delete?"
				opened={opened}
				setOpened={setOpened}
				setConfirmation={setIsModalConfirmed}
				needsAction={isActionNeeded}
			/>
		</>
	);
};

export default VttBiomeList;
