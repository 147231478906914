import React from "react";
import { Card, Item } from "semantic-ui-react";

const EsvdStatisticsPanel = () => {
	return (
		<Card fluid>
			<Card.Content>
				<Item>
					<Item.Content>
						<Item.Header>
							<h4 className="title-card">About summary statistics</h4>
						</Item.Header>
					</Item.Content>
				</Item>
			</Card.Content>
			<Card.Content>
				<ul className="dash-list">
					<li>
						In most cases, only a portion of the search results will be included
						in the calculation of summary statistics. These are value records
						that: i) <strong> Could be standardised</strong> to a common set of
						units (International dollars/hectare/year in 2020 price levels); ii){" "}
						<strong>
							{" "}
							Refer to only 1 biome and/or ecozone and 1 ecosystem service
						</strong>{" "}
						(i.e. values with multiple services or ecosystem types are not
						included) and iii) <strong>Do not use value transfer</strong> as a
						valuation method.
					</li>
					<li>
						Additional summary statistics (number of value records used to
						compute the mean, median, standard deviation, minimum, maximum) can
						be viewed by hovering the mouse pointer over the results
					</li>
					<li>
						A common outlier exclusion rule (OER) based on a standard boxplot
						graph is used. The OER defines observations as outliers that are
						either located before the first quantile minus 1.5 times the
						interquartile range or beyond the third quantile plus 1.5 times the
						interquartile range on a logarithmic scale.
					</li>
					<li>
						For more information on the ESVD, see{" "}
						<a
							className="esvd-hyperlink"
							href="https://www.esvd.info/ourdatabase"
							target="_blank"
							rel="noopener noreferrer">
							here
						</a>
					</li>
				</ul>
				{/* <p style={{ paddingTop: "1rem" }}>
					Note that <b>this summary of values is for illustrative purposes</b>,
					only to provide an impression of the order of magnitude of the values
					obtained from the literature and to identify data gaps. It is not
					advised to use these summary statistics for value transfers since the
					summary statistics reflect the underlying ecological and
					socio-economic contexts of diverse (but not necessarily
					representative) study sites.
				</p> */}
			</Card.Content>
		</Card>
	);
};

export default EsvdStatisticsPanel;
