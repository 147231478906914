import React, { useContext, useEffect } from "react";
import { Button, Header, Modal } from "semantic-ui-react";

interface IOptions {
	title?: string;
	body?: string;
	setOpened?: Function;
	setConfirmation?: Function;
	needsAction?: boolean;
	closeOnDimmerClick?: boolean;
	basicModal?: boolean;
	customActions4BasicModal?: any;
}
interface IState {
	open: boolean;
	size?: "small" | "mini" | "tiny" | "large" | "fullscreen" | undefined;
	displayType?: "confirmation" | "info" | "error" | "custom" | null | undefined;
	options?: IOptions;
}

export function ContextModalReducer(state: IState, action: IState) {
	const { open, size, options, displayType } = action;
	switch (open) {
		case false:
			return {
				open: false,
				size,
				displayType,
				options,
			};
		case true:
			return {
				open: true,
				size: action.size,
				displayType,
				options,
			};
		default:
			throw new Error("Unsupported action...");
	}
}

interface IValue {
	modalState: IState;
	modalDispatcher: React.Dispatch<any>;
}

const ModalContext = React.createContext<IValue | null>(null);

const ModalWithContextProvider = ({ children }: { children: any }) => {
	const [state, dispatch] = React.useReducer(ContextModalReducer, {
		open: false,
		size: "small",
		displayType: null,
		options: {},
	});

	const { open, size, displayType, options } = state;

	const {
		title,
		body,
		setConfirmation,
		closeOnDimmerClick,
		basicModal,
		customActions4BasicModal,
	} = options || {};

	useEffect(() => {
		if (!open) {
			dispatch({ open: false });
		}
	}, [open]);

	const actionContent = (displayType: string) => {
		switch (displayType) {
			case "confirmation":
				return (
					<>
						<Button
							negative
							onClick={() => {
								dispatch({ open: false });
								setConfirmation && setConfirmation(false);
							}}>
							No
						</Button>
						<Button
							positive
							onClick={() => {
								dispatch({ open: false });
								setConfirmation && setConfirmation(true);
							}}>
							Yes
						</Button>
					</>
				);
			case "info":
				return (
					<button
						className="btn btn-warning btn-sm"
						onClick={() => {
							dispatch({ open: false });
						}}>
						Close
					</button>
					// <Button
					// 	negative
					// 	onClick={() => {
					// 		dispatch({ open: false });
					// 	}}>
					// 	Close
					// </Button>
				);
			default:
				return null;
		}
	};

	return (
		<>
			{basicModal ? (
				<Modal
					className="scroll-from-top modal-content "
					size="small"
					centered={false}
					open={open}
					onClose={() => dispatch({ open: false })}
					// onOpen={openModal}
				>
					<Modal.Content style={{ height: "16em", whiteSpace: "pre-line" }}>
						<Header className="pos_fixed">{title}</Header>
						{body}

						<div className="modal__footer">
							{/* {(content.actions && content.actions()) || ( */}
							{(customActions4BasicModal && customActions4BasicModal()) || (
								<button
									className={`btn ${
										displayType === "error" ? "btn-danger" : "btn-warning"
									} btn-sm`}
									onClick={() => {
										dispatch({ open: false });
									}}>
									Close
								</button>
							)}
						</div>
					</Modal.Content>
				</Modal>
			) : (
				<Modal
					centered={false}
					size={size}
					open={open}
					onClose={() => dispatch({ open: false })}
					closeOnDimmerClick={
						closeOnDimmerClick === null || closeOnDimmerClick === undefined
							? true
							: closeOnDimmerClick
					}>
					<Modal.Header>{title}</Modal.Header>
					<Modal.Content style={{ whiteSpace: "pre-line" }}>
						{/* <p>{body}</p> */}
						{body}
					</Modal.Content>
					{displayType ? (
						<Modal.Actions>
							<div className="modal__footer">{actionContent(displayType)}</div>
						</Modal.Actions>
					) : null}
				</Modal>
			)}

			<ModalContext.Provider
				value={{
					modalState: state,
					modalDispatcher: dispatch,
				}}>
				{children}
			</ModalContext.Provider>
		</>
	);
};

const useModalWithContext = () => {
	const activeMenu = useContext(ModalContext);
	if (activeMenu == null) {
		throw new Error("useModalWithContext() called outside of a Modal hook?");
	}
	return activeMenu;
};

export { ModalWithContextProvider, useModalWithContext };
