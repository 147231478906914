// import { useField } from "formik";
import React from "react";
import { Icon, Popup } from "semantic-ui-react";

interface Props {
	content: string;
}

const MyPopup = (props: Props) => {
	const { content } = props;
	return (
		<Popup
			content={
				<div
					dangerouslySetInnerHTML={{
						__html: ` ${content}`,
					}}
				/>
			}
			trigger={
				<sup>
					<Icon name="question circle outline" />
				</sup>
			}
		/>
	);
};

export default MyPopup;
