import React from "react";
import { Card } from "semantic-ui-react";
// import flowerbee from "../../images/flowerbee.jpg";

const EcosystemImage = ({ imageItem }: { imageItem: any }) => {
	// render() {
	return (
		<Card fluid>
			<img
				src={imageItem}
				alt="flowerbee"
				className="banner"
				// style={{
				// 	height: "100%",
				// 	backgroundSize: "cover",
				// }}
			/>
		</Card>
	);
	// }
};

export default EcosystemImage;
