import React from "react";
import CookieConsent from "react-cookie-consent";
import {
	Container,
	// Divider,
	Icon,
	Menu,
	Segment,
	Sidebar,
	Visibility,
} from "semantic-ui-react";
import MediaLayout from "./MediaLayout";
import NavBar from "./NavBar";
import NavBarMobile from "./NavBarMobile";
import Routes from "./Route";
import { ModalWithContextProvider } from "./ModalWithContext";
import { FeedbackButton } from "../components/feedbacks/FeedbackButton";

const { Media, MediaContextProvider } = MediaLayout;

class MobileContainer extends React.Component {
	state = { sidebarOpened: false };

	handleSidebarHide = () => this.setState({ sidebarOpened: false });

	handleToggle = () => this.setState({ sidebarOpened: true });

	render() {
		const { children } = this.props;
		const { sidebarOpened } = this.state;

		return (
			<Media at="mobile">
				<Sidebar.Pushable>
					<NavBarMobile
						onSidebarOpened={this.handleSidebarHide}
						//   mobile
						sidebarOpened={sidebarOpened}
					/>

					<Sidebar.Pusher dimmed={sidebarOpened}>
						<Segment
							inverted
							textAlign="center"
							vertical
							style={{ marginBottom: "0.5rem" }}>
							<Container>
								<Menu inverted pointing secondary size="large">
									<Menu.Item onClick={this.handleToggle}>
										<Icon name="sidebar" />
									</Menu.Item>
								</Menu>
							</Container>
						</Segment>
						{children}
						<CookieConsent
							location="top"
							buttonText="I agree."
							buttonClasses="ui primary button"
							cookieName="esvdConsentCookie"
							style={{
								background: "#2B373B",
								height: "8rem",
								lineHeight: "1.2",
								fontSize: "0.8rem",
							}}
							buttonStyle={{ fontSize: "15px" }}
							expires={150}
							debug={
								!process.env.NODE_ENV || process.env.NODE_ENV === "development"
									? true
									: false
							}>
							<span style={{ margin: "auto", textAlign: "center" }}>
								This site uses google analytics and cookies to manage your
								access rights on this website. By using this website you
								specifically agree to this.
							</span>
						</CookieConsent>
					</Sidebar.Pusher>
				</Sidebar.Pushable>
			</Media>
		);
	}
}

class DesktopContainer extends React.Component {
	state = { fixed: "top" };

	hideFixedMenu = () => this.setState({ fixed: false });
	showFixedMenu = () => this.setState({ fixed: true });

	render() {
		const { children } = this.props;
		// const { fixed } = this.state;

		return (
			<Media greaterThan="mobile">
				<Visibility
					once={false}
					onBottomPassed={this.showFixedMenu}
					onBottomPassedReverse={this.hideFixedMenu}>
					<NavBar />
				</Visibility>
				{/* <Container style={{ marginTop: "4em" }}>{children}</Container> */}
				<div style={{ marginTop: "7em" }}>{children}</div>
				<CookieConsent
					location="top"
					buttonText="I agree."
					buttonClasses="ui primary button"
					cookieName="esvdConsentCookie"
					style={{
						background: "#2B373B",
						height: "5rem",
						lineHeight: "2.4",
						fontSize: "1.2rem",
					}}
					buttonStyle={{ fontSize: "18px" }}
					expires={150}
					debug={
						!process.env.NODE_ENV || process.env.NODE_ENV === "development"
							? true
							: false
					}>
					<span style={{ margin: "auto", textAlign: "center" }}>
						This site uses google analytics and cookies to manage your access
						rights on this website. By using this website you specifically agree
						to this.
					</span>
				</CookieConsent>
			</Media>
		);
	}
}

const ResponsiveContainer = ({ children }: { children: any }) => (
	/* Heads up!
	 * For large applications it may not be best option to put all page into these containers as
	 * they will be rendered twice for SSR.
	 */
	<MediaContextProvider>
		<DesktopContainer>{children}</DesktopContainer>
		<MobileContainer>{children}</MobileContainer>
		<ModalWithContextProvider>
			<FeedbackButton />
		</ModalWithContextProvider>
	</MediaContextProvider>
);

const BaseContainersLayout = () => {
	return (
		<ResponsiveContainer>
			<Routes />
		</ResponsiveContainer>
	);
};

export default BaseContainersLayout;
