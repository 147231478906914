import React, { Fragment } from "react";
import { Button, Checkbox, Grid, Progress } from "semantic-ui-react";

import {
	EsvdQueryDataWithOffset,
	EsvdQueryParams,
} from "../../models/EsvdQueryFilters";
import { ColumnStructure } from "../../models/columnStructure";
import {
	bodyRowsFlattened,
	buildTreeTypeQueryList,
	setMultiColumnMaxLenFlattened,
} from "../../EsvdFunctions";
import { ColumnTemplate } from "../../models/columnTemplate";
import { CSVLink } from "react-csv";
import { LIMIT } from "../../shared/Literals";
import agent from "../../api/agent";
import MakePDF from "./MakePDF";
import { CurrentReferenceInfo } from "../../models/ReferenceInfo";
import filterCheck from "../../shared/FilterCheck";

import { defaultEsvdParams } from "../../models/EsvdQueryFilters";
import { useModalWithContext } from "../../layouts/ModalWithContext";
import { useLists } from "../../providers/ListsProvider";

interface IProps {
	esvdQueryParams: EsvdQueryParams;
	filename: string;
	ButtonText: string;
	isFull?: boolean;
	currentVersion?: CurrentReferenceInfo | null;
	saveQueryParams?: Function;
}

let downloadPdf = false;

const EsvdExportEsvd = (props: IProps) => {
	const { modalDispatcher } = useModalWithContext();
	const { list } = useLists();

	const {
		ButtonText,
		filename,
		esvdQueryParams,
		isFull,
		currentVersion,
		saveQueryParams,
	} = props;
	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [progress, setprogress] = React.useState<number>(0);
	const [isDownloadCitation, setisDownloadCitation] =
		React.useState<boolean>(false);
	const [csvData, setcsvData] = React.useState<any[]>([]);

	const csvLinkRef: any | null = React.useRef<{ link: HTMLAnchorElement }>();

	const noFilterPrompt = () => {
		modalDispatcher({
			open: true,
			size: "small",
			displayType: "info",
			options: {
				title: "Attention!",
				body: (
					<Grid.Row centered>
						<Grid.Column width={12}>
							<p className="pos_fixed">
								There are no valuations yet to download.
							</p>
							<p className="pos_fixed">
								You need to apply a filter and get the valuations first before
								you can download.
							</p>
						</Grid.Column>
					</Grid.Row>
				),
				basicModal: true,
			},
		});
		// const { openModal } = this.props;
		// openModal({
		// 	icon: "exclamation",
		// 	header: "Attention!",
		// 	content: (
		// 		<Grid.Row centered>
		// 			<Grid.Column width={12}>
		// 				<p className="pos_fixed">
		// 					There are no valuations yet to download.
		// 				</p>
		// 				<p className="pos_fixed">
		// 					You need to apply a filter and get the valuations first before you
		// 					can download.
		// 				</p>
		// 			</Grid.Column>
		// 		</Grid.Row>
		// 	),
		// 	isOpen: true,
		// 	actions: null,
		// });
	};

	const handleChange = (e: any, checkedState: any) => {
		downloadPdf = checkedState.checked;
		setisDownloadCitation(checkedState.checked);
	};
	const showDownloadPrompt = () => {
		modalDispatcher({
			open: true,
			size: "small",
			displayType: "info",
			options: {
				title: "Attention!",
				body: (
					<Grid.Row>
						<Grid.Column width={12}>
							<p className="">
								By downloading data from this ESVD website you specifically
								agree to the Terms of Use.
							</p>
							<p className="">
								You are downloading data from Database Version:{" "}
								{currentVersion?.version}
							</p>
							<p className="">
								Correct Citation: {currentVersion?.referenceText}
							</p>

							<Checkbox
								label="Also download a pdf with the correct citation."
								onChange={handleChange}
							/>
						</Grid.Column>
					</Grid.Row>
				),
				basicModal: true,
				customActions4BasicModal: () => (
					<>
						<Button
							color="green"
							style={{ marginRight: "20px" }}
							onClick={async () => {
								if (!esvdQueryParams.saved && filterCheck(esvdQueryParams)) {
									//save query if not saved already
									saveQueryParams && saveQueryParams({ ...esvdQueryParams });
								}

								modalDispatcher({
									open: false,
								});
								if (currentVersion && downloadPdf) {
									try {
										currentVersion && MakePDF(currentVersion);
									} catch (error) {
										// console.log(error);
									}
								}
								downloadPdf = false;
								setisDownloadCitation(false);
								setIsLoading(true);
								const params = {
									limit: LIMIT,
									offset: 0,
									sortColumn: "studyId",
									isDescend: true,
								};
								const filta = isFull
									? { ...defaultEsvdParams, params }
									: esvdQueryParams;
								await fetchFilteredEsvds(filta).then((r) => {
									const cols: ColumnStructure = setMultiColumnMaxLenFlattened(
										r.data,
										ColumnTemplate
									);
									// specify the key to delete from the columns in the downloaded csv
									const keyToDelete: string = "inclExclText";

									const body = bodyRowsFlattened(r.data, {
										...cols,
										[keyToDelete]: undefined,
									});

									setcsvData(body);

									if (csvLinkRef?.current) {
										csvLinkRef.current.link.click();
										setcsvData([]);
									}
									setIsLoading(false);
								});
							}}>
							Download
						</Button>
						<Button
							style={{ marginLeft: "10px" }}
							color="red"
							onClick={() =>
								modalDispatcher({
									open: false,
								})
							}>
							Cancel
						</Button>
					</>
				),
			},
		});
	};

	const fetchFilteredEsvds = async (filters: EsvdQueryParams) => {
		/**
		 * TODO: this will need to be refactored to update the query params in one place.
		 */

		// const page = Number(isFreshFilter ? 1 : activePage);

		const params = { ...filters.params };
		const { ecosystemServices, biomeV2EcosystemV2Ecozones, ciceses } = params;

		// params.offset = page > 1 ? page - 1 : 0;

		if (ecosystemServices && ecosystemServices.length > 0) {
			params.ecosystemServices = buildTreeTypeQueryList(
				ecosystemServices,
				list.ecosystemServices
			);
		}

		if (biomeV2EcosystemV2Ecozones && biomeV2EcosystemV2Ecozones.length > 0) {
			params.biomeV2EcosystemV2Ecozones = buildTreeTypeQueryList(
				biomeV2EcosystemV2Ecozones,
				list.biomeV2EcosystemV2Ecozones
			);
		}

		if (ciceses && ciceses.length > 0) {
			params.ciceses = buildTreeTypeQueryList(ciceses, list.ciceses);
		}

		const qryfil = { ...filters, params: { ...params } };

		const count = await agent.Esvds.esvdCount(qryfil);
		const data: any[] = [];
		if (count > 0) {
			const pages = Math.ceil(count / LIMIT);
			qryfil.params.limit = LIMIT;
			let nextOffset = 0;
			while (nextOffset < pages) {
				qryfil.params.offset = nextOffset;
				const response = await agent.Esvds.list(qryfil);
				data.push(...response);
				const progres = Math.ceil(((nextOffset + 1) / pages) * 100);
				setprogress(progres);

				nextOffset++;
			}
		}
		return { count, data };
	};

	const handleClick = async () => {
		if (!isFull) {
			if (!filterCheck(esvdQueryParams)) {
				noFilterPrompt();
				return;
			}
		}

		showDownloadPrompt();
	};

	return (
		<Fragment>
			<Button
				positive
				loading={isLoading}
				content={ButtonText}
				onClick={() => handleClick()}
				disabled={isLoading}
				className={`${isLoading ? "invisible" : ""}`}
			/>
			<Progress
				percent={progress}
				className={`${isLoading ? "" : "invisible"}`}
			/>
			<CSVLink
				ref={csvLinkRef}
				data={csvData}
				filename={filename}
				target="_blank"
				className={`${"invisible"}`}
				enclosingCharacter={'"'}
			/>
		</Fragment>
	);
};

export default EsvdExportEsvd;
