import { ErrorMessage, Form, Formik } from "formik";
import React, { useEffect } from "react";
import { Card, Grid, Label } from "semantic-ui-react";
import * as Yup from "yup";
import EsvdInput from "../../shared/Input";
import { Link, Redirect } from "react-router-dom";
import { useAuth } from "../../providers/AuthProvider";
import LoginsContainer from "./LoginsContainer";
import { UserLoginMode } from "../../models/user";
import { ErrorHandler } from "../../shared/ExceptionHandling";

interface IProps {
	setUserLoginMode: Function;
}

const UsernameOrEmail = (props: IProps) => {
	const { user, verifyUsername, setToken } = useAuth();
	const { setUserLoginMode } = props;

	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [isSent, setisSent] = React.useState<boolean>(false);

	useEffect(() => {
		return () => {};
	}, []);

	const handleFormSubmit = async (
		values: { usernameOrEmail: string; error: null },
		setErrors: Function
	) => {
		setIsLoading(true);
		try {
			// if (!userLoginMode) {
			const um: UserLoginMode = await verifyUsername(values.usernameOrEmail);
			setUserLoginMode({ ...um, usernameOrEmail: values.usernameOrEmail });
			// if (um) {
			// 	setUserLoginMode(um);
			// 	setIsLoading(false);
			// 	if (um.userExists) {
			// 		if (um.isUsername) {
			// 			//show password form
			// 		} else {
			// 			//generate OTP and show  code form
			// 		}
			// 	} else {
			// 		//report failure or show sign up page
			// 	}
			// }
			// }
		} catch (error) {
			setErrors({
				error: ErrorHandler(error),
			});
			setIsLoading(false);
		} finally {
			setIsLoading(false);
		}
	};

	const renderContent = () => {
		if (!user) {
			setToken();
			return (
				<Card
					centered
					// className="small-card"
					style={{
						padding: "10px",
					}}>
					<Card.Content textAlign="left">
						<h4 className="title-card">Welcome to the ESVD</h4>
					</Card.Content>
					{isSent ? (
						<Card.Content className="text-center">
							An email has been sent to your inbox. <br />
							Please check your inbox to reset your password.
							<br />
							If you don't see an email, please check your spam folder.
						</Card.Content>
					) : (
						<>
							<Card.Content textAlign="left">
								<Card.Description>
									Login below if you already have an account. If you do not have
									an account yet, please Sign Up first by clicking the Sign Up
									button below and create your account.
								</Card.Description>
								<br />
								<Formik
									validationSchema={validationSchema}
									enableReinitialize
									initialValues={{
										usernameOrEmail: "",
										error: null,
									}}
									onSubmit={(values, { setErrors }) => {
										handleFormSubmit(values, setErrors);
									}}>
									{({ handleSubmit, isValid, isSubmitting, dirty, errors }) => (
										<Form
											className="ui form page-content"
											onSubmit={handleSubmit}
											autoComplete={"off"}>
											<EsvdInput
												name="usernameOrEmail"
												label="Username or email address"
												fluid
												icon={"user outline"}
												placeholder="Username or email address"
												autoFocus={true}
												//let it have default focus
												// autoFocus
											/>
											<ErrorMessage
												name="error"
												render={() => (
													<Label
														style={{ marginBottom: 10 }}
														basic
														color="red"
														content={errors.error}
													/>
												)}
											/>
											<Grid
												style={{
													marginTop: "1rem",
													paddingTop: "0px",
													fontSize: "0.8rem",
												}}>
												<Grid.Row>
													<Grid.Column width={8}>
														<div
															style={{
																border: "0.05rem solid #70bbfd",
																borderRadius: "5px",
															}}>
															<Link
																to={"/signup"}
																className="ui button fluid btn btn-primary"
																type="submit"
																style={{
																	borderColor: "#70bbfd",
																	backgroundColor: "#fff",
																	height: "2.8rem",
																	color: "#70bbfd",
																	lineHeight: "2.2rem",
																}}>
																<span>Sign Up</span>
															</Link>
														</div>
													</Grid.Column>
													<Grid.Column width={8}>
														<button
															disabled={!dirty || (dirty && !isValid)}
															className="ui button fluid btn btn-primary"
															type="submit"
															style={{
																backgroundColor: "#70bbfd",
																display: "block !important",
																height: "3rem",
															}}>
															<span className={`${isLoading ? "hide" : ""}`}>
																Next
															</span>
															<span
																className={`box loader-01 ${
																	isLoading ? "" : "invisible"
																}`}></span>
														</button>
													</Grid.Column>
												</Grid.Row>
											</Grid>
										</Form>
									)}
								</Formik>
								{/* <Card.Content className="text-center"> */}
							</Card.Content>
						</>
					)}
				</Card>
			);
		} else return <Redirect to="/" />;
	};

	// render() {
	return <LoginsContainer>{renderContent()}</LoginsContainer>;
	// }
};

const validationSchema = Yup.object({
	usernameOrEmail: Yup.string()
		.trim()
		.required("Username or Email is required"),
});

export default UsernameOrEmail;
