import React from "react";
import { Card } from "semantic-ui-react";
import { useReferenceInfo } from "../../providers/ReferenceInfoProvider";

const TechnicalImplementation = () => {
	const { currentInfo } = useReferenceInfo();

	return (
		<Card fluid>
			<Card.Content>
				<p>
					<a
						className="esvd-hyperlink"
						href="https://www.fsd.nl/"
						target="_blank"
						rel="noopener noreferrer">
						Foundation for Sustainable Development
					</a>{" "}
					(2021) - All rights reserved - ESVD database version{" "}
					{currentInfo?.version} - Technical implementation and information
					management advice by{" "}
					<a
						className="esvd-hyperlink"
						href="https://www.co-capacity.com"
						target="_blank"
						rel="noopener noreferrer">
						Co-Capacity
					</a>
					.
				</p>
			</Card.Content>
		</Card>
	);
};

export default TechnicalImplementation;
