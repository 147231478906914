import React from "react";
import { Card } from "semantic-ui-react";

const Contribute = () => {
	return (
		<Card fluid>
			<Card.Content>
				<h4 className="title-card">Contribute</h4>
			</Card.Content>
			<Card.Content>
				<p>
					You can suggest a study to be added to the ESVD using the “Suggest a
					Study” page. Suggested studies will be added to the repository of
					valuation studies to be analysed, entered into the database, reviewed
					and shared through the ESVD.{" "}
				</p>
			</Card.Content>
		</Card>
	);
};

export default Contribute;
