export const feedbackCsvHeaders = {
	"howSatisfied": "How satisfied are you with esvd.net? (scale 1-10)",
	"ideasforEsvd": "Do you have general feedback or ideas for esvd.net",
	"useforEsvd": "It would be of great interest to hear more about your use of the ESVD. What are you using the ESVD for and what is its added value?",
	"issueLoadingTables": "Issue - Loading table results",
	"issueLoadingMapResult": "Issue - Loading mapping results",
	"issueLoadingSumStart": "Issue - Loading summary statistics",
	"issueDownload": "Issue - Downloading the data",
	"issueLoggin": "Issue - Logging-in",
	"issueOther": "Issue - Other",
	"issueOtherText": "Issue - Other text",
	"additionalFeatures":
		"Additional features (new filters)",
};

const feedbackdata = {
	howSatisfied: "How satisfied are you with esvd.net? (scale 1-10)",
	ideasforEsvd: "Do you have general feedback or ideas for esvd.net?",
	useforEsvd:
		"It would be of great interest to hear more about your use of the ESVD. What are you using the ESVD for and what is its added value?",
	issue: {
		label: "Have you experienced any technical issues or bugs?",
		options: {
			issueLoadingTables: "Loading table results",
			issueLoadingMapResult: "Loading mapping results",
			issueLoadingSumStart: "Loading summary statistics",
			issueDownload: "Downloading the data",
			issueLoggin: "Logging-in",
			issueOther: "Other, please specify",
		},
		otherChecked: false,
		issueOtherText: "",
	},
	additionalFeatures:
		"Which additional features (new filters, value transfer function etc.) would you like to see on esvd.net that would help you better utilize the data?",
};



export default feedbackdata;
