import { ErrorMessage, Field, Formik } from "formik";
import React, { useEffect } from "react";
import { Form, Label, Card, Checkbox, Grid } from "semantic-ui-react";
import * as Yup from "yup";

import { useVttBiome } from "../../../providers/VttBiomeProvider";
import { useState } from "react";
import { VttBiome } from "../../../models/vtt-models/VttBiome";
import EsvdInputInline from "../../../shared/InputInline";
import { ErrorHandler } from "../../../shared/ExceptionHandling";
import { ViewHandles, useVtt } from "../../../providers/VttProvider";

const defaultBiome: VttBiome = {
	name: "",
	constantValue: "",
	lowerBoundFactor: "",
	upperBoundFactor: "",
	showForUserCalculations: false,
};

const VttBiomeEntry = () => {
	const { currentView, setCurrentView } = useVtt();
	const { createVttBiome, getVttBiome, updateVttBiome } = useVttBiome();
	const [vttBiome, setVttBiome] = useState<VttBiome>();
	const [checked, setChecked] = useState<boolean | null>(false);

	useEffect(() => {
		const { biomeid } = currentView;
		if (biomeid) {
			//retrieve biome
			(async () => {
				const vt = await getVttBiome(biomeid);
				setVttBiome(vt);
				if (vt) {
					setChecked(vt.showForUserCalculations);
				}
			})();
		} else {
			//set to default
			setVttBiome(defaultBiome);
		}
	}, []);

	const handleFormSubmit: Function = async (
		values: any,
		setErrors: Function
	) => {
		try {
			if (values.id) {
				await updateVttBiome({ ...values, showForUserCalculations: checked });
			} else {
				await createVttBiome({ ...values, showForUserCalculations: checked });
			}
			setCurrentView({ view: ViewHandles.biome });
		} catch (error) {
			setErrors({ error: ErrorHandler(error) });
		}
	};

	return (
		<Card.Content>
			<Formik
				enableReinitialize={true}
				validationSchema={validationSchema}
				initialValues={vttBiome || defaultBiome}
				onSubmit={(values, { setErrors }) => {
					handleFormSubmit(values, setErrors);
				}}>
				{({
					// values,
					// handleChange,
					handleSubmit,
					// isValid,
					// isSubmitting,
					// dirty,
					errors,
					// resetForm,
					// setFieldValue,
				}) => (
					<Form
						className="custom-ui-element"
						onSubmit={handleSubmit}
						autoComplete={"off"}
						encType="multipart/form-data">
						<div style={{ display: "none" }}>
							<EsvdInputInline name="id" type="hidden" />
						</div>
						<EsvdInputInline
							name="name"
							label="Name (*)"
							fluid
							placeholder="Name"
						/>
						<EsvdInputInline
							name="constantValue"
							label="Constant (*)"
							fluid
							placeholder="Constant value"
						/>
						<EsvdInputInline
							name="lowerBoundFactor"
							label="Lower bound factor (*)"
							fluid
							placeholder="Lower bound factor"
						/>
						<EsvdInputInline
							name="upperBoundFactor"
							label="Upper bound factor (*)"
							fluid
							placeholder="Upper bound factor"
						/>
						<div>
							<Grid>
								<Grid.Row>
									<Grid.Column width={4}></Grid.Column>
									<Grid.Column width={12}>
										<Field
											name="showForUserCalculations"
											label="Show this Biome in the VTT to visitors"
											onChange={(e: any, data: any) => setChecked(data.checked)}
											checked={checked || false}
											component={Checkbox}
										/>
									</Grid.Column>
								</Grid.Row>
							</Grid>
						</div>
						<ErrorMessage
							name="error"
							render={() => (
								<Label
									style={{ marginBottom: 5, borderColor: "none" }}
									basic
									color="red"
									size="tiny"
									content={errors.error}
								/>
							)}
						/>
						<label
							style={{
								color: "red",
							}}>{`(*) = required`}</label>
						<br />
						<button
							className="ui button btn btn-primary"
							type="reset"
							style={{
								marginTop: "1rem",
								backgroundColor: "#70bbfd",
								display: "block !important",
								height: "2rem",
								width: "5rem",
							}}
							onClick={() => {
								setCurrentView({ view: ViewHandles.biome });
							}}>
							Back
						</button>
						{/* <div className="ui "> */}
						<button
							className="ui button btn btn-primary right floated"
							type="submit"
							style={{
								marginTop: "1rem",
								backgroundColor: "#70bbfd",
								display: "block !important",
								height: "2rem",
								width: "5rem",
							}}>
							Save
						</button>
						{/* </div> */}
					</Form>
				)}
			</Formik>
		</Card.Content>
	);
};

const validationSchema: any = Yup.object({
	name: Yup.string().trim().required("Name is required"),
	constantValue: Yup.number()
		.typeError("Constant must be a number")
		.required("Constant value is required"),
	lowerBoundFactor: Yup.number()
		.typeError("Lower bound factor must be a number")
		.required("Lower bound factor is required")
		.test(
			"len",
			`Lower bound factor must be between 0 and 1 (inclusive).`,
			(val: any) => val >= 0 && val <= 1
		),

	upperBoundFactor: Yup.number()
		.typeError("Upper bound factor must be a number")
		.required("Upper bound factor is required")
		.test(
			"len",
			`Upper bound factor must be between 1 and 2 (inclusive).`,
			(val: any) => val >= 1 && val <= 2
		),
});

export default VttBiomeEntry;
