import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { Fragment } from "react";
import { Card, Container, Grid, Label } from "semantic-ui-react";
import * as Yup from "yup";
import EsvdInput from "../../shared/Input";
import WelcomeHeader from "../../shared/WelcomeHeader";
import { Link, Redirect } from "react-router-dom";
import {
	EMAIL_REGEX,
	PASSWORD,
	purposeOfAccessList,
	sectorsList,
} from "../../shared/Literals";
import { ErrorHandler } from "../../shared/ExceptionHandling";
import { MyDropdown } from "../../shared/SingleSelect";
import { useAuth } from "../../providers/AuthProvider";
import LoginsContainer from "./LoginsContainer";

const Signup = () => {
	const { user, setToken, signup } = useAuth();

	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [isSigned, setisSigned] = React.useState<boolean>(false);
	const [subheadText, setsubheadText] = React.useState<string>(
		"Create an account (All fields required)"
	);
	const [selectedSector, setselectedSector] = React.useState<string>("");
	const [selectedPurpose, setselectedPurpose] = React.useState<string>("");
	const [sectorOtherShow, setsectorOtherShow] = React.useState<boolean>(false);
	const [purposeOtherShow, setpurposeOtherShow] =
		React.useState<boolean>(false);

	const signupDone = () => {
		setisSigned(!isSigned);
		setsubheadText("Sign up successful.");
	};

	const setSector = (value: string) => {
		setselectedSector(value);
		setsectorOtherShow(value.toLowerCase() === "other");
	};

	const setPurposeOfAccess = (value: string) => {
		setselectedPurpose(value);
		setpurposeOtherShow(value.toLowerCase() === "other");
	};

	const handleFormSubmit = (
		values: {
			displayName: string;
			organization: string;
			sector: string;
			sectorOther: string;
			purposeOfAccess: string;
			purposeOfAccessOther: string;
			email: string;
			username: string;
			password: string;
			error: null;
		},
		setErrors: Function
	) => {
		const { sector, purposeOfAccess } = values;
		if (sector && sector.toLowerCase() !== "other") {
			values.sectorOther = "";
		}
		if (purposeOfAccess && purposeOfAccess.toLowerCase() !== "other") {
			values.purposeOfAccessOther = "";
		}
		setIsLoading(true);
		signup(values)
			.then((res: any) => {
				signupDone();
				setIsLoading(false);
			})
			.catch((error: any) => {
				setErrors({ error: ErrorHandler(error) });
				setIsLoading(false);
			});
	};

	const renderContent = () => {
		if (!user) {
			setToken();
			return (
				<Card
					centered
					className="small-card"
					style={{
						padding: "10px",
					}}>
					<Card.Content textAlign="left">
						<h4 className="title-card">Welcome to the ESVD</h4>
					</Card.Content>
					{isSigned ? (
						<Card.Content className="text-center">
							An email has been sent to your inbox. <br />
							Please check your inbox to confirm your email within 24 hours.
							<br />
							If you do not see an email, please check your spam folder.
							<br />
							If you do not confirm your email within 24 hours you have to
							register again.
						</Card.Content>
					) : (
						<Card.Content textAlign="left">
							<Card.Description>{subheadText}</Card.Description>
							<br />
							<Formik
								validationSchema={validationSchema}
								enableReinitialize
								initialValues={{
									displayName: "",
									organization: "",
									sector: "",
									sectorOther: "",
									purposeOfAccess: "",
									purposeOfAccessOther: "",
									email: "",
									username: "",
									password: "",
									error: null,
								}}
								onSubmit={(values, { setErrors }) => {
									handleFormSubmit(values, setErrors);
								}}>
								{({ handleSubmit, isValid, isSubmitting, dirty, errors }) => (
									<Form
										className="ui form page-content"
										onSubmit={handleSubmit}
										autoComplete={"off"}>
										<EsvdInput
											name="displayName"
											label="Name"
											fluid
											icon={"user outline"}
											placeholder="Display name"
										/>

										<EsvdInput
											name="organization"
											label="Organization"
											fluid
											icon={"building"}
											placeholder="Organization"
										/>
										<Field
											name="sector"
											label="Sector"
											options={sectorsList}
											component={MyDropdown}
											isMulti={false}
											value={selectedSector}
											placeholder="Sector"
											onChange={setSector}
										/>
										{sectorOtherShow ? (
											<EsvdInput
												name="sectorOther"
												label=""
												fluid
												placeholder=""
											/>
										) : (
											<div
												style={{
													display: "none",
												}}>
												<EsvdInput
													name="sectorOther"
													label=""
													fluid
													placeholder=""
												/>
											</div>
										)}
										<Field
											name="purposeOfAccess"
											label="Purpose of access"
											options={purposeOfAccessList}
											component={MyDropdown}
											isMulti={false}
											value={selectedPurpose}
											placeholder="Purpose of access"
											onChange={setPurposeOfAccess}
										/>
										{purposeOtherShow ? (
											<EsvdInput
												name="purposeOfAccessOther"
												label=""
												fluid
												placeholder=""
											/>
										) : (
											<div
												style={{
													display: "none",
												}}>
												<EsvdInput
													name="purposeOfAccessOther"
													label=""
													fluid
													placeholder=""
												/>
											</div>
										)}
										<EsvdInput
											name="username"
											label="Username"
											fluid
											icon={"user outline"}
											placeholder="Username"
										/>
										<EsvdInput
											name="email"
											label="Email"
											fluid
											icon={"at"}
											placeholder="Email"
										/>
										<EsvdInput
											name="password"
											label={`Password (Min ${PASSWORD.min_len} Characters)`}
											type="password"
											fluid
											icon={"key"}
											placeholder="Password"
										/>
										<EsvdInput
											name="confirmpassword"
											label="Confirm Password"
											type="password"
											fluid
											icon={"key"}
											placeholder="Confirm Password"
										/>
										<ErrorMessage
											name="error"
											render={() => (
												<Label
													style={{
														marginBottom: 5,
														borderColor: "none",
													}}
													basic
													color="red"
													size="tiny"
													content={errors.error}
												/>
											)}
										/>
										<button
											disabled={!dirty || (dirty && !isValid)}
											className="ui button fluid btn btn-primary"
											type="submit"
											style={{
												backgroundColor: "#70bbfd",
												display: "block !important",
												height: "3rem",
											}}>
											<span className={`${isLoading ? "hide" : ""}`}>
												Sign Up
											</span>
											<span
												className={`box loader-01 ${
													isLoading ? "" : "invisible"
												}`}></span>
										</button>
									</Form>
								)}
							</Formik>
							{/* Already have an account?{" "}
														<Link to="/login">Login</Link> */}
						</Card.Content>
					)}
					{/* <Card.Content className="text-center"> */}
					<div style={{ display: "inline-block" }}>
						{" "}
						Already have an account? <Link to="/login">Login</Link>
					</div>
					{/* </Card.Content> */}
				</Card>
			);
		}

		//a login user shouldn't see the signup page.
		//Therefore is a logged user access the signup route, go to home
		// history.push("/");
		// return null;
		else return <Redirect to="/" />;
	};

	// render() {
	return <LoginsContainer>{renderContent()}</LoginsContainer>;
	// }
};

const validationSchema = Yup.object({
	displayName: Yup.string().required("Display name is required"),
	organization: Yup.string().required("Organization is required"),
	sector: Yup.string().required("Sector is required"),
	sectorOther: Yup.string().when(["sector"], {
		is: (sector: string) => sector && sector.toLowerCase() === "other",
		then: Yup.string().required("Sector description is required"),
	}),
	purposeOfAccess: Yup.string().required("Purpose of access is required"),
	purposeOfAccessOther: Yup.string().when(["purposeOfAccess"], {
		is: (purposeOfAccess: string) =>
			purposeOfAccess && purposeOfAccess.toLowerCase() === "other",
		then: Yup.string().required("Purpose description is required"),
	}),
	username: Yup.string().required("Username is required"),
	email: Yup.string()
		.trim()
		.matches(EMAIL_REGEX.exp, EMAIL_REGEX.msg)
		.required("Email is required"),
	password: Yup.string()
		.trim()
		.min(PASSWORD.min_len, PASSWORD.msg)
		.required("Password is required"),
	confirmpassword: Yup.string()
		.oneOf([Yup.ref("password"), null], "Passwords must match")
		.required("Confirm Password is required"),
});

// const mapStateToProps = (state: any) => {
// 	return { user: state.user };
// };

export default Signup;
// connect(mapStateToProps, {
// 	signup,
// })(Signup);
