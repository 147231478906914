import React, { useContext } from "react";
// import _ from "lodash";
import agent from "../api/agent";
import { PagingParams } from "../models/PaginationParams";
import { VttVariable } from "../models/vtt-models/VttBiome";

interface IValue {
	getVttVariables: Function;
	getVttVariableCount: Function;
	createVttVariable: Function;
	getVttVariable: Function;
	updateVttVariable: Function;
	deleteVttVariable: Function;
	getUnattahcedVttVariables: Function;
}

const VttVariableContext = React.createContext<IValue | null>(null);

// The FilterQueryProvider is responsible for FilterQuery management
const VttVariableProvider = ({ children }: { children: any }) => {
	const getVttVariableCount = async () => {
		try {
			return await agent.VttVariables.count();
		} catch (error: any) {
			throw error.response ? error.response.statusText : error.message;
		}
	};

	const getVttVariables = async (pageParams: PagingParams) => {
		try {
			const vtts = await agent.VttVariables.list(pageParams);
			if (!vtts) return [];
			return vtts;
		} catch (error: any) {
			throw error.response ? error.response.statusText : error.message;
		}
	};

	const getUnattahcedVttVariables = async (
		biomeid: number,
		selectedVariableid: number | null
	) => {
		try {
			const vtts = selectedVariableid
				? await agent.VttVariables.listUnattachedVariables(
						biomeid,
						selectedVariableid
				  )
				: await agent.VttVariables.listExclusiveUnattachedVariables(biomeid);
			if (!vtts) return [];
			return vtts;
		} catch (error: any) {
			throw error.response ? error.response.statusText : error.message;
		}
	};
	const getVttVariable = async (id: number) => {
		try {
			return await agent.VttVariables.details(id);
		} catch (error: any) {
			throw error.response ? error.response.statusText : error.message;
		}
	};

	const createVttVariable = async (vttVariable: VttVariable) => {
		try {
			await agent.VttVariables.create(vttVariable);
		} catch (error: any) {
			throw error.response ? error.response.statusText : error.message;
		}
	};

	const updateVttVariable = async (vttVariable: VttVariable) => {
		try {
			await agent.VttVariables.update(vttVariable);
		} catch (error: any) {
			throw error.response ? error.response.statusText : error.message;
		}
	};

	const deleteVttVariable = async (id: number) => {
		try {
			await agent.VttVariables.delete(id);
		} catch (error: any) {
			throw error.response ? error.response.statusText : error.message;
		}
	};

	return (
		<VttVariableContext.Provider
			value={{
				getVttVariables,
				getVttVariableCount,
				createVttVariable,
				getVttVariable,
				updateVttVariable,
				deleteVttVariable,
				getUnattahcedVttVariables,
			}}>
			{children}
		</VttVariableContext.Provider>
	);
};

// The useStackData hook can be used by components under an StackDataProvider to
// access the auth context value.
const useVttVariable = () => {
	const filterQuery = useContext(VttVariableContext);
	if (filterQuery == null) {
		throw new Error("useVttVariable() called outside of its hook.");
	}
	return filterQuery;
};

export { VttVariableProvider, useVttVariable };
