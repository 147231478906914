import { defaultSort, IS_DESCEND_DEFAULT, LIMIT } from "../shared/Literals";

export interface Params {
    offset?: number;
    limit?: number;
    countries?: number[];
    protectionStatuses?: number[];
    continents?: number[];
    valuationMethods?: number[];
    ecosystemServices?: number[];
    seeas?: number[];
    ciceses?: number[];
    biomeEcosystems?: number[];
    biomeV2EcosystemV2Ecozones?: number[];
    freeText?: string;
    sortColumn?: string;
    isDescend?: boolean;
}

/**
 * use this to pass parameters to query functions as objects instead of passing them individually.
 * This helps to avoid the need to pass arguments in the exact order as they appear in the function signature.
 */
export interface EsvdQueryParams {
  isTeeb?: "teeb" | "cices" | null;
  params: Params;
  // activePage: number;
  saved?:boolean
}

export const defaultEsvdParams:EsvdQueryParams={
		isTeeb: null,
		params: {
			limit: LIMIT,
			offset: 0,
			sortColumn: defaultSort.key,
			isDescend: IS_DESCEND_DEFAULT,
  },
    saved:false
  // activePage: 0,
}
  
export interface EsvdQueryDataWithOffset {
  nextOffset: number;
  data: any[];
}

export interface QueryString {
  username?: string;
  code?: string;
}

export interface FreeTextQueryParams {
  freeText?: string | null;
}

export interface FilterQueryParams {
  params: {
    offset?: number;
    limit?: number;
    freeText?: string;
    date?: string;
  };
}

export interface UserQueryParams {
  params: {
    offset?: number;
    limit?: number;
    freeText?: string;
    emailConfirmed?: boolean;
    sortColumn?: string;
    isDescend?: boolean;
  };
}
