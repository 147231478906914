import React, { useEffect } from "react";
import { Grid, Container } from "semantic-ui-react";
import EcosystemValuationDB from "./EcosystemValuationDB";
import TermsOfUse from "./TermsOfUse";
import AboutESVD from "./AboutESVD";
import Contact from "./Contact";
import TechnicalImplementation from "./TechnicalImplementation";
import EcosystemImage from "./EcosystemImage";
import MediaLayout from "../../layouts/MediaLayout";
import { useActiveMenu } from "../../providers/ActiveMenuProvider";
import { resources } from "../../shared/Literals";
import Contribute from "./Contribute";
import firstHomeImage from "../../images/nunzio-guerrera-LA8kstntJhI-unsplash.jpg";
import secondHomeImage from "../../images/robin-canfield-JDlF_d9BDAQ-unsplash.jpg";

const { Media, MediaContextProvider } = MediaLayout;

const HomePage = () => {
	const { setActiveItem } = useActiveMenu();

	useEffect(() => {
		setActiveItem(resources.home.name);
		return () => {};
	}, []);

	return (
		<MediaContextProvider>
			<Media at="mobile">
				<Container fluid>
					<EcosystemImage imageItem={firstHomeImage} />
					<EcosystemValuationDB />
					<AboutESVD />
					<EcosystemImage imageItem={firstHomeImage} />
					<TermsOfUse />
					<Contribute />
					<Contact />
					<TechnicalImplementation />
				</Container>
			</Media>
			<Media greaterThan="mobile">
				<Container fluid className="top-container">
					<Grid className="page-content" stackable>
						<Grid.Row>
							<Grid.Column stretched>
								<EcosystemImage imageItem={firstHomeImage} />
								<EcosystemValuationDB />
								<AboutESVD />
								<EcosystemImage imageItem={secondHomeImage} />
							</Grid.Column>
						</Grid.Row>
						<Grid.Row columns={3}>
							<Grid.Column stretched>
								<TermsOfUse />
							</Grid.Column>
							<Grid.Column stretched>
								<Contribute />
							</Grid.Column>
							<Grid.Column stretched>
								<Contact />
							</Grid.Column>
						</Grid.Row>
						<Grid.Row>
							<Grid.Column stretched>
								<TechnicalImplementation />
							</Grid.Column>
						</Grid.Row>
					</Grid>
				</Container>
			</Media>
		</MediaContextProvider>
	);
};

export default HomePage;
