import { Component } from "react";
// import esvd_logo from "../../images/esvd_logo.jpg";
// import esvd_logo2 from "../../images/esvd_logo_3.png";
// import esvd_logo from "../../images/esvd_newlogosmall.jpg";
import esvd_logo from "../../images/esvd-newlogo.webp";
//set the image size to half its zise

const EcosystemLogo = () => {
	// render() {
	return (
		<img
			src={esvd_logo}
			alt="logo"
			style={{
				height: "auto",
				width: "100%",
				backgroundSize: "contain",
				outline: "none",
				border: "none",
				paddingTop: ".5em",
			}}
		/>
	);
	// }
};

export default EcosystemLogo;
