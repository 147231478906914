import { ErrorMessage, Field, Formik } from "formik";
import React, { Fragment } from "react";
import { Form, Label, Card, Grid } from "semantic-ui-react";
// import * as Yup from "yup";
import { ErrorHandler } from "../../shared/ExceptionHandling";
import EsvdInputInline from "../../shared/InputInline";
import { initReferenceInfo } from "../../shared/Literals";
import { ReferenceInfo } from "../../models/ReferenceInfo";
import { useReferenceInfo } from "../../providers/ReferenceInfoProvider";

const CitationEntry = () => {
	const formikRef = React.useRef<any>();
	const { saveReferenceInfo, getCurrentVersionInfo, currentInfo } =
		useReferenceInfo();
	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [referenceInfo] = React.useState<ReferenceInfo>(initReferenceInfo);

	const clearReferenceInfo = () => {
		formikRef.current?.resetForm();
	};

	const renderButtons = (values: any) => {
		return (
			<Fragment>
				<Grid>
					<Grid.Column width={4}>
						<button
							className="ui button btn btn-primary"
							type="reset"
							style={{
								marginTop: "1rem",
								backgroundColor: "#70bbfd",
								// backgroundColor: "#f6da6e",
								// display: "block !important",
								height: "2rem",
								width: "4rem",
							}}
							onClick={() => clearReferenceInfo()}>
							Clear
						</button>
					</Grid.Column>
					<Grid.Column width={12}>
						<div className="ui right floated">
							<button
								className="ui button btn btn-primary"
								type="submit"
								style={{
									marginTop: "1rem",
									backgroundColor: "#70bbfd",
									display: "block !important",
									height: "2rem",
									width: "4rem",
									marginRight: "2rem",
								}}>
								<span className={`${isLoading ? "hide" : ""}`}>Save</span>
								<span
									className={`box loader-01 ${
										isLoading ? "" : "invisible"
									}`}></span>
							</button>
						</div>
					</Grid.Column>
				</Grid>
			</Fragment>
		);
	};
	const renderContent = () => {
		return (
			<>
				<h5>Current Citation text: {currentInfo?.referenceText}</h5>
				<br />
				<Formik
					innerRef={formikRef}
					enableReinitialize={true}
					// validationSchema={validationSchema}
					initialValues={referenceInfo}
					onSubmit={(values, { setErrors, resetForm }) => {
						handleFormSubmit(values, setErrors, resetForm);
					}}>
					{({ values, handleSubmit, isValid, isSubmitting, dirty, errors }) => (
						<Form
							className="custom-ui-element"
							onSubmit={handleSubmit}
							autoComplete={"off"}>
							<div style={{ display: "none" }}>
								<EsvdInputInline name="id" type="hidden" />
							</div>

							<EsvdInputInline
								name="referenceText"
								label="Citation text"
								fluid
								placeholder="Citation text"
							/>
							{errors.error && (
								<Label
									style={{ marginBottom: 5, borderColor: "none" }}
									basic
									color="red"
									size="tiny"
									content={errors.error}
								/>
							)}

							{renderButtons(values)}
						</Form>
					)}
				</Formik>
			</>
		);
	};

	const handleFormSubmit: Function = (
		values: ReferenceInfo,
		setErrors: Function,
		resetForm: Function
	) => {
		setIsLoading(true);
		values.subversion = values.subversion || 0;
		values.fromCitation = true;
		saveReferenceInfo(values)
			.then((res: any) => {
				clearReferenceInfo();
				setIsLoading(false);
				getCurrentVersionInfo();
			})

			.catch((error: any) => {
				setErrors({ error: ErrorHandler(error) });
				setIsLoading(false);
			});
	};

	return (
		<Card fluid>
			<Card.Content>
				<h4 className="title-card">{"Citation text"}</h4>
			</Card.Content>

			<Card.Content>{renderContent()}</Card.Content>
		</Card>
	);
};
// const validationSchema: any = Yup.object({
// 	// ["month"]: Yup.number(),
// 	// .nullable(false)
// 	// .required("Month is required")
// 	// .positive(`Month must be a positive number.`)
// 	// .min(0, `Month must be grater than or equal to 0.`),
// 	["year"]: Yup.number()
// 		.typeError("Year must be a number")
// 		.required("Year is required")
// 		.positive(`Year must be a positive number.`)
// 		.min(1950, `Year must be grater than or equal to 1950.`)
// 		.test(
// 			"len",
// 			`Year is not a valid year.`,
// 			(val: any) => !val || (val && val.toString().length === 4)
// 		),
// 	["version"]: Yup.number()
// 		// .nullable(false)
// 		.typeError("Version is required")
// 		.required("Version is required")
// 		.positive(`Version must be a positive number.`)
// 		.min(1, `Version must be grater than or equal to 1.`),
// 	["subversion"]: Yup.number()
// 		.nullable(true)
// 		// .positive(`Subversion must be a positive number.`)
// 		.min(0, `Subversion must be grater than or equal to 0.`),
// });

export default CitationEntry;
