import React, { useEffect, useState } from "react";
import { Button, Popup, Transition } from "semantic-ui-react";

import { useModalWithContext } from "../../layouts/ModalWithContext";
import FeedbackForm from "./FeedbackForm";
import { useAuth } from "../../providers/AuthProvider";

const transitions = [
	"jiggle",
	"flash",
	"shake",
	"pulse",
	"tada",
	"bounce",
	"glow",
];

export const FeedbackButton = () => {
	const { user } = useAuth();

	const { modalDispatcher } = useModalWithContext();

	// const [shake, setShake] = useState(false);
	const [animation] = useState(transitions[0]);
	const [fontsize, setFontsize] = useState(1.4);
	const [numOfTimes] = useState(10);
	const [duration] = useState(500);
	const [visible, setvisible] = useState(true);
	const [shake, setShake] = useState(false);
	const [shakeCount, setShakeCount] = useState(0);
	const [reintervalIds, setreintervalIds] = useState<any[]>([]);
	const [subfrequecncy] = useState(1100);
	const [mainFrequecncy] = useState(
		parseInt(process.env.REACT_APP_FEEDBACK_ANIMATION_INTERVAL || "120000")
		// 30000
	);

	const triggerShake = () => {
		// if (shakeCount % 2 > 0)
		setFontsize(fontsize + 0.03 * shakeCount);
		setvisible(true);
		setShakeCount(shakeCount + 1);
		setTimeout(() => {
			setvisible(!visible);
		}, duration); // Reset the state after the animation
	};

	//start the initial triggerShake after every 2 mins
	const startTrigger = () => {
		setShake(true);
		const intervalId = setInterval(triggerShake, subfrequecncy);
		setreintervalIds([...reintervalIds, intervalId]);
	};

	useEffect(() => {
		// Set an interval to do the transition
		const intervalId = setInterval(startTrigger, mainFrequecncy); // 120000 ms = 2 minutes
		return () => {
			clearInterval(intervalId);
			for (const id of reintervalIds) {
				clearInterval(id);
			}
		};
	}, []);

	useEffect(() => {
		// Function to trigger the effect
		if (shakeCount >= numOfTimes) {
			for (const id of reintervalIds) {
				clearInterval(id);
			}
			setreintervalIds([]);
			setShake(false);
			setShakeCount(0);
			setFontsize(1.4);
		} else {
			if (shake && shakeCount < numOfTimes) {
				//call the triggerShake repeatedly for {numOfTimes}
				const intervalId = setInterval(triggerShake, subfrequecncy);
				setreintervalIds([...reintervalIds, intervalId]);
			}
		}
	}, [shakeCount]);

	const toggleModal = () => {
		modalDispatcher({
			open: true,
			size: "tiny",
			displayType: null,
			options: {
				title: "ESVD Feedback & Feature Request Form",
				body: <FeedbackForm formDispatcher={modalDispatcher} />,
				closeOnDimmerClick: false,
			},
		});
	};

	return user ? (
		<Popup
			content="Click to provide feedback."
			trigger={
				<Transition animation={animation} duration={duration} visible={visible}>
					<Button
						onClick={toggleModal}
						className="fixed-button"
						// size="huge"
						circular
						icon="chat"
						style={{
							backgroundColor: "rgb(179, 190, 98)",
							fontSize: `${fontsize}rem`,
						}}
					/>
				</Transition>
			}
		/>
	) : null;
};
