import { ErrorMessage, Field, Formik } from "formik";
import React, { Fragment } from "react";
import { Form, Label, Card, Grid } from "semantic-ui-react";
import * as Yup from "yup";
import { ErrorHandler } from "../../shared/ExceptionHandling";
import EsvdInputInline from "../../shared/InputInline";
import NumberInputInline from "../../shared/InputInlineNumber";
import { initReferenceInfo } from "../../shared/Literals";
import SelectDropdown from "../../shared/SelectDropdown";
import { ReferenceInfo } from "../../models/ReferenceInfo";
import { useReferenceInfo } from "../../providers/ReferenceInfoProvider";
import { getMonthList } from "../../EsvdFunctions";
import { TreeType } from "../../models/ecosystemService";

const VersionEntry = () => {
	const formikRef = React.useRef<any>();
	const { saveReferenceInfo, getCurrentVersionInfo, currentInfo } =
		useReferenceInfo();
	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [referenceInfo] = React.useState<ReferenceInfo>(initReferenceInfo);
	const [monthId, setMonthId] = React.useState<any>(new Date().getMonth());

	const clearReferenceInfo = () => {
		formikRef.current?.resetForm();
		setMonthId(new Date().getMonth());
	};

	const renderButtons = (values: any) => {
		return (
			<Fragment>
				<Grid>
					<Grid.Column width={4}>
						<button
							className="ui button btn btn-primary"
							type="reset"
							style={{
								marginTop: "1rem",
								backgroundColor: "#70bbfd",

								height: "2rem",
								width: "4rem",
							}}
							onClick={() => clearReferenceInfo()}>
							Clear
						</button>
					</Grid.Column>
					<Grid.Column width={12}>
						<div className="ui right floated">
							<button
								className="ui button btn btn-primary"
								type="submit"
								style={{
									marginTop: "1rem",
									backgroundColor: "#70bbfd",
									display: "block !important",
									height: "2rem",
									width: "4rem",
									marginRight: "2rem",
								}}>
								<span className={`${isLoading ? "hide" : ""}`}>Save</span>
								<span
									className={`box loader-01 ${
										isLoading ? "" : "invisible"
									}`}></span>
							</button>
						</div>
					</Grid.Column>
				</Grid>
			</Fragment>
		);
	};
	const renderContent = () => {
		return (
			<>
				<h5>Current Database Version: {currentInfo?.version}</h5>
				<br />
				<Formik
					innerRef={formikRef}
					enableReinitialize={true}
					validationSchema={validationSchema}
					initialValues={referenceInfo}
					onSubmit={(values, { setErrors, resetForm }) => {
						handleFormSubmit(values, setErrors, resetForm);
					}}>
					{({
						values,
						// handleChange,
						handleSubmit,
						isValid,
						isSubmitting,
						dirty,
						errors,
					}) => (
						<Form
							className="custom-ui-element"
							onSubmit={handleSubmit}
							autoComplete={"off"}>
							<div style={{ display: "none" }}>
								<EsvdInputInline name="id" type="hidden" />
							</div>
							<Field
								name="month"
								label="Month"
								options={getMonthList()}
								component={SelectDropdown}
								value={monthId}
								placeholder="Month"
								onChange={(value: TreeType) => {
									setMonthId(value);
								}}
							/>
							<NumberInputInline
								className="number"
								name="year"
								label="Year"
								placeholder="Year"
								min={1950}
							/>
							<NumberInputInline
								className="number"
								name="version"
								label="Version"
								placeholder="Version"
								min={1}
							/>
							<NumberInputInline
								className="number"
								name="subversion"
								label="Subversion"
								placeholder="Subversion"
								min={0}
							/>
							{errors.error && (
								<Label
									style={{ marginBottom: 5, borderColor: "none" }}
									basic
									color="red"
									size="tiny"
									content={errors.error}
								/>
							)}
							{renderButtons(values)}
						</Form>
					)}
				</Formik>
			</>
		);
	};

	const handleFormSubmit: Function = (
		values: ReferenceInfo,
		setErrors: Function,
		resetForm: Function
	) => {
		setIsLoading(true);
		values.subversion = values.subversion || 0;
		values.fromCitation = false;
		saveReferenceInfo(values)
			.then((res: any) => {
				clearReferenceInfo();
				setIsLoading(false);
				getCurrentVersionInfo();
			})

			.catch((error: any) => {
				setErrors({ error: ErrorHandler(error) });
				setIsLoading(false);
			});
	};

	return (
		<Card fluid>
			<Card.Content>
				<h4 className="title-card">{"Database Version"}</h4>
			</Card.Content>

			<Card.Content>{renderContent()}</Card.Content>
		</Card>
	);
};
const validationSchema: any = Yup.object({
	["year"]: Yup.number()
		.typeError("Year must be a number")
		.required("Year is required")
		.positive(`Year must be a positive number.`)
		.min(1950, `Year must be grater than or equal to 1950.`)
		.test(
			"len",
			`Year is not a valid year.`,
			(val: any) => !val || (val && val.toString().length === 4)
		),
	["version"]: Yup.number()
		// .nullable(false)
		.typeError("Version is required")
		.required("Version is required")
		.positive(`Version must be a positive number.`)
		.min(1, `Version must be grater than or equal to 1.`),
	["subversion"]: Yup.number()
		.nullable(true)
		// .positive(`Subversion must be a positive number.`)
		.min(0, `Subversion must be grater than or equal to 0.`),
});

export default VersionEntry;
