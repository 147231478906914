import React, { useEffect, useState } from "react";
import Table, { AutoResizer } from "react-base-table";
import "react-base-table/styles.css";
import { useAuth } from "../../providers/AuthProvider";
import { User } from "../../models/user";
import { userProfileGridHeaderColumns } from "../../tests/data";
import moment from "moment";
import { Card, Dimmer, Header, Loader } from "semantic-ui-react";
import UserProfilesButton from "./UserProfilesButton";
import { sortReactTableData } from "../../EsvdFunctions";
import LoadingOverlay, { LoadingButton } from "../../shared/LoadingOverlay";

const UserProfileTable = () => {
	const { getUsers } = useAuth();
	const [users, setUsers] = useState<User[]>([]);
	const [isDescend, setisDescend] = useState<boolean>(false);
	const [isLoading, setisLoading] = useState<boolean>(false);
	const [sortBy, setsortBy] = useState<any>({
		key: "registrationDate",
		order: "asc",
	});
	const [bodyArray, setBodyArray] = useState<any[]>([]);

	useEffect(() => {
		setisLoading(true);
		(async () => {
			var filters = await getUsers({
				params: {
					sortColumn: sortBy.key,
					isDescend: sortBy.order === "asc" ? false : true,
				},
			});
			const items = filters.map((user: User, i: number) => {
				user.id = `${i + 1}`;
				user.registrationDate = moment("01-Jan-0001")
					? moment(user.registrationDate).format("D-MMM-yyyy HH:mm:ss")
					: "";
				user.lastSignInDate =
					moment(user.lastSignInDate) > moment("01-Jan-0001")
						? moment(user.lastSignInDate).format("D-MMM-yyyy HH:mm:ss")
						: "";
				if (user.sector && user.sector.toLowerCase() === "other") {
					user.sector = `${user.sectorOther}`;
				}
				if (
					user.purposeOfAccess &&
					user.purposeOfAccess.toLowerCase() === "other"
				) {
					user.purposeOfAccess = `${user.purposeOfAccessOther}`;
				}
				return user;
			});
			setUsers(items);
			setBodyArray(items);
			setisLoading(false);
		})();

		return () => {};
	}, []);

	useEffect(() => {
		return () => {};
	}, [users]);

	useEffect(() => {
		//sort the items without returning to the server since all users are loaded at page load
		const { key, order } = sortBy;
		setisDescend(order === "asc" ? false : true);
		const sorted = sortReactTableData(isDescend, users, key);
		setUsers(sorted);
		setBodyArray(sorted);
		return () => {};
	}, [sortBy]);
	const onColumnSort = async (sort: any) => {
		setsortBy(sort);
	};

	return (
		<>
			<Card
				fluid
				style={{
					height: "91vh",
					marginLeft: "1em",
					marginRight: "1em",
				}}>
				<Card.Content>
					<h4
						// as="h4"
						className="title-card"
						// textAlign="center"
						style={{ display: "inline-block" }}>
						User Profiles
					</h4>
					<h4 className="ui right floated title-card">
						<UserProfilesButton sortBy={sortBy} />
					</h4>
				</Card.Content>

				<div className="BaseContainer">
					<LoadingOverlay active={isLoading} text="Loading users" />
					<AutoResizer>
						{({ width, height }) => (
							<Table
								fixed
								width={width}
								height={height + 10}
								columns={userProfileGridHeaderColumns}
								data={bodyArray}
								sortBy={sortBy}
								rowHeight={30}
								onColumnSort={onColumnSort}
							/>
						)}
					</AutoResizer>
				</div>
			</Card>
		</>
	);
};
export default UserProfileTable;
