import { useEffect } from "react";
import { Grid, Container } from "semantic-ui-react";
import { resetEsvds } from "../../actions/esvdActions";
import EsvdGetStartedPanel from "./EsvdGetStartedPanel";
import MediaLayout from "../../layouts/MediaLayout";
import {
	EsvdValuationProvider,
	useEsvdValuation,
} from "../../providers/EsvdValuationProvider";
import EsvdDataReadonlyWrapper from "./EsvdDataReadonlyWrapper";
import { ListsProvider } from "../../providers/ListsProvider";
import { ModalWithContextProvider } from "../../layouts/ModalWithContext";
const { Media, MediaContextProvider } = MediaLayout;

const EsvdDatabaseHomeReadonly = () => {
	const { getEsvdTotalCount, getEsvdTotalMapCount } = useEsvdValuation();

	useEffect(() => {
		resetEsvds();
		(async () => {
			await getEsvdTotalCount();
			await getEsvdTotalMapCount();
		})();
		return () => {
			// clearFilter();
		};
	}, []);

	const renderContent = () => {
		return (
			<MediaContextProvider>
				<Media at="mobile" className="page-content">
					<EsvdGetStartedPanel />
					{/* <EsvdFilterPanel /> */}
					<EsvdDataReadonlyWrapper />
				</Media>
				<Media greaterThan="mobile">
					<Container fluid className="top-container">
						<Grid className="page-content" stackable>
							<Grid.Row>
								<Grid.Column stretched>
									<EsvdGetStartedPanel />
									<EsvdDataReadonlyWrapper />
								</Grid.Column>
							</Grid.Row>
						</Grid>
					</Container>
				</Media>
			</MediaContextProvider>
		);
	};

	return renderContent();
};

const EsvdDatabaseHomeReadonlyWrapper = (props: any) => {
	return (
		<EsvdValuationProvider>
			<ModalWithContextProvider>
				<ListsProvider>
					<EsvdDatabaseHomeReadonly />
				</ListsProvider>
			</ModalWithContextProvider>
		</EsvdValuationProvider>
	);
};

export default EsvdDatabaseHomeReadonlyWrapper;
