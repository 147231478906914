import React, { useEffect } from "react";

import { UserLoginMode } from "../../models/user";
import LoginsContainer from "./LoginsContainer";
import UsernameOrEmail from "./UsernameOrEmail";
import Login from "./Login";
import LoginWithCode from "./LoginWithCode";

interface IProps {
	match: any;
}

const LoginWrapper = (props: IProps) => {
	const [userLoginMode, setUserLoginMode] =
		React.useState<UserLoginMode | null>(null);

	useEffect(() => {
		return () => {};
	}, []);

	const renderContent = () => {
		if (!userLoginMode) {
			return <UsernameOrEmail setUserLoginMode={setUserLoginMode} />;
		} else {
			return userLoginMode.userExists && userLoginMode.isUsername ? (
				<Login
					match={props.match}
					username={userLoginMode.usernameOrEmail || ""}
					setUserLoginMode={setUserLoginMode}
				/>
			) : (
				<LoginWithCode
					match={props.match}
					email={userLoginMode.usernameOrEmail || ""}
					setUserLoginMode={setUserLoginMode}
				/>
			);
		}
		// if (props.match.params) {
		// 	const { params } = props.match;
		// 	if (Object.keys(params).length === 0) {
		// 		return <Redirect to="/" />;
		// 	} else {
		// 		return <Redirect to={`/${params.returnUrl}`} />;
		// 	}
		// } else {
		// 	//a login user shouldn't see the login page.
		// 	//Therefore is a logged user access the login route, go to home
		// 	// history.push("/");
		// 	return <Redirect to="/" />;
		// }
	};
	// render() {
	return <LoginsContainer>{renderContent()}</LoginsContainer>;
	// }
};

export default LoginWrapper;
