import React, { useEffect, useState } from "react";
import {
	Button,
	Card,
	Form,
	Input,
	List,
	Icon,
	Pagination,
} from "semantic-ui-react";
import { FieldArray, Formik } from "formik";

import {
	ModalWithContextProvider,
	useModalWithContext,
} from "../../layouts/ModalWithContext";
import { useUsers } from "../../providers/UserProvider";
import { User } from "../../models/user";
// import { offset } from "handsontable/helpers/dom";

export const EarlyBirdSelection = ({
	formDispatcher,
}: {
	formDispatcher: Function | undefined;
}) => {
	//use a separate dispacher for the modal that will show upon success or failure of feedback submission.
	//This way, the feedback form will not be affected by the closing of the that modal, since it has a different dispatch, formDispatcher
	const {
		getNonEarlyBirdUsers,

		addUserToEarlybird,
	} = useUsers();

	const closeModal = () => {
		formDispatcher && formDispatcher({ open: false });
	};

	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [limit] = React.useState<number>(15);
	const [currentPage, setCurrentPage] = useState<string | number>(0);
	const [ebUsers, setEbUsers] = React.useState<User[]>([]);
	const [ebUsersCount, setEbUsersCount] = React.useState<number>(0);
	const [freeText, setFreeText] = useState("");

	useEffect(() => {
		(async () => {
			setIsLoading(true);
			const dat = await getNonEarlyBirdUsers({
				params: { offset: currentPage, limit: limit },
			});
			dat && setEbUsersCount(dat.totalRecords);
			dat && setEbUsers(dat.users);
			setIsLoading(false);
		})();
	}, []);

	useEffect(() => {
		(async () => {
			setIsLoading(true);
			const dat = await getNonEarlyBirdUsers({
				params: { offset: currentPage, limit: limit },
			});
			dat && setEbUsersCount(dat.totalRecords);
			dat && setEbUsers(dat.users);
			setIsLoading(false);
		})();
	}, [currentPage]);

	useEffect(() => {
		(async () => {
			setIsLoading(true);
			const dat = await getNonEarlyBirdUsers({
				params: { offset: currentPage, limit: limit, freeText },
			});
			dat && setEbUsersCount(dat.totalRecords);
			dat && setEbUsers(dat.users);
			setIsLoading(false);
		})();
	}, [freeText]);

	const searchUsers = (e: any) => {
		setFreeText(e.target.value);
	};

	const addUser = async (user: User) => {
		try {
			setIsLoading(true);
			await addUserToEarlybird(user.id);
			user.id = null;
		} catch (error) {
			console.log(error);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<Card.Content style={{ overflowY: "auto", height: "100%" }}>
			{/* <div style={{ height: "95%" }} className="custom-ui-element"> */}
			{/* <Card fluid style={{ height: "100%" }}> */}
			{/* <Card.Content> */}
			{/* <div style={{ padding: "1em 2em" }}> */}
			<>
				{/* <h5>
					Search users by name, email or display name to add to early bird
				</h5> */}
				<Input
					onChange={(e: any) => searchUsers(e)}
					icon="search"
					placeholder="Search users by name, email or display name..."
					fluid
					loading={isLoading}
				/>
				<List divided verticalAlign="middle" size="tiny">
					{ebUsers &&
						ebUsers.map((user, i) => {
							return (
								<List.Item
									key={i}
									style={{
										display: `${user.id ? "block" : "none"}`,
									}}>
									<List.Content verticalAlign="middle">
										<Button
											icon
											size="mini"
											floated="right"
											onClick={async () => user.id && (await addUser(user))}>
											<Icon name="add" size="small" />
										</Button>
										<List.Header>{user.userName}</List.Header>
										<List.Description>{user.email}</List.Description>
									</List.Content>
								</List.Item>
							);
						})}
				</List>
			</>
			{/* </div> */}
			{/* </Card.Content>
			</Card> */}
			{/* </div> */}
			<Pagination
				activePage={currentPage}
				boundaryRange={1}
				onPageChange={(e, { activePage }) => {
					setCurrentPage(activePage || 1);
				}}
				size="mini"
				siblingRange={1}
				totalPages={Math.ceil(ebUsersCount / limit)}
				// ellipsisItem={true}
				// firstItem={true}
				// lastItem={true}
				prevItem={true}
				nextItem={true}
			/>{" "}
			<Button
				className="btn btn-feedback btn-warning"
				floated="right"
				size="tiny"
				style={{
					marginLeft: "2.5em",
					minWidth: "4em",
					backgroundColor: "#f6da6e",
				}}
				onClick={closeModal}>
				Close
			</Button>
		</Card.Content>
	);
};

const EarlyBirdSelectionWrapper = ({
	formDispatcher,
	setRefresh,
}: {
	formDispatcher: Function | undefined;
	setRefresh: Function;
}) => {
	useEffect(() => {
		return () => {
			setRefresh(true);
		};
	});
	return (
		<ModalWithContextProvider>
			<EarlyBirdSelection formDispatcher={formDispatcher} />
		</ModalWithContextProvider>
	);
};

export default EarlyBirdSelectionWrapper;
