import React, { useEffect, useState } from "react";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import { useEsvdValuation } from "../../providers/EsvdValuationProvider";
import LoadingOverlay from "../../shared/LoadingOverlay";

import EsvdMapMarker from "../esvdPanel/EsvdMapMarker";
import { Menu, Segment, Sidebar } from "semantic-ui-react";
import { SidebarProvider, useSidebar } from "../../hooks/SidebarHook";
import { Esvd } from "../../models/esvd";

const containerStyle = {
	width: "100%",
	height: "70vH",
	// height: "90vH",
};

const center = {
	lat: -3.745,
	lng: -38.523,
};
const EsvdReadonlyMaps = () => {
	// const { isMapBusy, setIsMapBusy } = useEsvdValuation();
	const { studyEsvds } = useEsvdValuation();

	const { isLoaded } = useJsApiLoader({
		id: "google-map-script",
		googleMapsApiKey: process.env.REACT_APP_MAP_API_KEY || "",
	});

	const [valuations, setValuations] = useState<Esvd[]>([]);
	const [loading, setLoading] = useState<boolean>(true);

	const [mapDats, setMapDats] = React.useState<any[]>([]);

	useEffect(() => {
		setLoading(true);
	}, []);

	useEffect(() => {
		setLoading(true);
		if (studyEsvds) {
			setValuations(studyEsvds);
		} else {
			setValuations([]);
		}
	}, [studyEsvds]);

	const groupDups = () => {
		const marks: any = {};
		valuations.forEach((mark) => {
			const pos = `${mark.latitude}:${mark.longitude}`;
			if (marks[pos]) {
				marks[pos].valueIds.push(mark.valueId);
				marks[pos].locations.add(mark.studyLocation);
			} else {
				marks[pos] = {
					position: { lat: Number(mark.latitude), lng: Number(mark.longitude) },
					valueIds: [mark.valueId],
					locations: new Set([mark.studyLocation]),
				};
			}
		});
		return marks;
	};

	useEffect(() => {
		setLoading(true);
		const dat = groupDups();

		const maks = markers(dat);
		setMapDats(maks);
	}, [valuations]);

	const onUnmount = React.useCallback(function callback(map) {
		setMapDats([]);
	}, []);

	const markers = (marks: any) => {
		setLoading(true);
		let mks: any[] = [];
		(async function mars() {
			<>
				{
					(mks = Object.entries(marks).map(([key, value], i) => (
						<EsvdMapMarker key={i} markerData={value} />
					)))
				}
			</>;
		})();
		setTimeout(() => {
			setLoading(false);
		}, 1000);
		return mks;
	};

	return isLoaded ? (
		<GoogleMap
			mapContainerStyle={containerStyle}
			center={center}
			zoom={2.5}
			options={{
				minZoom: 2.5,
				maxZoom: 18,
				restriction: {
					latLngBounds: {
						north: 85,
						south: -85,
						west: -180,
						east: 180,
					},
				},
			}}
			onUnmount={onUnmount}>
			<>
				<LoadingOverlay active={loading} />
				{valuations.length > 0 && mapDats}
			</>
		</GoogleMap>
	) : (
		<></>
	);
};

export const SidebarPanel = (props: any) => {
	const { visible, closeSidebar, sidebarContent } = useSidebar();

	return (
		<Sidebar.Pushable as={Segment}>
			<Sidebar
				as={Menu}
				animation="overlay"
				onHide={() => closeSidebar()}
				vertical
				visible={visible}
				width="very wide">
				<Menu.Item>{sidebarContent}</Menu.Item>
			</Sidebar>

			<Sidebar.Pusher>
				<EsvdReadonlyMaps />
			</Sidebar.Pusher>
		</Sidebar.Pushable>
	);
};

const EsvdReadonlyMapsPanel = () => {
	return (
		<SidebarProvider>
			<SidebarPanel />
		</SidebarProvider>
	);
};

export default React.memo(EsvdReadonlyMapsPanel);
