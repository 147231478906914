import React from "react";
// import { Link } from "react-router-dom";
import { Card } from "semantic-ui-react";
import { COMPANY_EMAIL, resources } from "../../shared/Literals";

// interface IProps {
//   esvds: { esvds: Esvd[]; headerArray: any[]; bodyArray: any[] };
// }<IProps>
const AboutESVD = () => {
	// render() {
	// const { esvds } = this.props;

	return (
		<Card fluid>
			<Card.Content>
				<h4 className="title-card">About the ESVD &amp; Registration</h4>
			</Card.Content>
			<Card.Content>
				<p>
					To use the ESVD, you can{" "}
					{/* <Link to={"/signup"}>register for a free account</Link>. */}
					<a className="esvd-hyperlink" href={"/signup"}>
						register for a free account
					</a>
					. By signing up, you are indicating agreement with the Terms of Use
					specified in the card below.
				</p>
				<p>
					You can search the ESVD in the {`“${resources.esvd.name}”`} page using
					a set of drop-down lists and/or free text search. Here you can view
					your search results, generate summary statistics, and download data.
				</p>
				<p>
					The valuations from your search query can be toggled between table
					format and a map. The table provides different relevant variables on
					ecosystem type, ecosystem services, standardized values,
					beneficiaries, valuation methods, location information and
					bibliographic information. Additionally, it highlights whether or not
					a valuation has been checked by an external reviewer.
				</p>
				<p>
					The map shows the valuations based on their point location
					coordinates. Hovering over a valuation pointer on the map shows basic
					information. Clicking on a pointer opens a window to show more
					information on the ecosystems and ecosystem services of your
					valuation(s) of interest. Please note that a map pointer can contain
					multiple valuations from multiple studies and that it can reflect a
					small or large study area.
				</p>
				<p>
					The values included in the ESVD are obtained from reports and academic
					publications. The study sites for which values have been estimated can
					be a single ecosystem type or contain multiple different types of
					ecosystem. Similarly, value estimates can be for a single ecosystem
					service or a bundle of multiple ecosystem services. In some cases, it
					is not possible to disaggregate value estimates for multiple ecosystem
					types and/or bundles of services to individual ecosystem types or
					services. We advise to use these value estimates with care in your
					specific research or decision-making context. For more information
					consult our{" "}
					<a
						className="esvd-hyperlink"
						href="https://esvd.info"
						target="_blank"
						rel="noopener noreferrer">
						info website
					</a>{" "}
					or send us an{" "}
					<a className="esvd-hyperlink" href={`mailto:${COMPANY_EMAIL}`}>
						email
					</a>
					.
				</p>
				Notes on using the ESVD:
				<ul>
					<li>
						Where possible, value records are standardised to a common set of
						units (International dollars/hectare/year in 2020 price levels for
						all relevant beneficiaries of the service).
					</li>
					<li>
						Some value records cannot be standardised to a common set of units
						due to missing information but nevertheless contain potentially
						useful information for users. For this reason, some search results
						may contain data but do not contain standardised values. Only
						standardised values are included in the summary statistics.
					</li>
					<li>
						The data is under an ongoing review process to check that the
						interpretation and coding of study results are correct but not all
						data have been reviewed yet. The review status of each value record
						is indicated in the data.
					</li>
				</ul>
			</Card.Content>
		</Card>
	);
	// }
};

export default AboutESVD;
