import moment from "moment";
import {
  ColumnSpecs,
  ColumnStructure,
  DisplayColumnStructure,
  Spec,
  SummaryStatsColumnStructure,
} from "./models/columnStructure";
import { TreeType } from "./models/ecosystemService";
import { Esvd } from "./models/esvd";
import { HeaderItem } from "./models/headerStructure";

export const saveCurrentUrl = (url: string | null = null) => {
  if (url) {
    sessionStorage.setItem("returnUrl", url);
  } else {
    sessionStorage.removeItem("returnUrl");
  }
};

export const getCurrentUrl = () => {
  return sessionStorage.getItem("returnUrl");
};
   
export const displayBodyRowsObjects = (
  esvds: Esvd[],
  columnsDetails: DisplayColumnStructure
) => {
  //build a list of maxlen for multi column properties
  let bodyArray = [];

  for (const esvd of esvds) {
    const esvdObject: any = {};

    for (const item of Object.entries(columnsDetails)) {
      let key = item[0];
      let value = item[1];
      if (value.isMulti) {
        //since it's multi, the maxLen would have been calculated before this step to be used.
        //if max length = 0, then we skip that entry completely
        if (value.maxLen === 0) continue;

        //since each multi item consists of an array of specs (each of which adds a column or series of columns), we loop
        //IMPORTANT: At this point, check the specialTreat property for ecosystemServices
        let maxlen = value.maxLen;
        value.specs.forEach((spec: Spec) => {
          //get basecolumn
          const baseColumnProperty = spec.baseColumn ? spec.baseColumn : key;
          const propertyValue = esvd[baseColumnProperty]; //this will be an array

          maxlen = spec.baseColumn
            ? columnsDetails[spec.baseColumn].maxLen
            : value.maxLen;
          //process the array property
          if (maxlen > 0) {
            const len = propertyValue.length;
            if (!spec.flatten) {
              //get the len of the array
              for (let i = 0; i < maxlen; i++) {
                if (i < len) {
                  let cell = propertyValue[i];
                  esvdObject[`${spec.caption}${i + 1}`] =
                    (cell && cell[spec.dbField || "name"]) || "";
                } else {
                  //Pad up empty columns to avoid displacing the row for this entity
                  esvdObject[`${spec.caption}${i + 1}`] = "";
                }
              }
            } else {
              let flattened = "";
              for (let i = 0; i < len; i++) {
                let cell = propertyValue[i];
                flattened =
                  i === 0
                    ? cell[spec.dbField]
                    : `${flattened}${
                        cell
                          ? `${spec.separator || ";"}${cell[spec.dbField]}`
                          : ""
                      }`;
              }
              esvdObject[spec.caption] = flattened;
            }
          }
        });

        
      } else {
        if (typeof value === "object" && value.isSimple) {
          const spec = value.spec;
          let propertyValue = esvd[spec.dbField];
          if (spec.dbField.toLocaleLowerCase() === "intPerHectarePerYear".toLocaleLowerCase())
          {
            propertyValue = standardValueFormat(propertyValue)
            }          
          esvdObject[spec.caption] =
            spec.isDate && propertyValue
              ? moment(propertyValue).format(spec.isDate.format)
              : propertyValue;
        } else {
          let propertyValue = esvd[key];
          if (key.toLocaleLowerCase() === "intPerHectarePerYear".toLocaleLowerCase())
          {
            propertyValue = standardValueFormat(propertyValue)
            }          
          esvdObject[value] = propertyValue;
        }
      }
    }
    esvdObject.id = esvd.id;

    bodyArray.push(esvdObject);
  }

  return bodyArray;
};

export const bodyRowsFlattened = (
  esvds: Esvd[],
  columnsDetails: ColumnStructure
) => {
  //build a list of maxlen for multi column properties
  let bodyArray = [];

  for (const esvd of esvds) {
    const esvdObject: any = {};

    for (const item of Object.entries(columnsDetails)) {
      let key = item[0];
      let value = item[1];
     if (!value || value.isHide) continue;
      if (value.isMulti) {
        //since it's multi, the maxLen would have been calculated before this step to be used.
        //if max length = 0, then we skip that entry completely
        if (value.maxLen === 0) continue;

        //since each multi item consists of an array of specs (each of which adds a column or series of columns), we loop
        //IMPORTANT: At this point, check the specialTreat property for ecosystemServices
        let maxlen = value.maxLen;
        // value.specs.forEach((spec: Spec) => {
        //get basecolumn
        const spec = value.specs;
        const baseColumnProperty = spec.baseColumn ? spec.baseColumn : key;
        const propertyValue = esvd[baseColumnProperty]; //this will be a flattened string

        //process the array property
        if (maxlen > 0) {
          const propSplit = propertyValue && propertyValue.split(";");
          const len = propSplit && propSplit.length;
          if (!spec.flatten) {
            //get the len of the array
            for (let i = 0; i < maxlen; i++) {
              if (i < len) {
                let cell = propSplit[i];
                esvdObject[`${spec.caption}${i + 1}`] = cell || "";
              } else {
                //Pad up empty columns to avoid displacing the row for this entity
                esvdObject[`${spec.caption}${i + 1}`] = "";
              }
            }
          }
        }
        // });
      } else {
        if (typeof value === "object" && value.isSimple) {
          const spec = value.spec;
          let propertyValue = esvd[spec.dbField];
        //  if (spec.dbField.toLocaleLowerCase() === "intPerHectarePerYear".toLocaleLowerCase())
        //   {
        //     propertyValue = standardValueFormat(propertyValue)
        //     }          
          esvdObject[spec.caption] =
            spec.isDate && propertyValue ? moment(propertyValue).format(spec.isDate.format) : value.extractLen && propertyValue
              ? propertyValue.split(";")[value.extractLen - 1]
              : removeBadChar(propertyValue);
        } else {
          let propertyValue = esvd[key];
 
          esvdObject[value] =removeBadChar(propertyValue)
          if (key === "inclExcl") {
            esvdObject[value] = changeInclExclTo1and0(esvdObject[value])
          }
        }
      }
    }

    bodyArray.push(esvdObject);
  }

  return bodyArray;
};

//This is strictly for the InclExcl column. If other columns need such granular processing, then bodyRowsFlattened
//will have to be modified to accept an object which has columns as keys and the appropriate functions to perform on them as values
function changeInclExclTo1and0(propValue: boolean | null | undefined) {
  //To avoid the confusing Truthy nature of 0, null, undefined and false, convert to string before comparing.
  //0, null, undefined and false: all four items evaluate to false in the Truthy table
  if (`${propValue}` === "false") {
    return 0
  } else if (`${propValue}` === "true") {
    return 1
  } else
    return ""
}

function removeBadChar(propertyValue: any) {
  let item = "";
  if (typeof propertyValue === 'string') {
    item = propertyValue.replace(/"/g, '""');;
  } else {
    item = propertyValue
  }
  return item
}

//get the maxlens of all multi column fields in the result set
export const setMultiColumnMaxLens = (
  esvds: Esvd[],
  columns: ColumnStructure
) => {
  //get columns whose values are arrays
  const arrayOfMultis = Object.entries(columns).filter(([key, value]) => {
    return typeof value === "object" && value !== null && value?.isMulti;
  });

  //then for each column, find the max len of sub-columns
  arrayOfMultis.forEach(([key, value]) => {
    //from the filter above, "entry" is an array of key/value pair: [key, value].
    //The value in this case are arrays which hold the fixed parts of the captions for the sub-columns
    //the key holds the name of the column for which we want to find max len
    const maxlen = maxLen(esvds, key);

    columns = { ...columns, [key]: { ...value, maxLen: maxlen } };
  });
  return columns;
};

//get the maxlens of all multi column fields in the result set
export const setMultiColumnMaxLenFlattened = (
  esvds: Esvd[],
  columns: ColumnStructure
) => {
  //get columns whose values are arrays
  const arrayOfMultis = Object.entries(columns).filter(([key, value]) => {
    return typeof value === "object" && value !== null && value?.isMulti;
  });

  
  //then for each column, find the max len of sub-columns
  arrayOfMultis.forEach(([key, value]) => {
    //from the filter above, "entry" is an array of key/value pair: [key, value].
    //The value in this case are arrays which hold the fixed parts of the captions for the sub-columns
    //the key holds the name of the column for which we want to find max len
    const maxlen = maxLenFlattened(esvds, value.specs.baseColumn);

    columns[key]= { ...value, maxLen: maxlen } ;
  });
  return columns;
};

//get the maxlens of all multi column fields in the result set
export const setDisplayMultiColumnMaxLens = (
  esvds: Esvd[],
  columns: DisplayColumnStructure
) => {
  //get columns whose values are arrays
  const arrayOfMultis = Object.entries(columns).filter(([key, value]) => {
    return typeof value === "object" && value !== null && value?.isMulti;
  });

  //then for each column, find the max len of sub-columns
  arrayOfMultis.forEach(([key, value]) => {
    //from the filter above, "entry" is an array of key/value pair: [key, value].
    //The value in this case are arrays which hold the fixed parts of the captions for the sub-columns
    //the key holds the name of the column for which we want to find max len
    const maxlen = maxLen(esvds, key);

    columns = { ...columns, [key]: { ...value, maxLen: maxlen } };
  });
  return columns;
};

export const maxLen = (esvds: Esvd[], key: string) => {
  let evsLens: number[] = esvds.map((e) => e[key].length); //Extracts an array of length of lists of only the specified key for all the records
  return Math.max(...evsLens, 0);
};

export const maxLenFlattened = (esvds: Esvd[], key: string) => {
  let evsLens: number[] = esvds.map((e) =>
    e[key] ? e[key].split(";").length : 0
  ); //Extracts an array of length of lists of only the specified key for all the records
  return Math.max(...evsLens, 0);
};
//Whether the list associated with this field would be flattened into one column or spread into multiple columns.
export const isMultiColumn = (item: ColumnSpecs) => {
  return item.isMulti;
};

export const headerTransform = (
  esvds: Esvd[],
  columnsDetails: ColumnStructure,
  sortable: boolean = true
): any[] => {
  //build a list of maxlen for multi column properties
  let headerArray: HeaderItem[] = [];

  for (const item of Object.entries(columnsDetails)) {
    // const key = item[0];
    const value = item[1];
    if (value.isMulti) {
      //since it's multi, the maxLen would have been calculated before this step to be used.
      //if max length = 0, then we skip that entry completely
      if (value.maxLen === 0) continue;

      //since each multi item consists of an array of specs (each of which adds a column or series of columns), we loop
      //IMPORTANT: At this point, check the specialTreat property for ecosystemServices
      let maxlen = value.maxLen;
      value.specs.forEach((spec: Spec) => {
        maxlen = spec.baseColumn
          ? columnsDetails[spec.baseColumn].maxLen
          : value.maxLen;

        if (maxlen > 0) {
          if (spec.flatten) {
            const headObject: HeaderItem = {
              key: spec.caption,
              title: spec.caption,
              dataKey: spec.caption,
              width: 100,
              resizable: true,
              sortable: spec.sortable || false,
            };
            headerArray.push(headObject);
          } else {
            for (let i = 0; i < maxlen; i++) {
              const headObject: HeaderItem = {
                key: `${spec.caption}${i + 1}`,
                title: `${spec.caption}${i + 1}`,
                dataKey: `${spec.caption}${i + 1}`,
                width: 100,
                resizable: true,
                sortable: spec.sortable || false,
              };
              headerArray.push(headObject);
            }
          }
        }
      });
    } else {
      if (value.spec && value.isSimple) {
        const spec = value.spec;
        const headObject: HeaderItem = {
          key: spec.caption,
          title: spec.caption,
          dataKey: spec.caption,
          width: 100,
          resizable: true,
          sortable: spec.sortable || false,
          
        };
        headerArray.push(headObject);
      } else {
        const headObject: HeaderItem = {
          key: value,
          title: value,
          dataKey: value,
          width: 100,
          resizable: true,
        };
        headerArray.push(headObject);
      }
    }
  }
  // if (sortable) {
  //   if (headerArray) {
  //     for (let i = 0; i < 2; i++) {
  //       headerArray[i].sortable = true;
  //     }
  //   }
  // }

  return headerArray;
};

export const headerTransformFlattened = (
  columnsDetails: ColumnStructure
): any[] => {
  //build a list of maxlen for multi column properties
  let headerArray: HeaderItem[] = [];

  for (const item of Object.entries(columnsDetails)) {
    // const key = item[0];
    const value = item[1];
    if (value.isHide) continue;
    if (value.isMulti) {
      //since it's multi, the maxLen would have been calculated before this step to be used.
      //if max length = 0, then we skip that entry completely
      if (value.maxLen === 0) continue;

      //since each multi item consists of an array of specs (each of which adds a column or series of columns), we loop
      //IMPORTANT: At this point, check the specialTreat property for ecosystemServices
      let maxlen = value.maxLen;
      value.specs.forEach((spec: Spec) => {
        if (maxlen > 0) {
          for (let i = 0; i < maxlen; i++) {
            const headObject: HeaderItem = {
              key: `${spec.caption}${i + 1}`,
              title: `${spec.caption}${i + 1}`,
              dataKey: `${spec.caption}${i + 1}`,
              width: 100,
              resizable: true,
              sortable: spec.sortable || false, 
              // align:value.Alignment||'left'
            };
            headerArray.push(headObject);
          }
        }
      });
    } else {
      if (value.spec && value.isSimple) {
        const spec = value.spec;
        const headObject: HeaderItem = {
          key: spec.caption,
          title: spec.caption,
          dataKey: spec.caption,
          width: 100,
          resizable: true,
          sortable: spec.sortable || false,
          // align:value.Alignment||'left'
        };
        headerArray.push(headObject);
      } else {
        const headObject: HeaderItem = {
          key: value,
          title: value,
          dataKey: value,
          width: 100,
          resizable: true,
          // align:value.Alignment||'left'
        };
        headerArray.push(headObject);
      }
    }
  }
  // if (sortable) {
  //   if (headerArray) {
  //     for (let i = 0; i < 2; i++) {
  //       headerArray[i].sortable = true;
  //     }
  //   }
  // }

  return headerArray;
};

export const displayHeaderTransform = (
  esvds: Esvd[],
  columnsDetails: DisplayColumnStructure,
  sortable: boolean = true
): any[] => {
  //build a list of maxlen for multi column properties
  let headerArray: HeaderItem[] = [];

  for (const item of Object.entries(columnsDetails)) {
    // const key = item[0];
    const value = item[1];
    if (value.isMulti) {
      //since it's multi, the maxLen would have been calculated before this step to be used.
      //if max length = 0, then we skip that entry completely
      if (value.maxLen === 0) continue;

      //since each multi item consists of an array of specs (each of which adds a column or series of columns), we loop
      //IMPORTANT: At this point, check the specialTreat property for ecosystemServices
      let maxlen = value.maxLen;
      value.specs.forEach((spec: Spec) => {
        maxlen = spec.baseColumn
          ? columnsDetails[spec.baseColumn].maxLen
          : value.maxLen;

        if (maxlen > 0) {
          if (spec.flatten) {
            const headObject: HeaderItem = {
              key: spec.dbField,
              title: spec.caption,
              dataKey: spec.caption,
              width: spec.width || 100,
              resizable: true,
              sortable: (sortable && spec.sortable) || false,
              align:spec.align ||'left'
            };
            headerArray.push(headObject);
          } else {
            for (let i = 0; i < maxlen; i++) {
              const headObject: HeaderItem = {
                key: `${spec.dbField}${i + 1}`,
                title: `${spec.caption}${i + 1}`,
                dataKey: `${spec.caption}${i + 1}`,
                width: spec.width || 100,
                resizable: true,
                sortable: (sortable && spec.sortable) || false,
                align:spec.align ||'left'
              };
              headerArray.push(headObject);
            }
          }
        }
      });
    } else {
      if (value.spec && value.isSimple) {
        const spec = value.spec;
        const headObject: HeaderItem = {
          key: spec.dbField,
          title: spec.caption,
          dataKey: spec.caption,
          width: spec.width || 100,
          resizable: true,
          sortable: (sortable && spec.sortable) || false,
          align:spec.align ||'left'
        };
        headerArray.push(headObject);
      } else {
        const headObject: HeaderItem = {
          key: value,
          title: value,
          dataKey: value,
          width: 150,
          resizable: true,
          // align:spec.align ||'left'
        };
        headerArray.push(headObject);
      }
    }
  }
  // if (sortable) {
  //   if (headerArray) {
  //     for (let i = 0; i < 2; i++) {
  //       headerArray[i].sortable = true;
  //     }
  //   }
  // }

  return headerArray;
};

export const vwTOpx = (value: number) => {
  //https://jsfiddle.net/Dwaaren/j9zahaLL/
  var w = window,
    d = document,
    e = d.documentElement,
    g = d.getElementsByTagName("body")[0],
    x = w.innerWidth || e.clientWidth || g.clientWidth;
  // y = w.innerHeight || e.clientHeight || g.clientHeight;

  var result = (x * value) / 100;
  // document.getElementById("result_vw_px").innerHTML = result; // affichage du r�sultat (facultatif)
  return result;
};

//ecosystemServices items
export const formatESForDropdown = (ess: TreeType[]) => {
  // //get header classifications
  // const headers = ess.filter(
  //   (x) => !x.isSub && x.code === null && x.sortOrder === null
  // );
  //get main es
  if (!ess)
    return 
  const mains = ess
    .filter((x) => !x.isSub && x.code !== null && x.sortOrder === 1)
    .sort((a, b) => parseInt(a.code) - parseInt(b.code));
  // .sort((a, b) => (a.path > b.path ? 1 : -1));

  let masterList = [];
  for (const es of mains) {
    let subList = [];
    //add main to master list
    subList.push(es);

    //get subs of the mains
    const subs = ess
      .filter((x) => x.isSub && x.path.startsWith(`${es.path}/`))
      .sort((a, b) => (a.path > b.path ? 1 : -1));
    subList.push(...subs);
    //disable sublist without code
    subList = subList.map((x, index) => {
      x.isDisabled = !x.code ? true : false;
      return x;
    });

    masterList.push({
      label: `${es.code} -- ${es.label}`,
      options: subList,
    });
  }
  return masterList;
};

//Cices items
export const formatCicesForDropdown = (ess: TreeType[]) => {
  // //get header classifications
  // const headers = ess.filter(
  //   (x) => !x.isSub && x.code === null && x.sortOrder === null
  // );

  let masterList = [];
  //get sections es
  const sections = (ess||[])
    .filter((x) => x.sortOrder === 1)
    .sort((a, b) => parseInt(a.code) - parseInt(b.code));

  for (const section of sections) {
    //get divisions es
    const divisions = ess
      .filter((x) => x.sortOrder === 2 && x.path.startsWith(`${section.path}/`))
      .sort((a, b) => (a.code > b.code ? 1 : -1));
    let subList = [];
    section.isDisabled = true;
    // section.code = "--".repeat(section.sortOrder - 1);
    // section.label = "--".repeat(section.sortOrder) + section.label;
    subList.push(section);
    for (const division of divisions) {
      //disable this option so it cannot be selected

      //get groups es
      const groups = ess
        .filter(
          (x) => x.sortOrder === 3 && x.path.startsWith(`${division.path}/`)
        )
        .sort((a, b) => (a.code > b.code ? 1 : -1));

      division.isDisabled = true;
      // division.code = "--".repeat(division.sortOrder - 1);
      // division.label = "--".repeat(division.sortOrder) + division.label;
      subList.push(division);

      for (const group of groups) {
        group.isDisabled = true;
        // group.code = "--".repeat(group.sortOrder);
        subList.push(group);
        const classs = ess
          .filter(
            (x) => x.sortOrder === 4 && x.path.startsWith(`${group.path}/`)
          )
          .sort((a, b) => (a.code > b.code ? 1 : -1));
        subList.push(...classs);

        // for (const es of mains) {
        //   let subList = [];
        //   //add main to master list
        //   subList.push(es);
        //   //get subs of the mains
        //   const subs = ess
        //     .filter((x) => x.isSub && x.path.startsWith(`${es.path}/`))
        //     .sort((a, b) => (a.path > b.path ? 1 : -1));
        //   subList.push(...subs);
        //   masterList.push({
        //     label: `${es.code} -- ${es.label}`,
        //     options: subList,
        //   });
        // }
      }
    }
    masterList.push({
      label: `${section.code} -- ${section.label}`,
      options: subList,
    });
  }

  return masterList;
};

export const buildTreeTypeQueryList = (
  selected: number[],
  list: TreeType[]
): number[] => {
  //
  //use sets to keep the list unique
  let filter = new Set<number>();
  for (const item of selected) {
    //
    // !filter.has(item) && 
    filter.add(item);
    const es = list.find((x) => x.id === item);
    if (es && !es?.isSub) {
      //if it's main, get all subdomain under this main and add them
      list
        .filter((x) => x.path.startsWith(`${es.path}/`) && x.id !== es.id)
        .forEach((a) => !filter.has(a.id) && filter.add(a.id));
    }
  }
  return Array.from<number>(filter.values());
};

// export const buildTreeTypeQueryListCices = (
//   selected: number[],
//   list: TreeType[]
// ): number[] => {
//   //
//   //use sets to keep the list unique
//   let filter = new Set<number>();
//   for (const item of selected) {
//     //
//     !filter.has(item) && filter.add(item);
//     const es = list.find((x) => x.id === item);
//     if (es && !es?.isSub) {
//       //if it's main, get all subdomain under this main and add them
//       list
//         .filter((x) => x.path.startsWith(`${es.path}/`) && x.id !== es.id)
//         .forEach((a) => !filter.has(a.id) && filter.add(a.id));
//     }
//   }
//   return Array.from<number>(filter.values());
// };

// /**
//  *https://stackoverflow.com/a/14480366/5367889
//  * @param string : the string to search from.
//  * @param searchString :the string being searched
//  * @param nthPositionOfOccurrence :the nth position of occurence being sought: 1=first occurrence of the searchString, 2=second occurrence of the searchString etc
//  * @returns
//  */
// const getNthPositionOfOccurrence = (
//   string: string,
//   searchString: string,
//   nthPositionOfOccurrence: number
// ) => {
//   return string.split(searchString, nthPositionOfOccurrence).join(searchString)
//     .length;
// };

export const summaryStatsRowsObjects = (
  esvds: Esvd[],
  columnsDetails: SummaryStatsColumnStructure
) => {
  //build a list of maxlen for multi column properties
  let bodyArray:SummaryStatsColumnStructure[] = [];

  for (const esvd of esvds) {
    const esvdObject: any = {};

    for (const item of Object.entries(columnsDetails)) {
      let key = item[0];
      let value = item[1];
      if (value.isMulti) {
        //since it's multi, the maxLen would have been calculated before this step to be used.
        //if max length = 0, then we skip that entry completely
        if (value.maxLen === 0) continue;

        //since each multi item consists of an array of specs (each of which adds a column or series of columns), we loop
        //IMPORTANT: At this point, check the specialTreat property for ecosystemServices
        let maxlen = value.maxLen;
        value.specs.forEach((spec: Spec) => {
          //get basecolumn
          const baseColumnProperty = spec.baseColumn ? spec.baseColumn : key;
          const propertyValue = esvd[baseColumnProperty]; //this will be an array

          maxlen = spec.baseColumn
            ? columnsDetails[spec.baseColumn].maxLen
            : value.maxLen;
          //process the array property
          if (maxlen > 0) {
            const len = propertyValue.length;
            if (!spec.flatten) {
              //get the len of the array
              for (let i = 0; i < maxlen; i++) {
                if (i < len) {
                  let cell = propertyValue[i];
                  esvdObject[`${spec.caption}${i + 1}`] =
                    (cell && cell[spec.dbField || "name"]) || "";
                } else {
                  //Pad up empty columns to avoid displacing the row for this entity
                  esvdObject[`${spec.caption}${i + 1}`] = "";
                }
              }
            } else {
              let flattened = "";
              for (let i = 0; i < len; i++) {
                let cell = propertyValue[i];
                flattened =
                  i === 0
                    ? cell[spec.dbField]
                    : `${flattened}${
                        cell
                          ? `${spec.separator || ";"}${cell[spec.dbField]}`
                          : ""
                      }`;
              }
              esvdObject[spec.caption] = flattened;
            }
          }
        });
        // } else if (value.specs && !value.isMulti === false) {
        //   const spec = value.specs.pop();
        //   const propertyValue = esvd[key];
        //   esvdObject[lowerCaseFirstLetter(spec.caption)] =
        //     propertyValue[spec.dbField];
      } else {
        if (typeof value === "object" && value.isSimple) {
          const spec = value.spec;
          const propertyValue = esvd[spec.dbField];
          esvdObject[spec.caption] = propertyValue;
        } else {
          const propertyValue = esvd[key];
          esvdObject[value] = propertyValue;
        }
      }
    }

    bodyArray.push(esvdObject);
  }

  return bodyArray;
};

//get the maxlens of all multi column fields in the result set
export const setMultiColumnMaxLensSummaryStats = (
  esvds: Esvd[],
  columns: SummaryStatsColumnStructure
) => {
  //get columns whose values are arrays
  const arrayOfMultis = Object.entries(columns).filter(([key, value]) => {
    return typeof value === "object" && value !== null && value?.isMulti;
  });

  //then for each column, find the max len of sub-columns
  arrayOfMultis.forEach(([key, value]) => {
    //from the filter above, "entry" is an array of key/value pair: [key, value].
    //The value in this case are arrays which hold the fixed parts of the captions for the sub-columns
    //the key holds the name of the column for which we want to find max len
    const maxlen = maxLen(esvds, key);

    columns = { ...columns, [key]: { ...value, maxLen: maxlen } };
  });
  return columns;
};

export const statsHeaderTransform = (
  columns: [],
  sortable: boolean = true
): any[] => {
  //build a list of maxlen for multi column properties
  let headerArray: HeaderItem[] = [];

  for (const item of columns) {
    const headObject: HeaderItem = {
      key: item,
      title: item,
      dataKey: item,
      width: 100,
      resizable: true,
    };
    headerArray.push(headObject);
  }

  // if (sortable) {
  //   if (headerArray) {
  //     for (let i = 0; i < 2; i++) {
  //       headerArray[i].sortable = true;
  //     }
  //   }
  // }

  return headerArray;
};

export const NumberFormat = (
  number: number,
  decimalPlaces: number = 2,
  currencySymbol: string = ""
) => {
  //ensure 'number' is truly a number
  const num = Number(number) || 0;
  return `${currencySymbol} ${num
    .toFixed(decimalPlaces)
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}`;
};

const handleFileChosen = async (file: File) => {
  return new Promise((resolve, reject) => {
    let fileReader = new FileReader();
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = reject;
    fileReader.readAsDataURL(file);
  });
};
export const readAllFiles = async (AllFiles: File[]) => {
  const results = await Promise.all(
    AllFiles.map(async (file) => {
      return Object.assign(file, {
        filename: file.name,
        contentType: file.type,
        content: await handleFileChosen(file),
      });
      // const fileContents = await handleFileChosen(file);
      // return fileContents;
    })
  );
  return results;
};

export const readUploadedFile = async (myfile: File) => {
  const content = await handleFileChosen(myfile);
  return {...myfile,
        filename: myfile.name,
        contentType: myfile.type,
        content: content,
      }
  // const results = await Promise.all(
  //   [myfile].map(async (file) => {
  //     return Object.assign(file, {
  //       filename: file.name,
  //       contentType: file.type,
  //       content: await handleFileChosen(file),
  //     });
  //   })
  // );
  // return results;
};

//ecosystemServices items
export const formatBiomeForDropdown = (ess: TreeType[], includeMainInOptions=true) => {
  // //get header classifications
  // const headers = ess.filter(
  //   (x) => !x.isSub && x.code === null && x.sortOrder === null
  // );
  //get main es
  
  const mains = (ess || [])
    .filter((x) => !x.isSub && x.code !== null && x.sortOrder === 1)
    .sort((a, b) => parseInt(a.code) - parseInt(b.code));
  // .sort((a, b) => (a.path > b.path ? 1 : -1));

  let masterList = [];
  for (const es of mains) {
    let subList = [];
    //add main to master list
    if (includeMainInOptions)
      subList.push(es);

    //get subs of the mains
    const subs = ess
      .filter((x) => x.isSub && x.path.startsWith(`${es.path}/`) && x.sortOrder < 3) //L3 excluded
      .sort(function (a, b) {
        var nums1 = a.code.split(".");
        var nums2 = b.code.split(".");

        for (var i = 0; i < nums1.length; i++) {
          if (nums2[i]) { // assuming 5..2 is invalid
            if (nums1[i] !== nums2[i]) {
              return parseInt(nums1[i]) - parseInt(nums2[i]);
            } // else continue
          } else {
            return 1; // no second number in b
          }
        }
        return -1; // was missing case b.len > a.len
      });
    
      
    subList.push(...subs);
    //disable sublist without code
    subList = subList.map((x, index) => {
      x.isDisabled = !x.code ? true : false;
      return x;
    });

    masterList.push({
      label: `${es.code} -- ${es.label}`,
      options: subList,
    });
  }
  return masterList;
};
//ecosystemServices items
export const formatSeeasDropdown = (items: TreeType[]) => {
  //get main es
  const mains = (items||[])
    .filter((x: any) => x.sortOrder === 1)
    .sort((a: any, b: any) => a.parentId > b.parentId ? 1 : a.parentId < b.parentId ? -1 : 0);
    // .sort((a, b) => parseInt(a.code) > parseInt(b.code) ? 1 : parseInt(a.code) < parseInt(b.code) ? -1 : 0);

  let masterList = [];
  for (const item of mains) {
    let subList = [];
    //add main to master list
    subList.push(item);
    //get subitems under mains
    const subs = items
      .filter((x) => x.sortOrder === item.sortOrder + 1 && x.parentId === item.id)
      .sort(function (a, b) {
        var nums1 = a.code.split(".");
        var nums2 = b.code.split(".");

        for (var i = 0; i < nums1.length; i++) {
          if (nums2[i]) { // assuming 5..2 is invalid
            if (nums1[i] !== nums2[i]) {
              return parseInt(nums1[i]) - parseInt(nums2[i]);
            } // else continue
          } else {
            return 1; // no second number in b
          }
        }
        return -1; // was missing case b.len > a.len
      });
    
    subList.push(...subs);
        //disable sublist without code
    subList = subList.map((x, index) => {
      x.isDisabled = !x.code ? true : false;
      x.hideCode = true;
      return x;
    });
    masterList.push({
      label: `${item.label}`,
      // label: `${item.code} -- ${item.label}`,
      options: subList,
    });
  }
  return masterList;
};

export function getMonthNameShort(monthNumber: number) {
	const date = new Date();
	date.setMonth(monthNumber);

	return date.toLocaleString("en-US", { month: "short" });
}

export function getMonthName(monthNumber: number) {
	const date = new Date();
	date.setMonth(monthNumber);

	return date.toLocaleString("en-US", { month: "long" });
}

export const getMonthList = () => {
	const months = [];
	for (let i = 0; i < 12; i++) {
		months.push({ label: getMonthName(i), value: i  });
	}
	return months;
};

export function sortReactTableData(isDescend: boolean, items: any[], key: any) {
	const factor = isDescend ? -1 : 1;
	const sorted = items.sort((a: any, b: any) => {
		if (a[`${key}`] < b[`${key}`]) return 1 * factor;
		else if (a[`${key}`] > b[`${key}`]) return -1 * factor;
		else return 0;
	});
	return sorted;
}

export const standardValueFormat = (stValue: number): string => {
  if (!stValue)
    return ""
  if (stValue < 0.1) {
      return stValue.toFixed(2)
  }
  
  if (stValue >= 0.1 && stValue <= 1000) {
    return stValue.toFixed(1)
  }
	return stValue.toFixed(0);
};

//ecosystemServices items
export const formatShowOnlyBiomeForDropdown = (ess: TreeType[]) => {
  // //get header classifications
  // const headers = ess.filter(
  //   (x) => !x.isSub && x.code === null && x.sortOrder === null
  // );
  //get main es
  
  const mains = (ess || [])
    .filter((x) => !x.isSub && x.code !== null && x.sortOrder === 1)
    .sort((a, b) => parseInt(a.code) - parseInt(b.code));

  let masterList = [];
  for (const es of mains) {
    let subList = [];
    //add main to master list
    subList.push(es);

    // //get subs of the mains
    // const subs = ess
    //   .filter((x) => x.isSub && x.path.startsWith(`${es.path}/`) && x.sortOrder < 3) //L3 excluded
    //   .sort((a, b) => (a.path > b.path ? 1 : -1));
    // subList.push(...subs);
    //disable sublist without code
    subList = subList.map((x, index) => {
      x.isDisabled = !x.code ? true : false;
      return x;
    });

    masterList.push({
      // label: `${es.code} -- ${es.label}`,
      options: subList,
    });
  }
  return masterList;
};

//ecosystemServices items
export const formatEnableOnlyEcosystemForDropdown = (ess: TreeType[]) => {
  // //get header classifications
  // const headers = ess.filter(
  //   (x) => !x.isSub && x.code === null && x.sortOrder === null
  // );
  //get main es
  
  const mains = (ess || [])
    .filter((x) => !x.isSub && x.code !== null && x.sortOrder === 1)
    .sort((a, b) => parseInt(a.code) - parseInt(b.code));
  // .sort((a, b) => (a.path > b.path ? 1 : -1));

  let masterList = [];
  for (const es of mains) {
    let subList = [];
    //add main to master list
    // subList.push(es);

    //get subs of the mains
    const subs = ess
      .filter((x) => x.isSub && x.path.startsWith(`${es.path}/`) && x.sortOrder < 3) //L3 excluded
      .sort(function (a, b) {
        var nums1 = a.code.split(".");
        var nums2 = b.code.split(".");

        for (var i = 0; i < nums1.length; i++) {
          if (nums2[i]) { // assuming 5..2 is invalid
            if (nums1[i] !== nums2[i]) {
              return parseInt(nums1[i]) - parseInt(nums2[i]);
            } // else continue
          } else {
            return 1; // no second number in b
          }
        }
        return -1; // was missing case b.len > a.len
      });
    
    
      // .sort((a, b) => (a.path > b.path ? 1 : -1));
    subList.push(...subs);
    //disable sublist without code
    subList = subList.map((x, index) => {
      x.isDisabled = !x.code ? true : false;
      return x;
    });

    masterList.push({
      label: `${es.code} -- ${es.label}`,
      options: subList,
    });
  }
  return masterList;
};




/**
 * Above $10 (0 decimals, so $13)
1 and above, $1 (1 decimal, so $1.1)
Below 1 (2 decimals, so $0.66)?

has now changed to 

 * 1 and above, $1 (0 decimals)
    Below 1 (1 decimal)
 */
export const formatVttCalculation = (results:any) => {

  if (`${results}`.includes("e")) {
    return `  ${results.toExponential(
      2
    )}  `
  }

  if (results >= 1) {
    return `${parseInt(results)}`
  }

  if (results < 1) {
   return `${results?.toFixed(1)}`
  }
  
  return ""
}