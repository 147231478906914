import React, { Fragment, useEffect } from "react";
import { Card, Label } from "semantic-ui-react";
import * as Yup from "yup";
import WelcomeHeader from "../../shared/WelcomeHeader";
import { Link, Redirect } from "react-router-dom";
import { ErrorHandler } from "../../shared/ExceptionHandling";
import { useAuth } from "../../providers/AuthProvider";
import LoginsContainer from "./LoginsContainer";

interface IProps {
	confirmemail: Function;
	// user: User;
	isSesetLinkSent: boolean;
	location: any | null;
}

const ConfirmEmail = (props: IProps) => {
	const { confirmemail, setToken } = useAuth();

	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [isConfirmed, setIsConfirmed] = React.useState<boolean>(false);
	const [error, setError] = React.useState<any>(null);
	const [isError, setIsError] = React.useState<boolean>(false);
	const [subheadText, setsubheadText] = React.useState<string>("Confirm Email");
	const [queryString] = React.useState<any>(
		Object.fromEntries(new URLSearchParams(props.location.search))
	);

	const confirmationSent = () => {
		setIsConfirmed(!isConfirmed);
		setsubheadText("Reset successful.");
	};

	const handleFormSubmit = () => {
		setIsLoading(true);

		// confirmemail({ username: queryString.username, code: 65333 })
		confirmemail(queryString)
			.then((res: any) => {
				confirmationSent();
				// setLoading(false);
			})
			.catch((error: any) => {
				// setErrors({ error: ErrorHandler(error) });
				setIsError(true);
				setError(ErrorHandler(error));
				// this.setState(() => ({ isError: true, error: ErrorHandler(error) }));
				// setLoading(false);
			});
		setIsLoading(false);
	};

	useEffect(() => {
		handleFormSubmit();
	}, []);

	const renderContent = () => {
		if (queryString.username && queryString.code) {
			setToken();
			return (
				<Card
					centered
					// className="small-card"
					style={{
						padding: "10px",
					}}>
					<Card.Content textAlign="left">
						<h4 className="title-card">Welcome to the ESVD</h4>
					</Card.Content>
					{isConfirmed && !isError ? (
						<Card.Content className="text-center">
							Your confirmation was successful.
							<br />
							Please click <Link to="/login">here</Link> to login.
						</Card.Content>
					) : isError && error ? (
						<Label
							style={{ marginBottom: 10, display: "block", border: "none" }}
							className="text-center"
							basic
							color="red"
							content={error}
						/>
					) : (
						<span
							className={`box loader-01 ${
								isLoading ? "" : "invisible"
							}`}></span>
					)}
				</Card>
			);
		}
		//a login user shouldn't see the login page.
		//Therefore is a logged user access the login route, go to home
		// history.push("/");
		else return <Redirect to="/" />;
	};

	return <LoginsContainer>{renderContent()}</LoginsContainer>;
	// }
};

// const validationSchema = Yup.object({
// 	// email: Yup.string().required("Email is required"),
// });

// const mapStateToProps = (state: any) => {
// 	return {
// 		user: state.user,
// 	};
// };

export default ConfirmEmail;
// 	connect(null, {
// 	confirmemail,
// })(ConfirmEmail);
