import { ErrorMessage, Field, FieldArray, Formik, useField } from "formik";
import React, { Fragment, useEffect } from "react";
import {
	Form,
	Label,
	Card,
	Checkbox,
	Grid,
	Input,
	Button,
} from "semantic-ui-react";
import * as Yup from "yup";

import { useState } from "react";
import {
	VttBiome,
	VttVariableDetailMap,
} from "../../../models/vtt-models/VttBiome";
import { useVttVariableDetailMap } from "../../../providers/VttVariableDetailMapProvider";
import SelectDropdown from "../../../shared/SelectDropdown";
import { useVttBiome } from "../../../providers/VttBiomeProvider";
import { useAuth } from "../../../providers/AuthProvider";
import { userRole } from "../../../shared/Literals";
import VTTCalculationDropdown from "../../shared-components/VTTCalculationDropdown";
import VTTCalculationFourColumnHeader from "../../shared-components/VTTCalculationEntryHeader";
import { formatVttCalculation } from "../../../EsvdFunctions";

const VariableInput = (props: any) => {
	const [field, meta] = useField(props);
	const { label, checkLabel, remarks, checked, setChecked, sampleMean } = props;
	return (
		<Form.Field error={meta.touched && !!meta.error}>
			<Grid>
				<Grid.Row>
					<Grid.Column width={4}>
						<label>{label}</label>
					</Grid.Column>
					<Grid.Column width={4}>
						<Input
							labelPosition="left"
							{...field}
							placeholder={props.placeholder}
							fluid={props.fluid || false}
							type={props.type || "text"}
							// size={size}
							value={
								sampleMean
									? sampleMean
									: field.value
									? field.value
									: field.value === 0
									? "0"
									: ""
							}
							disabled={props.disabled || false}
							className={props.className}>
							<input />
						</Input>
						{meta.touched && meta.error ? (
							<Label
								basic
								color="red"
								size="tiny"
								style={{ padding: "0px", border: "none", display: "block" }}>
								{meta.error}
							</Label>
						) : null}
					</Grid.Column>

					<Grid.Column width={6} verticalAlign="middle">
						<label>{remarks}</label>
					</Grid.Column>
					<Grid.Column width={2} verticalAlign="middle">
						<Checkbox
							label={checkLabel}
							onChange={(e, data: any) => setChecked(data.checked)}
							checked={checked}
						/>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		</Form.Field>
	);
};

const VttValueCalculationEntry = () => {
	const { getVttBiomes, getVttBiome } = useVttBiome();
	const { getVttVariableDetailMaps } = useVttVariableDetailMap();

	const [vttVariableDetailMaps, setVttVariableDetailMaps] =
		useState<VttVariableDetailMap[]>();

	const [vttBiomes, setVttBiomes] = useState<any[]>([]);

	//holds the sub biomes for the biomes
	const [subBiomesForDropdown, setSubBiomesForDropdown] = useState<any>();

	const [selectedBiomeid, setSelectedBiomeid] = React.useState();
	const [selectedBiome, setSelectedBiome] = React.useState<VttBiome | null>();

	//An object holding the selected vtt variables from the sub biome dynamic dropdowns.
	const [selectedVttVariablesOfSubbiomes, setSelectedVttVariablesOfSubbiomes] =
		React.useState<any>({});

	//VttVariables which do not belong to a sub biome group
	const [freeVariables, setFreeVariables] = React.useState<any>();

	//An object holding the initial values of the dynamic inputs.
	//Since they are dynamically created and their names are not known before hand,
	//this is the appropriate means to define initial values, with the name of the input as key, containing the initial value of the input
	const [initialValues, setInitialValues] = React.useState<any>({});

	const [validateSchema, setValidateSchema] = React.useState<any>();

	const [ecosystemServiceValue, setEcosystemServiceValue] = React.useState<
		number | null
	>(null);

	const [ecosystemServiceValueLb, setEcosystemServiceValueLb] = React.useState<
		number | null
	>(null);
	const [ecosystemServiceValueUb, setEcosystemServiceValueUb] = React.useState<
		number | null
	>(null);
	//An object holding the checkstate of the dynamic checkboxes for each input.
	//Since they are dynamically created and their names are not known before hand,
	//this is the appropriate means to attached a check property, with the name of the input as key, containing the state of the checkbox
	const [inputCheckedList, setInputCheckedList] = React.useState<any>({});

	//use this to rebuild the validation
	const [defaultFields, setDefaultFields] = React.useState<any[]>([]);
	//use this to retrieve actual defaults during calculation
	// const [defaultVttVariableIds, setDefaultVttVariableIds] = React.useState<
	// 	any[]
	// >([]);

	const { user, fetchCurrentUser } = useAuth();

	const generateFieldValidations = (
		vttdets: VttVariableDetailMap[]
		// doSubbiomes: boolean = false
	) => {
		const subbiomesIds = Array.from(
			new Set(
				vttdets
					.filter((x) => x.vttSubBiomeId)
					.map(
						(k) => JSON.stringify({ id: k.vttSubBiomeId, name: k.vttSubBiome }) //use stringify on the object to ensure their strings are compared to eliminate duplicates
					)
			)
		);

		//use the sub biomes to create key-value pairs of vtt variables that belong to each sub biome for use as dropdown options
		const subbiomeOptions: any = {};
		for (let it of subbiomesIds) {
			if (it) {
				const ob = JSON.parse(it);
				subbiomeOptions[ob.id] = {
					name: ob.name,
					options: vttdets
						.filter((x) => x.vttSubBiomeId == ob.id)
						.map((m) => ({
							value: m.vttVariableId,
							label: m.vttVariable,
							remarks: m.remarksText,
						})),
				};
			}
		}
		setSubBiomesForDropdown(subbiomeOptions);

		const freeVar = vttdets
			.filter((x) => !x.vttSubBiomeId)
			.map((m) => ({
				id: m.vttVariableId,
				name: m.vttVariable,
				remarks: m.remarksText,
			}));

		setFreeVariables(freeVar);

		//build Yup object for validation
		//drop-sub-k -> sub biome dropdown
		//drop-var-k -> sub biome dropdown selected item entry
		//var-k -> variable
		const fieldsForValidation: any = {};
		for (let it of subbiomesIds) {
			const ob = JSON.parse(it);
			let fieldName = `drop-sub-${ob.id}`;
			if (!defaultFields.includes(fieldName))
				fieldsForValidation[fieldName] = Yup.string().required("Required");
		}

		for (let it of freeVar) {
			//get var details of id
			const vardat = vttdets.find((x) => x.vttVariableId == it.id);
			let fieldName = `var-${it.id}`;
			if (defaultFields.includes(fieldName)) continue;
			if (vardat) {
				fieldsForValidation[`var-${it.id}`] = Yup.number()
					.typeError("Must be a number")
					.min(
						parseFloat(vardat.min ? vardat.min : vardat.min == "0" ? "0" : ""),
						`Must be greater than or equal to ${vardat.min}.`
					)
					.max(
						parseFloat(vardat.max ? vardat.max : vardat.max == "0" ? "0" : ""),
						`Must be less than or equal to ${vardat.max}.`
					)
					.required("Required");
			}
		}

		setValidateSchema(Yup.object({ ...fieldsForValidation }));
		return fieldsForValidation;
	};

	useEffect(() => {
		fetchCurrentUser();
		(async () => {
			//load biomes
			if (user) {
				var biomes =
					user.role === userRole.admin || user.role === userRole.cocapacity
						? await getVttBiomes(null)
						: await getVttBiomes(null, true);

				setVttBiomes(biomes);
			}
		})();
	}, []);

	useEffect(() => {
		setSelectedVttVariablesOfSubbiomes({});
		setEcosystemServiceValue(null);
		setValidateSchema(null);
		setInitialValues({});
		setFreeVariables(null);
		setSubBiomesForDropdown(null);
		setDefaultFields([]);
		setInputCheckedList({});
		setSelectedBiome(null);
		setVttVariableDetailMaps([]);
		if (selectedBiomeid) {
			(async () => {
				const biome = await getVttBiome(selectedBiomeid);
				setSelectedBiome(biome);
				var vttdets: VttVariableDetailMap[] = await getVttVariableDetailMaps(
					selectedBiomeid
				);
				setVttVariableDetailMaps(vttdets);

				//get unique sub biomeids from details map
				const validate: any = generateFieldValidations(vttdets);

				const init: any = {};
				for (let it in validate) {
					init[it] = "";
				}

				setInitialValues(init);
			})();
		}
	}, [selectedBiomeid]);

	const [
		selectedSubbiomeVttVariableRemarks,
		setSelectedSubbiomeVttVariableRemarks,
	] = useState<any>({});

	useEffect(() => {
		setEcosystemServiceValue(null);
		vttVariableDetailMaps && generateFieldValidations(vttVariableDetailMaps);

		//update remarks for selected items in drop downs

		const remrks: any = {};
		//use field names
		for (let k in selectedVttVariablesOfSubbiomes) {
			const rks = vttVariableDetailMaps?.find(
				(x) => x.vttVariableId == selectedVttVariablesOfSubbiomes[k]
			);
			if (rks) {
				remrks[`drop-var-${k}`] = rks.remarksText;
			}
		}

		setSelectedSubbiomeVttVariableRemarks({ ...remrks });
	}, [selectedVttVariablesOfSubbiomes]);

	//update fields listed to use default values
	useEffect(() => {
		setEcosystemServiceValue(null);
		const defaults = Object.keys(inputCheckedList).filter(
			(x) => inputCheckedList[x]
		);

		if (defaults && defaults.length > 0) {
			const set = new Set();
			for (let de of defaults) {
				set.add(de);
			}
			setDefaultFields([...Array.from(set)]);
		} else {
			setDefaultFields([]);
		}
	}, [inputCheckedList]);

	useEffect(() => {
		setEcosystemServiceValue(null);
		vttVariableDetailMaps && generateFieldValidations(vttVariableDetailMaps);
	}, [defaultFields]);

	const handleFormSubmit: Function = async (
		values: any,
		setErrors: Function
	) => {
		//NOTE drop-sub-k holds the id of the variable whose value has been entered under drop-var-k
		// {
		// 	"drop-sub-3": 15,
		// 	"drop-var-3": "23",
		// 	"drop-sub-1": 19,
		// 	"drop-var-1": "43",
		// }
		// Eg drop-sub-3 is 15. This 15 is the id of the variable selected for subbiome with id 3 and the user value entered for the variable 15 is 23

		//Sample Entries
		// const tempval: any = {
		// 	"drop-sub-1": 19,
		// 	"drop-sub-3": 15,
		// 	"drop-var-1": "1",
		// 	"drop-var-3": "1",
		// 	"var-1": "30000",
		// 	"var-11": "0.9",
		// 	// "var-12": "16.511",
		// 	"var-22": "60",
		// 	"var-23": "40",
		// 	"var-24": "1",
		// 	"var-25": "1000",
		// 	"var-4": "40",
		// 	"var-6": "50",
		// };

		const biomeVarEntries: any = {};

		for (let ent in values) {
			if (ent.startsWith("drop-sub-")) {
				const vttvarid = values[ent];
				//sub biome chosen entries do not need the sample mean anymore. so we simple multiply by 1
				biomeVarEntries[`${vttvarid}`] = 1;
				// const varMap = vttVariableDetailMaps?.find(
				// 	(x) => x.vttVariableId == vttvarid
				// );
				// if (varMap) {
				// 	biomeVarEntries[`${vttvarid}`] = varMap.sampleMean;
				// }
				continue;
			}

			if (ent.startsWith("var-")) {
				//get var id
				const varid = ent.replace("var-", "");
				biomeVarEntries[`${varid}`] = values[ent];
				continue;
			}
		}

		for (let ent of defaultFields) {
			//check if it's included in defaults
			if (defaultFields.includes(ent)) {
				let vttvarid: string | null = null;
				if (ent.startsWith("drop-var-")) {
					//use the id of the sub biome to get the id of the vvt variable
					const subid = ent.replace("drop-var-", "");
					//get vtt varid from select
					vttvarid = selectedVttVariablesOfSubbiomes[subid];
				} else if (ent.startsWith("var-")) {
					vttvarid = ent.replace("var-", "");
				}

				const varMap = vttVariableDetailMaps?.find(
					(x) => x.vttVariableId == vttvarid
				);
				if (varMap) {
					biomeVarEntries[`${vttvarid}`] = varMap.sampleMean;
				}
				continue;
			}
		}

		//use details map to get details of the chosen variables for the calculation
		let summation = 0;
		for (let varid in biomeVarEntries) {
			const vdMap = vttVariableDetailMaps?.find(
				(x) => x.vttVariableId == varid
			);
			if (vdMap) {
				if (vdMap.coefficient) {
					const varCalculation = vdMap.isTransformation
						? parseFloat(vdMap.coefficient) *
						  Math.log(parseFloat(biomeVarEntries[varid]))
						: parseFloat(vdMap.coefficient) *
						  parseFloat(biomeVarEntries[varid]);
					// console.log(vdMap.vttVariable, varCalculation);

					summation += varCalculation;
				}
			}
		}
		//add biome constant value
		if (selectedBiome && selectedBiome.constantValue) {
			summation += parseFloat(selectedBiome.constantValue);
		}
		summation = Math.exp(summation);
		const lowerB =
			selectedBiome && selectedBiome.lowerBoundFactor
				? summation * parseFloat(selectedBiome.lowerBoundFactor)
				: null;
		const upperB =
			selectedBiome && selectedBiome.upperBoundFactor
				? summation * parseFloat(selectedBiome.upperBoundFactor)
				: null;
		setEcosystemServiceValue(summation);
		setEcosystemServiceValueLb(lowerB);
		setEcosystemServiceValueUb(upperB);
	};

	return (
		<>
			<Card.Content style={{ overflowY: "auto", height: "100%" }}>
				<div>
					<Formik
						enableReinitialize={true}
						initialValues={initialValues}
						validationSchema={validateSchema}
						onSubmit={(values, { setErrors }) => {
							handleFormSubmit(values, setErrors);
						}}>
						{({ values, handleSubmit, errors }) => (
							<Form
								onSubmit={handleSubmit}
								className="custom-ui-element"
								autoComplete={"off"}
								encType="multipart/form-data">
								<div style={{ padding: "2em 2em" }}>
									<Field
										name="vttBiomeId"
										label="Biome"
										options={vttBiomes || []}
										isClearable={true}
										component={SelectDropdown}
										value={selectedBiomeid}
										placeholder="Biome"
										onChange={(value: any) => {
											setSelectedBiomeid(value);
										}}
									/>
								</div>
								<hr />
								{selectedBiomeid && freeVariables && (
									<label style={{ padding: "1em 2em", color: "red" }}>
										All fields are required
									</label>
								)}
								<div style={{ padding: "1em 2em" }}>
									<FieldArray
										name="paramLists"
										render={(arrayHelpers) => (
											<div>
												<VTTCalculationFourColumnHeader
													col1="Variable"
													col2="Enter your data"
													col3="Short explanation"
													col4="Use default value"
												/>
												<div>
													{selectedBiomeid &&
														subBiomesForDropdown &&
														Object.keys(subBiomesForDropdown).map((k, i) => {
															return (
																<Fragment key={k}>
																	<Field
																		name={`drop-sub-${k}`}
																		label={`${subBiomesForDropdown[k].name}`}
																		options={
																			subBiomesForDropdown[k].options || []
																		}
																		isClearable={true}
																		component={VTTCalculationDropdown}
																		value={
																			selectedVttVariablesOfSubbiomes
																				? selectedVttVariablesOfSubbiomes[k]
																				: ""
																		}
																		placeholder=""
																		onChange={(value: any) => {
																			//use the name of the sub biome to hold the selected value
																			setSelectedVttVariablesOfSubbiomes({
																				...selectedVttVariablesOfSubbiomes,
																				[`${k}`]: value,
																			});
																		}}
																	/>
																	<div style={{ display: "none" }}>
																		<VariableInput
																			key={i}
																			name={`drop-var-${k}`}
																			label=""
																			placeholder=""
																			checkLabel="default"
																			checked={true}
																			disabled={true}
																			remarks={
																				selectedSubbiomeVttVariableRemarks &&
																				selectedSubbiomeVttVariableRemarks[
																					`drop-var-${k}`
																				]
																			}
																		/>
																	</div>
																</Fragment>
															);
														})}
												</div>

												<div>
													{selectedBiomeid &&
														freeVariables &&
														Object.keys(freeVariables).map((k, i) => {
															return (
																<VariableInput
																	key={i}
																	name={`var-${freeVariables[k].id}`}
																	label={freeVariables[k].name}
																	placeholder={freeVariables[k].name}
																	setChecked={(checkstate: any) => {
																		//get the default value and keep along with variable name
																		let vvtdetmap = null;
																		if (checkstate)
																			vvtdetmap = vttVariableDetailMaps?.find(
																				(x) =>
																					x.vttVariableId == freeVariables[k].id
																			);
																		setInputCheckedList({
																			...inputCheckedList,
																			[`var-${freeVariables[k].id}`]: checkstate
																				? vvtdetmap?.sampleMean
																				: null,
																		});
																	}}
																	//this props help to display sample mean for those
																	//checked as default inside the entry field
																	sampleMean={
																		inputCheckedList &&
																		inputCheckedList[
																			`var-${freeVariables[k].id}`
																		]
																	}
																	// checkLabel="default"
																	checked={
																		inputCheckedList[
																			`var-${freeVariables[k].id}`
																		]
																	}
																	disabled={
																		inputCheckedList[
																			`var-${freeVariables[k].id}`
																		]
																	}
																	remarks={freeVariables[k].remarks}
																/>
															);
														})}
												</div>
											</div>
										)}
									/>
								</div>
								<hr />
								<div style={{ padding: "1em 2em" }}>
									{selectedBiomeid && freeVariables && (
										<Grid>
											<Grid.Row>
												<Grid.Column width={4}>
													<button
														className="ui button btn btn-primary btn-sm"
														type="submit"
														style={{
															backgroundColor: "#70bbfd",
															// display: "block !important",
															// width: "6rem",
															height: "2rem",
														}}>
														Calculate
													</button>
												</Grid.Column>
												<Grid.Column width={12} style={{ paddingTop: "0.2em" }}>
													Ecosystem service value calculation result:{"  "}
													<h5 style={{ display: "inline" }}>
														<strong>
															{ecosystemServiceValue
																? formatVttCalculation(ecosystemServiceValue)
																: ""}
														</strong>
													</h5>
													{"    "}
													{ecosystemServiceValue && "  (Int$/ha/year)"}
												</Grid.Column>
											</Grid.Row>
											{ecosystemServiceValue &&
											selectedBiome &&
											selectedBiome.lowerBoundFactor !== undefined &&
											selectedBiome.lowerBoundFactor !== null &&
											parseFloat(selectedBiome.lowerBoundFactor) >= 0 &&
											parseFloat(selectedBiome.lowerBoundFactor) <= 1 &&
											selectedBiome.upperBoundFactor &&
											parseFloat(selectedBiome.upperBoundFactor) >= 1 &&
											parseFloat(selectedBiome.upperBoundFactor) <= 2 ? (
												<Grid.Row>
													<Grid.Column width={4}></Grid.Column>
													<Grid.Column
														width={12}
														style={{ paddingTop: "0.2em" }}>
														Confidence interval:{"  "}
														<>
															<h5 style={{ display: "inline" }}>
																<strong>
																	{ecosystemServiceValueLb
																		? formatVttCalculation(
																				ecosystemServiceValueLb
																		  )
																		: ""}
																</strong>
															</h5>
															{"   -  "}
															<h5 style={{ display: "inline" }}>
																<strong>
																	{ecosystemServiceValueUb
																		? formatVttCalculation(
																				ecosystemServiceValueUb
																		  )
																		: ""}
																</strong>
															</h5>
															{"    "}
															{ecosystemServiceValue && "  (Int$/ha/year)"}
														</>
													</Grid.Column>
												</Grid.Row>
											) : (
												""
											)}
										</Grid>
									)}
									<ErrorMessage
										name="error"
										render={() => (
											<Label
												style={{ marginBottom: 5, borderColor: "none" }}
												basic
												color="red"
												size="tiny"
												content={errors.error}
											/>
										)}
									/>
								</div>
							</Form>
						)}
					</Formik>
				</div>
			</Card.Content>
		</>
	);
};

export default VttValueCalculationEntry;
