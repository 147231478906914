import * as React from "react";
import { Card } from "semantic-ui-react";
import agent from "../../api/agent";
import { NumberFormat } from "../../EsvdFunctions";

const EcosystemValuationDB = () => {
	const [esvdTotalCount, setEsvdTotalcount] = React.useState<any>(0);
	React.useEffect(() => {
		//get info about the last upload
		(async () => {
			var totalesvdCount: Number = await agent.Esvds.esvdTotalCount();
			setEsvdTotalcount(totalesvdCount);
		})();
	}, []);

	return (
		<Card fluid>
			<Card.Content>
				<h4 className="title-card">
					Ecosystem Services Valuation Database (ESVD)
				</h4>
			</Card.Content>
			<Card.Content>
				<p>
					The continued loss of ecosystems and biodiversity is endangering the
					prosperity of current and future generations (Dasgupta, 2021). To make
					better informed decisions about the trade-offs involved in ecosystem
					management, land-use change, or any activity affecting ecosystems and
					biodiversity, we need better information about the full importance of
					ecosystems to our wellbeing. The Ecosystem Services Valuation Database
					(ESVD) has been developed with the long-term goal of providing robust
					and easily accessible information on the economic benefits of
					ecosystems and biodiversity, and the costs of their loss, to support
					decision making regarding nature conservation, ecosystem restoration
					and sustainable land management.
				</p>{" "}
				<p>
					{" "}
					The focus of the ESVD is to gather information on economic welfare
					values related to ecosystem services measured in monetary units. By
					communicating such values in monetary units, we provide recognisable
					information that can be used to internalise the importance of Nature
					in decision making.
				</p>
				<p>
					The ESVD currently contains {NumberFormat(esvdTotalCount, 0)} value
					records from over 1100 studies distributed across all biomes,
					ecosystem services and geographic regions. Our repository of valuation
					studies contains over 2000 studies and the number is growing
					continuously so the number of value records in the ESVD will increase
					over time.
				</p>
				<p>
					Do you want to read more about the ESVD or to collaborate with us?
					Please{" "}
					<a
						className="esvd-hyperlink"
						href="https://esvd.info"
						target="_blank"
						rel="noopener noreferrer">
						visit our info website
					</a>{" "}
					for more information on the background, applications and latest
					projects, and contact details.
				</p>
			</Card.Content>
		</Card>
	);
};

export default EcosystemValuationDB;
