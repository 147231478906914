import axios, { AxiosResponse } from "axios";
import { Country } from "../models/country";
import { Esvd } from "../models/esvd";
import { ProtectionStatus } from "../models/protectionStatus";
import { ValuationMethod } from "../models/valuationMethods";
import {
  EsvdQueryParams,
  FilterQueryParams,
  FreeTextQueryParams,
  UserQueryParams,
} from "../models/EsvdQueryFilters";
import { TreeType } from "../models/ecosystemService";
import {
  ForgotPassword,
  ResendConfirmation,
  ResetPassword,
  User,
  UserFormValues,
  UserLoginMode,
  UserQueryResult,
} from "../models/user";
import { Study } from "../models/Study";
import { Valuation } from "../models/Valuation";
import { DropdownType } from "../models/dropdownType";
import { Feedback, SuggestStudy } from "../models/SuggestStudy";
import { FilterQuery } from "../models/FilterQuery";
import { ReferenceInfo } from "../models/ReferenceInfo";
import {  UploadDto } from "../models/UploadDto";
import { EsvdMap } from "../models/esvdMap";
import { EsvdDataStatistics } from "../models/EsvdDataStatistics";
import { VttBiome, VttSubBiome, VttVariable, VttVariableDetailMap } from "../models/vtt-models/VttBiome";
import { PagingParams } from "../models/PaginationParams";
import { ValuationStudy } from "../models/ValuationStudy";
// import { VslUpdate } from "../models/vsl-models/VslGridItem";
import { VslValuationStudy } from "../models/vsl-models/VslValuationStudy";
import { StatsResultData } from "../providers/EsvdValuationProvider";


const Qs = require("qs");
const CryptoJS = require("crypto-js");

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

// let token = "";
axios.interceptors.request.use((config) => {
  // grab current state
  // const state = store.getState();
  // get the JWT token out of it
  // const token = token;
  let token = sessionStorage.getItem("jwt");
  if (token) {
    const key = process.env.REACT_APP_CRYPTO_KEY || "";
    //decrypt token
    const tokenBytes = CryptoJS.AES.decrypt(token, key);
    token = tokenBytes.toString(CryptoJS.enc.Utf8);
  }

  if (token) config.headers.Authorization = `Bearer ${token}`;
  config.maxContentLength = Infinity;
  config.maxBodyLength = Infinity;

  return config;
});

// let token = "";
axios.interceptors.response.use(
  response => response,
  error => {
    if (!error.response) {
      return Promise.reject(error);
    }

    const {
      response: { status, data },
    } = error;

    if (status >= 400 && status < 500) {
      switch (status) {
        case 401:
          error.response.statusText = "Check username and password.";
          break;
        case 403:
          error.response.statusText = "Not enough permissions.";
          break;
        default:
          error.response.statusText = error.response.data;
          break;
      }
      if (typeof error.response.data === "string" && error.response.data.includes("Email not confirmed")) {
        error.response.statusText = `${error.response.data}. Please go to the email address you used to signup and click on the link sent from ESVD when you signed up.`;
      }
    } else if (status >= 500) {
      if (error.response.data.includes("SqlException")) {
        error.response.data = "Database connectivity issue. Consult the admin.";
      }
    }
    // if (401 === error.response.status) {
    //   return Promise.reject(error);
    // } else {
    // return error;
    return Promise.reject(error);
    // }
  }
);

const responseBody = <T>(response: AxiosResponse<T>) =>
  response ? response.data : response;

const requests = {
  get: <T>(url: string, options?: any) =>
    axios.get<T>(url, options).then(responseBody),
  post: <T>(url: string, body: {}) =>
    axios.post<T>(url, body).then(responseBody),
  postFile: <T>(url: string, body: any, config: any) =>
    axios.post<T>(url, body, config).then(responseBody),
  put: <T>(url: string, body: {}) => axios.put<T>(url, body).then(responseBody),
  del: <T>(url: string) => axios.delete<T>(url).then(responseBody),
};

const Account = {
  current: (): Promise<User> => requests.get("/account/currentuser"),
  login: (user: UserFormValues) => requests.post<User>("/account/login", user),
  verifyuser: (usernameOrEmail: string) => requests.get<UserLoginMode>("/account/verifyuser", {
    params: { usernameOrEmail },
      paramsSerializer: (params: any) => {
        return Qs.stringify(params);
      },
  }),
  GenerateOtp: (usernameOrEmail: string) => requests.get<UserLoginMode>("/account/generateotp", {
    params: { usernameOrEmail },
      paramsSerializer: (params: any) => {
        return Qs.stringify(params);
      },
  }),
  loginwithotp: (user: UserFormValues) => requests.post<User>("/account/loginwithotp", user),
  logout: (): Promise<null> => requests.get("/account/signout"),
  signup: (user: UserFormValues) =>
    requests.post<User>("/account/signup", user),
  forgotpassword: (email: ForgotPassword) =>
    requests.post("/account/forgotpassword", email),
  resendconfirmationlink: (username: ResendConfirmation) =>
    requests.post("/account/resendconfirmationlink", username),
  resetpassword: (user: ResetPassword) =>
    requests.post<User | any>("/account/resetpassword", user),
  confirmemail: (user: ResetPassword) =>
    requests.post<User | any>("/account/confirmemail", user),
  useredit: (user: UserFormValues) =>
    requests.post<User>("/account/useredit", user),
  userslist: (filters?: UserQueryParams): Promise<User[]> => {
    return requests.get("account/allusers", {
      params: filters?.params,
      paramsSerializer: (params: any) => {
        return Qs.stringify(params);
      },
    });
  },
  usersCount: (): Promise<number> => requests.get("account/userscount"),
  earlybirdusers: (filters: UserQueryParams): Promise<UserQueryResult> => requests.post("users/earlybirds", filters.params),
  nonearlybirdusers: (filters: UserQueryParams): Promise<UserQueryResult> => requests.post("users/nonearlybirds", filters.params),
  addearlybirduser: (id:string) => requests.put(`users/addearlybirds/${id}`,{}),
  removeearlybirduser: (id:string) => requests.put(`users/removeearlybirds/${id}`,{})
};

const Esvds = {
  list: (filters: EsvdQueryParams): Promise<Esvd[]> => {
    return requests.get("/esvds/", {
      params: filters.params,
      paramsSerializer: (params: any) => {
        return Qs.stringify(params);
      },
    });
  },
  esvdCount: (filters: EsvdQueryParams): Promise<number> => {
    try {
      return requests.get("/esvds/count", {
        params: filters.params,
        paramsSerializer: (params: any) => {
          return Qs.stringify(params);
        },
      });
    } catch (error) {
      // console.log(error);
      throw error;
    }
  },
  esvdTotalCount: (): Promise<number> => {
    try {
      return requests.get("/esvds/totalcount");
    } catch (error) {
      // console.log(error);
      throw error;
    }
  },  delete: (id: number) => requests.del(`/esvds/${id}`),
};

const EsvdMaps = {
  esvdMapList: (filters: EsvdQueryParams): Promise<EsvdMap[]> => {
    return requests.get("/esvds/mapdata/", {
      params: filters.params,
      paramsSerializer: (params: any) => {
        return Qs.stringify(params);
      },
    });
  },
  // details: (valueids: number[] | null): Promise<Esvd[]> => requests.get(`/esvds/mapmarkerdata/${valueids}`),
  markerDetails: (valueids: number[] | null): Promise<Esvd[]> => {
    return requests.get("/esvds/mapmarkerdata/", {
      params: {valueids},
      paramsSerializer: (params: any) => {
        return Qs.stringify(params);
      },
    });
  },
  totalMapListCount: (): Promise<number> => { 
      return requests.get("/esvds/totalmaplistcount");
  },
  mapListCount: (filters: EsvdQueryParams): Promise<number> => {
    try {
      return requests.get("/esvds/maplistcount", {
        params: filters.params,
        paramsSerializer: (params: any) => {
          return Qs.stringify(params);
        },
      });
    } catch (error) {
      // console.log(error);
      throw error;
    }
  },
};

const EsvdStats = {


  summaryStats: (filters: EsvdQueryParams): Promise<StatsResultData> => {
    return requests.get("/esvds/summarystats", {
      params: filters.params,

      paramsSerializer: (params: any) => {
        return Qs.stringify(params);
      },
    });
  },
};

const Countries = {
  list: (): Promise<Country[]> => requests.get("/countries/"),
  details: (id: string) => requests.get(`/countries/${id}`),
  create: (country: Country) => requests.post("/countries", country),
  update: (country: Country) =>
    requests.put(`/countries/${country.id}`, country),
  delete: (id: string) => requests.del(`/countries/${id}`),
};

const Continents = {
  list: (): Promise<DropdownType[]> => requests.get("/continents/"),
  details: (id: string) => requests.get(`/continents/${id}`),
  create: (continent: DropdownType) => requests.post("/continents", continent),
  update: (continent: DropdownType) =>
    requests.put(`/continents/${continent.id}`, continent),
  delete: (id: string) => requests.del(`/continents/${id}`),
};

const ProtectionStatuses = {
  list: (): Promise<ProtectionStatus[]> => requests.get("/protectionstatuses/"),
  details: (id: string) => requests.get(`/protectionstatuses/${id}`),
  create: (protectionstatus: ProtectionStatus) =>
    requests.post("/protectionstatuses", protectionstatus),
  update: (protectionstatus: ProtectionStatus) =>
    requests.put(
      `/protectionstatuses/${protectionstatus.id}`,
      protectionstatus
    ),
  delete: (id: string) => requests.del(`/protectionstatuses/${id}`),
};

const ValuationMethods = {
  list: (): Promise<ValuationMethod[]> => requests.get("/valuationmethods/"),
  details: (id: string) => requests.get(`/valuationmethods/${id}`),
  create: (valuationmethod: ValuationMethod) =>
    requests.post("/valuationmethods", valuationmethod),
  update: (valuationmethod: ValuationMethod) =>
    requests.put(`/valuationmethods/${valuationmethod.id}`, valuationmethod),
  delete: (id: string) => requests.del(`/valuationmethods/${id}`),
};

const EcosystemServices = {
  list: (): Promise<TreeType[]> => requests.get("/ecosystemservices/"),
  details: (id: string) => requests.get(`/ecosystemservices/${id}`),
  create: (ecosystemservice: TreeType) =>
    requests.post("/ecosystemservices", ecosystemservice),
  update: (ecosystemservice: TreeType) =>
    requests.put(`/ecosystemservices/${ecosystemservice.id}`, ecosystemservice),
  delete: (id: string) => requests.del(`/ecosystemservices/${id}`),
};

const BiomeEcosystems = {
  list: (): Promise<TreeType[]> => requests.get("/biomeecosystems/"),
  details: (id: string) => requests.get(`/biomeecosystems/${id}`),
  create: (biomeecosystem: TreeType) =>
    requests.post("/biomeecosystems", biomeecosystem),
  update: (biomeecosystem: TreeType) =>
    requests.put(`/biomeecosystems/${biomeecosystem.id}`, biomeecosystem),
  delete: (id: string) => requests.del(`/biomeecosystems/${id}`),
};

const Studies = {
  list: (filters: FreeTextQueryParams): Promise<Study[]> => {
    return requests.get("/studies/", {
      params: filters,
      paramsSerializer: (params: any) => {
        return Qs.stringify(params);
      },
    });
  },
  userList: (): Promise<Study[]> => requests.get("/studies/userstudies/"),
  details: (id: number) => requests.get(`/studies/${id}`),
  create: (study: Study) => requests.post("/studies", study),
  update: (study: Study) => requests.put(`/studies/${study.id}`, study),
  delete: (id: number) => requests.del(`/studies/${id}`),
};

const StudyValutations = {
  list: (filters: EsvdQueryParams): Promise<Valuation[]> => {
    return requests.get("/esvds/", {
      params: filters.params,

      paramsSerializer: (params: any) => {
        return Qs.stringify(params);
      },
    });
  },

  studyValuations: (studyid: number): Promise<Valuation[]> => {
    return requests.get(`/esvds/studyvaluations/${studyid}`);
  },
  studyValuationApi: (studyId: string): Promise<Esvd[]> => {
    return requests.get(`/externalApi/study/valuations/${studyId}`);
  },
  details: (id: number): Promise<Valuation> => requests.get(`/esvds/${id}`),
  create: (esvd: Valuation) => requests.post("/esvds", esvd),
  update: (esvd: Valuation) => requests.put(`/esvds/${esvd.id}`, esvd),
  delete: (id: number) => requests.del(`/esvds/${id}`),
};

const ScaleOfStudySites = {
  list: (): Promise<DropdownType[]> => requests.get("/scaleofstudysites/"),
  details: (id: number) => requests.get(`/scaleofstudysites/${id}`),
  create: (item: DropdownType) => requests.post("/scaleofstudysites", item),
  update: (item: DropdownType) =>
    requests.put(`/scaleofstudysites/${item.id}`, item),
  delete: (id: string) => requests.del(`/scaleofstudysites/${id}`),
};

const StudyLocations = {
  list: (): Promise<DropdownType[]> => requests.get("/studylocations/"),
  details: (id: number) => requests.get(`/studylocations/${id}`),
  create: (item: DropdownType) => requests.post("/studylocations", item),
  update: (item: DropdownType) =>
    requests.put(`/studylocations/${item.id}`, item),
  delete: (id: string) => requests.del(`/studylocations/${id}`),
};

const UnitsOfMeasure = {
  list: (): Promise<DropdownType[]> => requests.get("/unitofmeasures/"),
  details: (id: number) => requests.get(`/unitofmeasures/${id}`),
  create: (item: DropdownType) => requests.post("/unitofmeasures", item),
  update: (item: DropdownType) =>
    requests.put(`/unitofmeasures/${item.id}`, item),
  delete: (id: string) => requests.del(`/unitofmeasures/${id}`),
};

const SiteConditions = {
  list: (): Promise<DropdownType[]> => requests.get("/siteconditions/"),
  details: (id: number) => requests.get(`/siteconditions/${id}`),
  create: (item: DropdownType) => requests.post("/siteconditions", item),
  update: (item: DropdownType) =>
    requests.put(`/siteconditions/${item.id}`, item),
  delete: (id: string) => requests.del(`/siteconditions/${id}`),
};

const ValuedChanges = {
  list: (): Promise<DropdownType[]> => requests.get("/valuedchanges/"),
  details: (id: number) => requests.get(`/valuedchanges/${id}`),
  create: (item: DropdownType) => requests.post("/valuedchanges", item),
  update: (item: DropdownType) =>
    requests.put(`/valuedchanges/${item.id}`, item),
  delete: (id: string) => requests.del(`/valuedchanges/${id}`),
};

const Currencies = {
  list: (): Promise<DropdownType[]> => requests.get("/currencies/"),
  details: (id: number) => requests.get(`/currencies/${id}`),
  create: (item: DropdownType) => requests.post("/currencies", item),
  update: (item: DropdownType) => requests.put(`/currencies/${item.id}`, item),
  delete: (id: string) => requests.del(`/currencies/${id}`),
};

const TemporalUnits = {
  list: (): Promise<DropdownType[]> => requests.get("/temporalunits/"),
  details: (id: number) => requests.get(`/temporalunits/${id}`),
  create: (item: DropdownType) => requests.post("/temporalunits", item),
  update: (item: DropdownType) =>
    requests.put(`/temporalunits/${item.id}`, item),
  delete: (id: string) => requests.del(`/temporalunits/${id}`),
};

const BeneficiaryUnits = {
  list: (): Promise<DropdownType[]> => requests.get("/beneficiaryunits/"),
  details: (id: number) => requests.get(`/beneficiaryunits/${id}`),
  create: (item: DropdownType) => requests.post("/beneficiaryunits", item),
  update: (item: DropdownType) =>
    requests.put(`/beneficiaryunits/${item.id}`, item),
  delete: (id: string) => requests.del(`/beneficiaryunits/${id}`),
};

const TypeOfBeneficiaries = {
  list: (): Promise<DropdownType[]> => requests.get("/typeofbeneficiaries/"),
  details: (id: number) => requests.get(`/typeofbeneficiaries/${id}`),
  create: (item: DropdownType) => requests.post("/typeofbeneficiaries", item),
  update: (item: DropdownType) =>
    requests.put(`/typeofbeneficiaries/${item.id}`, item),
  delete: (id: string) => requests.del(`/typeofbeneficiaries/${id}`),
};

const EcosystemConditions = {
  list: (): Promise<DropdownType[]> => requests.get("/ecosystemconditions/"),
  details: (id: number) => requests.get(`/ecosystemconditions/${id}`),
  create: (item: DropdownType) => requests.post("/ecosystemconditions", item),
  update: (item: DropdownType) =>
    requests.put(`/ecosystemconditions/${item.id}`, item),
  delete: (id: number) => requests.del(`/ecosystemconditions/${id}`),
};

const Ciceses = {
  list: (): Promise<TreeType[]> => requests.get("/cicesclasses/"),
  details: (id: string) => requests.get(`/cicesclasses/${id}`),
  create: (cices: TreeType) => requests.post("/cicesclasses", cices),
  update: (cices: TreeType) => requests.put(`/cicesclasses/${cices.id}`, cices),
  delete: (id: string) => requests.del(`/cicesclasses/${id}`),
};

const SuggestStudies = {
  // list: (): Promise<DropdownType[]> => requests.get("/suggeststudies/"),
  // details: (id: number) => requests.get(`/suggeststudies/${id}`),
  create: (item: SuggestStudy) => requests.post("/suggeststudies", item),
  // update: (item: DropdownType) =>
  //   requests.put(`/suggeststudies/${item.id}`, item),
  // delete: (id: number) => requests.del(`/suggeststudies/${id}`),
};

const FilterQueries = {
  list: (filters?: FilterQueryParams): Promise<FilterQuery[]> => {
    return requests.get("/filterqueries/", {
      params: filters?.params,
      paramsSerializer: (params: any) => {
        return Qs.stringify(params);
      },
    });
  },
  totalCount: (): Promise<number> => requests.get("/filterqueries/totalcount/"),
  create: (query: FilterQuery) => requests.post("/filterqueries", query),
  delete: (id: number) => requests.del(`/filterqueries/${id}`),
};

const BiomeV2EcosystemV2Ecozones = {
  list: (): Promise<TreeType[]> => requests.get("/biomev2ecosystemv2ecozones/"),
  details: (id: string) => requests.get(`/biomev2ecosystemv2ecozones/${id}`),
  create: (biomev2ecosystemv2ecozone: TreeType) =>
    requests.post("/biomev2ecosystemv2ecozones", biomev2ecosystemv2ecozone),
  update: (biomev2ecosystemv2ecozone: TreeType) =>
    requests.put(`/biomev2ecosystemv2ecozones/${biomev2ecosystemv2ecozone.id}`, biomev2ecosystemv2ecozone),
  toggleStatsReady: (id: number, statsReady: boolean) =>
    requests.put(`/biomev2ecosystemv2ecozones/togglestatsready/${id}/${statsReady}`, {}),
  delete: (id: string) => requests.del(`/biomev2ecosystemv2ecozones/${id}`),
};


const SEEAs = {
  list: (): Promise<TreeType[]> => requests.get("/seeas/"),
  details: (id: string) => requests.get(`/seeas/${id}`),
  create: (seeas: TreeType) =>
    requests.post("/seeas", seeas),
  update: (seeas: TreeType) =>
    requests.put(`/seeas/${seeas.id}`, seeas),
  delete: (id: string) => requests.del(`/seeas/${id}`),
};


const ReferenceInfos = {
  list: (filters?: ReferenceInfo): Promise<ReferenceInfo[]> => {
    return requests.get("/referenceinfos/", {
      params: filters,
      paramsSerializer: (params: any) => {
        return Qs.stringify(params);
      },
    });
  },
  details: (): Promise<ReferenceInfo> => requests.get(`/referenceinfos/currentversion`),
  create: (query: ReferenceInfo) => requests.post("/referenceinfos", query),
  delete: (id: number) => requests.del(`/referenceinfos/${id}`),
};

const UploadLists = {
  uploadJson: (data: UploadDto) => requests.post("/dataupload/uploadjsondataset", data),
  uploadDatasetFile: (data: UploadDto) => requests.post("/dataupload/uploadfiledataset", data),
  uploadFile: (data: any, options: any): any => requests.postFile("/dataupload/uploadfile", data, options),
  uploadComplete: (): any => requests.get("/dataupload/UploadComplete"),
  GetLastUploadStatistics: (): Promise<EsvdDataStatistics> => requests.get("/dataupload"),
};


//VTT

const VttBiomes = {
  list: (paging:PagingParams,): Promise<VttBiome[]> => requests.get("/vttbiomes/", paging),
  listExclude: (): Promise<VttBiome[]> =>
    requests.get(`/vttbiomes/exclude`),
  details: (id: number): Promise<VttBiome> => requests.get(`/vttbiomes/${id}`),
  toggleShowForUserCalculations: (vttbiomeid: number, showForUserCalculations: boolean) =>
      requests.put(`/vttbiomes/toggledisplay/${vttbiomeid}/${showForUserCalculations}`,{}),
  create: (vttbiome: VttBiome) => requests.post("/vttbiomes", vttbiome),
  update: (vttbiome: VttBiome) => requests.put(`/vttbiomes/${vttbiome.id}`, vttbiome),
  count: (): Promise<number> => requests.get("/VttBiomes/count/"),
  delete: (id: number) => requests.del(`/vttbiomes/${id}`),
};

const VttSubBiomes = {
  list: (paging:PagingParams): Promise<VttSubBiome[]> => requests.get("/vttsubbiomes/", paging),
  details: (id: number):Promise<VttSubBiome> => requests.get(`/vttsubbiomes/${id}`),
  create: (vttsubbiome: VttSubBiome) => requests.post("/vttsubbiomes", vttsubbiome),
  update: (vttsubbiome: VttSubBiome) => requests.put(`/vttsubbiomes/${vttsubbiome.id}`, vttsubbiome),
  count: (): Promise<number> => requests.get("/vttsubbiomes/count/"),
  delete: (id: number) => requests.del(`/vttsubbiomes/${id}`),
};

const VttVariables = {
  list: (paging: PagingParams): Promise<DropdownType[]> => requests.get("/vttvariables/", paging),
  listUnattachedVariables: (biomeid: number,selectedVariableid: number|null): Promise<DropdownType[]> => requests.get(`/vttvariables/unattachedvariables/${biomeid}/${selectedVariableid}`),
  listExclusiveUnattachedVariables: (biomeid: number): Promise<DropdownType[]> => requests.get(`/vttvariables/exclusiveunattachedvariables/${biomeid}`),
  details: (id: number):Promise<VttVariable> => requests.get(`/vttvariables/${id}`),
  create: (vttvariable: VttVariable) => requests.post("/vttvariables", vttvariable),
  update: (vttvariable: VttVariable) => requests.put(`/vttvariables/${vttvariable.id}`, vttvariable),
  count: (): Promise<number> => requests.get("/vttvariables/count/"),
  delete: (id: number) => requests.del(`/vttvariables/${id}`),
};

const VttVariableDetailMaps = {
  list: (vttbiomeid:number): Promise<VttVariableDetailMap[]> => requests.get(`/vttvariabledetailmaps/${vttbiomeid}`),
  // details: (vttbiomeid: number,vttvariableid:number):Promise<VttVariableDetailMap> => requests.get(`/vttvariabledetailmaps/${vttbiomeid}/${vttvariableid}`),
  details: (detailid: number):Promise<VttVariableDetailMap> => requests.get(`/vttvariabledetailmaps/details/${detailid}`),
  create: (vttvariabledetailmap: VttVariableDetailMap) => requests.post("/vttvariabledetailmaps", vttvariabledetailmap),
  update: (vttvariabledetailmap: VttVariableDetailMap) => requests.put(`/vttvariabledetailmaps/${vttvariabledetailmap.id}`, vttvariabledetailmap),
  count: (): Promise<number> => requests.get("/vttvariabledetailmaps/count/"),
  delete: (id: number) => requests.del(`/vttvariabledetailmaps/${id}`),
};


//VSL


const VslUploadLists = {
  uploadJson: (data: UploadDto) => requests.post("/vsldataupload/uploadjsondataset", data),
  uploadDatasetFile: (data: UploadDto) => requests.post("/vsldataupload/uploadfiledataset", data),
  uploadFile: (data: any, options: any): any => requests.postFile("/vsldataupload/uploadfile", data, options),
  uploadComplete: (): any => requests.get("/vsldataupload/UploadComplete"),
  GetLastUploadStatistics: (): Promise<EsvdDataStatistics> => requests.get("/vsldataupload"),
};



const Vsls = {

    list: (filters: EsvdQueryParams): Promise<ValuationStudy[]> => {
    return requests.get("/valuationstudies/",
      {
      params: filters.params,
      paramsSerializer: (params: any) => {
        return Qs.stringify(params);
      },
      }
    );
  },
  listCount: (filters: EsvdQueryParams): Promise<number> => {
    return requests.get("/valuationstudies/count", {
      params: filters.params,
      paramsSerializer: (params: any) => {
        return Qs.stringify(params);
      },
    });
  },
  create: (vslUpdate: VslValuationStudy) => requests.post("/valuationstudies", vslUpdate),
  update: (vslUpdate: VslValuationStudy) => requests.put(`/valuationstudies/${vslUpdate.id}`, vslUpdate),
  delete: (id: number) => requests.del(`/valuationstudies/${id}`),
  
};

const Apikey = {
  getApiKey: (): Promise<string> => {
     return requests.get("/ExternalApi/generateapi")
    //
//     return axios({
//     method: 'get',
//     url: '/externalapi/generateapi', // Relative URL
//     baseURL: process.env.REACT_APP_RAW_URL, // Override the base URL
// }).then(responseBody);
  },
};

const FeedbackMessage = {
  send: (item: Feedback) => requests.post("/feedback", item),
};

const obj = {
  Apikey,
  Account,
  Esvds,
  EsvdMaps,
  Countries,
  ProtectionStatuses,
  Continents,
  ValuationMethods,
  EcosystemServices,
  SEEAs,
  BiomeEcosystems,
  BiomeV2EcosystemV2Ecozones,
  EsvdStats,
  Studies,
  StudyValutations,

  ScaleOfStudySites,
  StudyLocations,
  UnitsOfMeasure,
  SiteConditions,
  ValuedChanges,
  Currencies,
  TemporalUnits,
  BeneficiaryUnits,
  TypeOfBeneficiaries,
  Ciceses,
  EcosystemConditions,
  SuggestStudies,
  FilterQueries,
  ReferenceInfos,
  UploadLists,

  //vtt
  VttBiomes,
  VttSubBiomes,
  VttVariables,
  VttVariableDetailMaps,

  //vsl
  VslUploadLists,
  Vsls,

  FeedbackMessage
};
export default obj;
