import { useField } from "formik";
import React, { Fragment } from "react";
import { Form, Icon, Input, Label, SemanticICONS } from "semantic-ui-react";

interface Props {
	name: string;
	placeholder?: string;
	icon?: SemanticICONS;
	label?: string;
	rightIcon?: any;
	fluid?: boolean;
	type?: string;
	value?: string;
	className?: string;
	accept?: string;
	autocomplete?: boolean;
	disabled?: boolean;
	autoFocus?: boolean;
}

const EsvdInput = (props: Props) => {
	const [field, meta] = useField(props.name);
	const size = "mini";
	return (
		<Fragment>
			<label>{props.label}</label>
			<Form.Field error={meta.touched && !!meta.error}>
				<Input
					labelPosition="left"
					{...field}
					placeholder={props.placeholder}
					fluid={props.fluid || false}
					type={props.type || "text"}
					// accept={props.accept || null}
					size={size}
					value={props.value}
					className={props.className}>
					{props.icon && (
						<Label style={{ paddingRight: "1px", lineHeight: "0.9" }}>
							<Icon name={props.icon} />
						</Label>
					)}

					<input
						disabled={props.disabled}
						autoComplete={props.autocomplete ? "true" : "false"}
						value={props.value}
						autoFocus={props.autoFocus}
					/>
				</Input>
				{meta.touched && meta.error ? (
					<Label
						basic
						color="red"
						size="tiny"
						style={{ padding: "0px", border: "none" }}>
						{meta.error}
					</Label>
				) : null}
			</Form.Field>
		</Fragment>
	);
};

export default EsvdInput;
