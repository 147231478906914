import { useField } from "formik";
// import { Fragment } from "react";
import {
	Form,
	Input,
	// Icon,
	// Input,
	Label,
	SemanticICONS,
	// TextArea,
} from "semantic-ui-react";

interface Props {
	name: string;
	placeholder?: string;
	icon?: SemanticICONS;
	label?: string;
	rightIcon?: any;
	fluid?: boolean;
	type?: string;
	value?: string;
	className?: string;
	minHeight?: number;
	readonly?: boolean;
}

const EsvdTextArea = (props: Props) => {
	const [field, meta] = useField(props.name);
	// const size = "mini";
	return (
		<>
			<label>{props.label}</label>
			<Form.Field error={meta.touched && !!meta.error}>
				<Input
					labelPosition="left"
					{...field}
					{...props}
					type={"text"}
					value={field.value ? field.value : field.value === 0 ? "0" : ""}>
					<textarea
						{...field}
						{...props}
						placeholder={props.placeholder}
						style={{ minHeight: props.minHeight || undefined }}
					/>
				</Input>
			</Form.Field>
			{meta.touched && meta.error ? (
				<Label
					basic
					color="red"
					size="tiny"
					style={{ padding: "0px", border: "none" }}>
					{meta.error}
				</Label>
			) : null}
		</>
	);
};

export default EsvdTextArea;
