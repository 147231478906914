import React from "react";
import {
	Grid,
	Container,
	Card,
	// Segment,
	// Rail,
	// Sticky,
	// Header,
	// Image,
	// GridColumn,
} from "semantic-ui-react";
// import _ from "lodash";
import { actions, resources } from "../../shared/Literals";
import Can from "../../accessControls/Can";
import { Redirect } from "react-router";
import MediaLayout, { MediaProvider } from "../../layouts/MediaLayout";
import BiomeEcozoneStatReady from "./BiomeEcozoneStatReady";
import { Esvdv2Provider } from "../../providers/Esvdv2Provider";

const { Media, MediaContextProvider } = MediaLayout;

const StatsReadyReadMe = () => {
	return (
		<Card fluid>
			<Card.Content>
				<h4 className="title-card">Read Me</h4>
			</Card.Content>
			<Card.Content></Card.Content>
		</Card>
	);
};

const StatsReadyBoard = () => {
	const renderContent = () => {
		return (
			<Esvdv2Provider>
				<MediaContextProvider>
					<Media at="mobile" className="page-content">
						<StatsReadyReadMe />
						<BiomeEcozoneStatReady />
					</Media>
					<Media greaterThan="mobile">
						<Container fluid className="top-container">
							<Grid columns={2} className="page-content" centered>
								<Grid.Row>
									<Grid.Column width={8} stretched>
										<BiomeEcozoneStatReady />
									</Grid.Column>
									<Grid.Column width={4}>
										<StatsReadyReadMe />
									</Grid.Column>
								</Grid.Row>
							</Grid>
						</Container>
					</Media>
				</MediaContextProvider>
			</Esvdv2Provider>
		);
	};
	return (
		<Can
			action={actions.read}
			resource={resources.study.name}
			yes={() => renderContent()}
			no={() => <Redirect to={"/"} />}
		/>
	);
};

const wrapper = () => {
	return (
		<MediaProvider>
			<StatsReadyBoard />
		</MediaProvider>
	);
};

export default wrapper;
