import React from "react";
import { Card, Grid } from "semantic-ui-react";

import { useEsvdValuation } from "../../providers/EsvdValuationProvider";
import { LIMIT } from "../../shared/Literals";
import EsvdReadonlyTable from "./EsvdReadonlyTable";
import EsvdPagination from "../esvdPanel/EsvdPagination";

const EsvdTableReadonlyPanel = () => {
	const { esvdCount, activePage, setActivePage } = useEsvdValuation();

	return (
		<>
			<EsvdReadonlyTable />
			{/* <Card.Content>
				<Grid>
					<Grid.Row style={{ marginTop: "2em", marginBottom: "1em" }}>
						<Grid.Column width={4}>
							<EsvdPagination
								currentPage={activePage}
								setActivePage={setActivePage}
								totalPages={Math.ceil(esvdCount / LIMIT)}
							/>
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</Card.Content> */}
		</>
	);
	// }
};

export default EsvdTableReadonlyPanel;
