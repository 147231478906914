import React, { useContext } from "react";
// import _ from "lodash";
import agent from "../api/agent";

interface IValue {
	getEsvdv2: Function;
	toggleStatsReady: Function;
}

const Esvdv2Context = React.createContext<IValue | null>(null);

// The ListsProvider is responsible for Lists management
const Esvdv2Provider = ({ children }: { children: any }) => {
	const getEsvdv2 = async () => {
		const lst = await agent.BiomeV2EcosystemV2Ecozones.list();
		return lst;
	};

	const toggleStatsReady = async (id: number, statsReady: boolean) => {
		try {
			return await agent.BiomeV2EcosystemV2Ecozones.toggleStatsReady(
				id,
				statsReady
			);
		} catch (error: any) {
			throw error.response ? error.response.statusText : error.message;
		}
	};

	return (
		<Esvdv2Context.Provider
			value={{
				getEsvdv2,
				toggleStatsReady,
			}}>
			{children}
		</Esvdv2Context.Provider>
	);
};

// The useStackData hook can be used by components under an StackDataProvider to
// access the auth context value.
const useEsvdv2 = () => {
	const lists = useContext(Esvdv2Context);
	if (lists == null) {
		throw new Error("useLists() called outside of a lists hook?");
	}
	return lists;
};

export { Esvdv2Provider, useEsvdv2 };
