import React, { useEffect } from "react";
import { Container, Grid } from "semantic-ui-react";

const LoginsContainer = ({ children }: { children: any }) => {
	useEffect(() => {
		return () => {};
	}, []);

	return (
		<Container fluid className="top-container">
			<Grid columns={1} className="page-content" stackable>
				<Grid.Row>
					<Grid.Column>
						<div className="outer-card">{children}</div>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		</Container>
	);
	// }
};

export default LoginsContainer;
