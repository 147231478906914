import React, { useEffect, useState } from "react";
import { Label } from "semantic-ui-react";
import filterCheck from "../../shared/FilterCheck";
import { ErrorMessage, Form, Formik } from "formik";
import NoFilterGrid from "../../shared/NoFilterGrid";
import { LoadingButton } from "../../shared/LoadingOverlay";
import {
	StatsResultData,
	useEsvdValuation,
} from "../../providers/EsvdValuationProvider";
// import { useModal } from "../../hooks/ModalHook";
import { useLists } from "../../providers/ListsProvider";
import { useModalWithContext } from "../../layouts/ModalWithContext";
import { TreeType } from "../../models/ecosystemService";
import { buildTreeTypeQueryList } from "../../EsvdFunctions";

const SummaryStatsButton = () => {
	const { getEsvdsSummaryStats, esvdQueryParams, saveQueryParams } =
		useEsvdValuation();
	// const { openModal } = useModal();
	const { list } = useLists();
	const { modalDispatcher } = useModalWithContext();

	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [exclusions, setExclusions] = useState<string[]>([]);
	// const [opened, setOpened] = useState<boolean>(false);
	// const [isActionNeeded, setisActionNeeded] = useState<boolean>(false);
	// const [modalMessage, setModalMessage] = useState<string | null>("");
	const [modalTitle] = useState<string | null>(
		"No summary stats available for your selections in the Biome/Ecozone filter"
	);

	React.useEffect(() => {
		if (exclusions.length > 0 && modalDispatcher) {
			const msg = `Due to lack of data, summary stats are not available for your selected filter:
				${exclusions.join(",\n")}`;

			modalDispatcher({
				open: true,
				size: "tiny",
				displayType: "info",
				options: {
					title: modalTitle,
					body: msg,
				},
			});
		}
	}, [exclusions]);

	// useEffect(() => {
	// 	console.log(
	// 		`esvdQueryParams in stats: ${JSON.stringify(
	// 			esvdQueryParams.params.biomeV2EcosystemV2Ecozones
	// 		)}`
	// 	);
	// }, [esvdQueryParams]);

	const checkStats = (summaryStats: StatsResultData) => {
		// console.log(MediaLayout);
		const { biomes, ecozones } = summaryStats || {};
		const startbiomes = esvdQueryParams.params.biomeV2EcosystemV2Ecozones;
		const included: number[] = [];
		if (startbiomes && biomes && biomes.length > 0) {
			const bio = biomes.reduce((a: number[], b: any): number[] => {
				const ids = b.biomeV2EcosystemV2Ecozones.map((k: any) => k.id);
				return [...a, ...ids];
			}, []);
			included.push(...bio);
		}
		if (startbiomes && ecozones && ecozones.length > 0) {
			const bio = ecozones.reduce((a: number[], b: any): number[] => {
				const ids = b.biomeV2EcosystemV2Ecozones.map((k: any) => k.id);
				return [...a, ...ids];
			}, []);
			included.push(...bio);
		}

		const uniqueIncluded = new Set(included);
		if (startbiomes && startbiomes.length > 0 && uniqueIncluded) {
			const exclusionIds = startbiomes.filter(
				(p: number) => !uniqueIncluded.has(p)
			);

			const { biomeV2EcosystemV2Ecozones } = list;
			const excs = biomeV2EcosystemV2Ecozones
				.filter((f: any) => exclusionIds.includes(f.id))
				.sort((a: TreeType, b: TreeType) => Number(a.code) - Number(b.code))
				.map((n: any) => `${n.code}) ${n.label}`);
			setExclusions([...excs]);
		}
	};

	const handleClick = async (setErrors: Function) => {
		if (filterCheck(esvdQueryParams)) {
			setIsLoading(true);

			if (!esvdQueryParams.saved && filterCheck(esvdQueryParams)) {
				//save query if not saved already
				saveQueryParams && saveQueryParams({ ...esvdQueryParams });
			}

			const params = { ...esvdQueryParams.params };

			//get the classifications in params and treefy them to include their sub items
			const { ecosystemServices, biomeV2EcosystemV2Ecozones } = params;

			if (ecosystemServices && ecosystemServices.length > 0) {
				params.ecosystemServices = buildTreeTypeQueryList(
					ecosystemServices,
					list.ecosystemServices
				);
			}

			if (biomeV2EcosystemV2Ecozones && biomeV2EcosystemV2Ecozones.length > 0) {
				params.biomeV2EcosystemV2Ecozones = buildTreeTypeQueryList(
					biomeV2EcosystemV2Ecozones,
					list.biomeV2EcosystemV2Ecozones
				);
			}
			const qryfil = {
				...esvdQueryParams,
				params,
			};
			const stats = await getEsvdsSummaryStats(qryfil);
			checkStats(stats);
			setIsLoading(false);
		} else {
			modalDispatcher({
				open: true,
				size: "small",
				displayType: "info",
				options: {
					title: "Attention!",
					body: (
						<NoFilterGrid
							conetent={
								<>
									<p className="pos_fixed">
										Summary statistics are based on your query/filter and result
										set of Valuation records.
									</p>
									<br />
									<p className="pos_fixed">
										You have 0 valuations at this moment. Therefore no summary
										statistics can be calculated yet.
									</p>
								</>
							}
						/>
					),
					basicModal: true,
				},
			});
			// openModal({
			// 	icon: "exclamation",
			// 	header: "Attention!",
			// 	main: (
			// 		<NoFilterGrid
			// 			conetent={
			// 				<>
			// 					<p className="pos_fixed">
			// 						Summary statistics are based on your query/filter and result
			// 						set of Valuation records.
			// 					</p>
			// 					<br />
			// 					<p className="pos_fixed">
			// 						You have 0 valuations at this moment. Therefore no summary
			// 						statistics can be calculated yet.
			// 					</p>
			// 				</>
			// 			}
			// 		/>
			// 	),
			// 	isOpen: true,
			// 	actions: null,
			// });
		}
	};

	return (
		<>
			<Formik
				enableReinitialize
				initialValues={{ username: "", password: "", error: null }}
				onSubmit={(values, { setErrors }) => {
					handleClick(setErrors);
				}}>
				{({ handleSubmit, errors }) => (
					<Form
						// className="ui form page-content"
						onSubmit={handleSubmit}
						autoComplete={"off"}>
						<LoadingButton
							isLoading={isLoading}
							text={"Show summary statistics"}
							type="submit"
						/>

						<ErrorMessage
							name="error"
							render={() => (
								<Label
									style={{ marginBottom: 10 }}
									basic
									color="red"
									content={errors.error}
								/>
							)}
						/>
					</Form>
				)}
			</Formik>
		</>
	);
};

export default SummaryStatsButton;
